import { createAction } from "../../utils";

export const types = {
  GET_ORDERS_PALLETS: "GET_ORDERS_PALLETS",
  GET_ORDERS_PALLETS_SUCCESS: "GET_ORDERS_PALLETS_SUCCESS",
  GET_ORDERS_PALLETS_LOADING: "GET_ORDERS_PALLETS_LOADING",

  RESET_ORDERS_PALLETS: "RESET_ORDERS_PALLETS",
};

const actions = {
  getOrderPallets: (id) => createAction(types.GET_ORDERS_PALLETS, { id }),
  getOrderPalletsSuccess: (pallets, articles) =>
    createAction(types.GET_ORDERS_PALLETS_SUCCESS, { pallets, articles }),
  getOrderPalletsLoading: (loading) =>
    createAction(types.GET_ORDERS_PALLETS_LOADING, { loading }),
  resetOrderPallets: () => createAction(types.RESET_ORDERS_PALLETS),
};

export default actions;
