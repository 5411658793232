import React, { useEffect, useState } from "react";
import { Modal, Table, Input, Space, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { BlActions } from "../../../../redux/bl";
import { selectSearchedOrders } from "../../../../redux/bl/selector";
import { showError, showSuccess } from "../../../../utils/notifications-helper";
import "./style.css";

const AddCMDModal = ({ visible, strings, onClose = () => {} }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const searchedOrders = useSelector(selectSearchedOrders);

  useEffect(() => {
    if (dispatch) {
      setLoadingTable(true);
      dispatch(
        BlActions.searchForOrdersRequest(query, false, () => {
          setLoadingTable(false);
        }),
      );
    }
    //eslint-disable-next-line
  }, [query]);

  const columns = [
    {
      title: strings("delivery_number"),
      dataIndex: "delivery_num",
      align: "center",
    },
    {
      title: strings("shipment_number"),
      dataIndex: "bl_num",
      align: "center",
    },
    {
      title: strings("create_date"),
      dataIndex: "created_at",
      align: "center",
      render: (value) => (
        <span>{value ? moment(value).format("DD/MM/YY") : ""}</span>
      ),
    },
  ];

  const handleChange = (e) => {
    const text = e.target.value;
    setQuery(text);
    setSelectedRowKeys([]);
  };

  const handleValidate = () => {
    if (dispatch) {
      setLoading(true);
      dispatch(
        BlActions.blUpdate(
          { id: selectedRowKeys[0], show_on_dashboard: true },
          {
            onFailure: () => {
              showError("order_visible_error", strings);
              setLoading(false);
            },
            onSuccess: () => {
              showSuccess("order_visible_success", strings);
              dispatch(BlActions.searchForOrdersSuccess([]));
              setQuery("");
              onClose();
              setLoading(false);
            },
          },
          true,
        ),
      );
    }
  };

  const onSelectChange = (selectedRowKeys) => {
    if (setSelectedRowKeys) setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = selectedRowKeys
    ? {
        selectedRowKeys,
        onChange: onSelectChange,
        type: "radio",
      }
    : null;

  const showMessageSupport =
    !loading &&
    !loadingTable &&
    searchedOrders?.length === 0 &&
    query?.length > 0;

  return (
    <Modal
      title={strings("add_cmd_bcu_title")}
      open={visible}
      onOk={handleValidate}
      onCancel={onClose}
      width={1000}
      cancelText={strings("cancel")}
      okText={strings("add")}
      okButtonProps={{
        disabled: selectedRowKeys.length === 0,
        loading,
      }}
    >
      <Space className={"space-add-cde"}>
        <Input
          className="input-delivery-num"
          allowClear
          value={query}
          onChange={handleChange}
          placeholder={strings("delivery_number")}
        />
        {showMessageSupport && (
          <Alert
            type="warning"
            showIcon
            message={strings("no_cde_contact_support")}
          />
        )}
      </Space>
      <Table
        size={"small"}
        bordered
        scr
        locale={{
          emptyText: strings("no_values"),
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              onSelectChange([record.id]);
            },
          };
        }}
        loading={loadingTable}
        rowSelection={rowSelection}
        dataSource={searchedOrders || []}
        rowKey={(record) => record.id}
        pagination={false}
        columns={columns}
      />
    </Modal>
  );
};

export default AddCMDModal;
