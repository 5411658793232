import React, { useContext, useEffect, useState } from "react";
import { ColumnTitle } from "../../ColumnTitle";
import { FilterDropdown } from "../../FilterDropdown";
import EditionComponent from "../../EditionComponent";
import { Button, Typography } from "antd";
import {
  OrderStepStatus,
  OrderStepStatusType,
} from "../../../../components/orderStepStatus/OrderStepStatus";
import ProgressFlux from "../../../../components/progressFlux";
import MultipleValueDisplay from "../../../../components/multipleValueDisplay";
import { BlActions } from "../../../../../redux/bl";
import { SealogisVsTeliae } from "../../EditionComponent/SealogisVsTeliae";
import {
  selectDeliveryStates,
  selectDeliveryStatus,
} from "../../../../../redux/status";
import VolumeComponent from "../../../../components/VolumeComponent";
import TrackinUrlButton from "../../../../components/TrackinUrlButton";
import { ShippingBox } from "../../../../../assets/svg";
import { AuditOutlined, InsertRowBelowOutlined } from "@ant-design/icons";
import EditButton from "../../EditButton";
import { DataTable, ModifyButton } from "../../../../components";
import { useHomeValues } from "../../useHomeValues";
import { useDispatch, useSelector } from "react-redux";
import { AbilityContext } from "../../../../../utils/casl/v2/can";

const DATE_COLUMN_WIDTH = 125;

export const ClassicalBoard = ({
  strings = () => {},
  sorter = {},
  openedFilter = "",
  handleOnFilterDropdownOpenChange = () => {},
  filters = {},
  isEdit = { index: -1, key: "" },
  handleEditionFieldClick = () => {},
  setCurrentBL = () => {},
  setIsModalePODVisible = () => {},
  setCurrentPodFile = () => {},
  setCurrentReceiptUrl = () => {},
  onClickTracking = () => {},
  setPalletsIndex = () => {},
  setToEditIndex = () => {},
  onModify = () => {},
  reset = false,
  setReset = () => {},
  pagination = {},
  totalBls = 0,
  subLoading = false,
}) => {
  const [valueList, setValueList] = useState([]);
  const [canWrite, setCanWrite] = useState(false);
  const { deliveryStates, deliveryStatus } = useHomeValues();
  const dispatch = useDispatch();
  const list = useSelector((state) => state.bl.bls);
  const canCtx = useContext(AbilityContext);

  useEffect(() => {
    if (list && isEdit.index === -1) {
      setValueList(list);
      const canWriteRule = canCtx.can("write", "all_columns");
      setCanWrite(canWriteRule || false);
    }
    //eslint-disable-next-line
  }, [list, isEdit.index]);

  const columns = [
    {
      title: strings("bl_number"),
      width: 120,
      dataIndex: "bl_num",
      align: "center",
      fixed: "left",
      render: (text) => <span>{text}</span>,
      csvExport: true,
      csvRender: (text) => text,
    },
    {
      title: strings("remitter_name"),
      width: 200,
      dataIndex: "client",
      align: "center",
      defaultSortOrder: "descend",
      sorter: () => {},
      sortKey: "client_supplier_code",
      sortOrder:
        sorter?.field === "client_supplier_code" ? sorter?.order : null,
      render: (client) => <span>{client?.name}</span>,
      csvExport: true,
      csvRender: (text) => text,
    },
    {
      title: (
        <ColumnTitle
          openedFilter={openedFilter === "edi_date"}
          title={strings("edi_date_remitter")}
        />
      ),
      width: DATE_COLUMN_WIDTH,
      dataIndex: "edi_date",
      align: "center",
      defaultSortOrder: "descend",
      sorter: () => {},
      sortOrder:
        sorter?.field === "sorting_view.receipt_date" ? sorter?.order : null,
      sortKey: "sorting_view.receipt_date",
      render: (date) => <span>{date}</span>,
      csvExport: true,
      filterDropdown: (props) => (
        <FilterDropdown {...props} strings={strings} type={"date"} />
      ),
      onFilterDropdownOpenChange: (isOpen) =>
        handleOnFilterDropdownOpenChange(isOpen, "edi_date"),
      filteredValue: filters.edi_date || [],
    },
    {
      title: (
        <ColumnTitle
          openedFilter={openedFilter === "formatted_shipping_date"}
          title={strings("exp_date")}
        />
      ),
      width: DATE_COLUMN_WIDTH,
      dataIndex: "formatted_shipping_date",
      align: "center",
      defaultSortOrder: "descend",
      sorter: () => {},
      sortKey: "sorting_view.date_shipping",
      sortOrder:
        sorter?.field === "sorting_view.date_shipping" ? sorter?.order : null,
      render: (date) => <span>{date}</span>,
      csvExport: true,
      csvRender: (text) => text,
      filterDropdown: (props) => (
        <FilterDropdown {...props} strings={strings} type={"date"} />
      ),
      onFilterDropdownOpenChange: (isOpen) =>
        handleOnFilterDropdownOpenChange(isOpen, "formatted_shipping_date"),
      filteredValue: filters.formatted_shipping_date || [],
    },
    {
      title: (
        <ColumnTitle
          openedFilter={openedFilter === "pickup_dates"}
          title={strings("pickup_date")}
        />
      ),
      width: DATE_COLUMN_WIDTH,
      dataIndex: "pickup_dates",
      align: "center",
      defaultSortOrder: "descend",
      sorter: () => {},
      sortOrder: sorter?.field === "pickup_date" ? sorter?.order : null,
      sortKey: "pickup_date",
      render: (dates, record, index) => {
        const { sealogis, charter } = dates;
        const isEditingCell =
          index === isEdit.index && "formatted_pickup_date" === isEdit.key;
        return (
          <EditionComponent
            type="date"
            value={isEditingCell ? sealogis : sealogis || charter}
            style={{
              color: sealogis ? "blue" : "turquoise",
              maxWidth: 170,
              alignItems: "start",
            }}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              "formatted_pickup_date",
            )}
            isEdit={isEditingCell}
            setIsEdit={() =>
              handleEditionFieldClick({
                index,
                key: "formatted_pickup_date",
                record,
              })
            }
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key: "formatted_pickup_date",
                record,
              })
            }
            required={false}
            canWrite={canWrite}
            updateInfos={{ key: "pickup_date", id: record.id }}
            showTooltip={charter !== null}
            tooltipTitle={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ color: "turquoise" }}>
                  {strings("charter")}: {charter}
                </Typography>
                <Typography style={{ color: "blue" }}>
                  {strings("sealogis")}: {sealogis}
                </Typography>
              </div>
            }
          />
        );
      },
      filterDropdown: (props) => (
        <FilterDropdown {...props} strings={strings} type={"date"} />
      ),
      onFilterDropdownOpenChange: (isOpen) =>
        handleOnFilterDropdownOpenChange(isOpen, "pickup_dates"),
      filteredValue: filters.pickup_dates || [],
    },
    {
      title: (
        <ColumnTitle
          openedFilter={openedFilter === "dde_prep_dates"}
          title={strings("dde_prep")}
        />
      ),
      width: DATE_COLUMN_WIDTH,
      dataIndex: "dde_prep_dates",
      align: "center",
      defaultSortOrder: "descend",
      sorter: () => {},
      sortOrder: sorter?.field === "dde_prep_date" ? sorter?.order : null,
      sortKey: "dde_prep_date",
      render: (dates, record, index) => {
        const { sealogis, charter } = dates;
        const isEditingCell =
          index === isEdit.index && "formatted_dde_prep_date" === isEdit.key;
        return (
          <EditionComponent
            type="date"
            value={isEditingCell ? sealogis : sealogis || charter}
            style={{
              color: sealogis ? "blue" : "turquoise",
              maxWidth: 170,
              alignItems: "start",
            }}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              "formatted_dde_prep_date",
            )}
            isEdit={isEditingCell}
            setIsEdit={() =>
              handleEditionFieldClick({
                index,
                key: "formatted_dde_prep_date",
                record,
              })
            }
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key: "formatted_dde_prep_date",
                record,
              })
            }
            required={false}
            canWrite={canWrite}
            updateInfos={{ key: "dde_prep_date", id: record.id }}
            showTooltip
            tooltipTitle={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ color: "turquoise" }}>
                  {strings("charter")}: {charter}
                </Typography>
                <Typography style={{ color: "blue" }}>
                  {strings("sealogis")}: {sealogis}
                </Typography>
              </div>
            }
          />
        );
      },
      csvExport: true,
      csvRender: (text) => text,
      filterDropdown: (props) => (
        <FilterDropdown {...props} strings={strings} type={"date"} />
      ),
      onFilterDropdownOpenChange: (isOpen) =>
        handleOnFilterDropdownOpenChange(isOpen, "dde_prep_dates"),
      filteredValue: filters.dde_prep_dates || [],
    },
    {
      title: strings("carrier_status"),
      width: 150,
      dataIndex: "carrier_status",
      align: "center",
      render: (status) => (
        <OrderStepStatus type={OrderStepStatusType.STEP} status={status} />
      ),
      csvExport: true,
      csvRender: (text) => text,
    },
    {
      title: strings("warehouse_status"),
      width: 125,
      dataIndex: "warehouse_status",
      align: "center",
      render: (status) => (
        <OrderStepStatus type={OrderStepStatusType.STEP} status={status} />
      ),
      csvExport: true,
      csvRender: (text) => text,
    },
    {
      title: strings("current_step"),
      width: 100,
      dataIndex: "steps_length",
      align: "center",
      render: (stepsLength) => <span>{`Étape ${stepsLength}`}</span>,
      csvExport: true,
      csvRender: (stepsLength) => {
        return `Étape ${stepsLength}`;
      },
    },
    {
      title: strings("progress"),
      width: 140,
      dataIndex: "steps_progress",
      align: "center",
      render: ({ total_steps, progress, percent }) => (
        <ProgressFlux
          strings={strings}
          progress={{
            totalSteps: total_steps,
            progressSteps: progress,
            percent: percent,
            state: "??",
          }}
        />
      ),
      csvExport: true,
      csvRender: (flux) => {
        let progressStep = 0;
        let totalSteps = flux?.flux_steps_aggregate?.aggregate?.count || 1;
        return `${strings("step")} - ${progressStep}/${totalSteps}`;
      },
    },
    {
      title: strings("order_state"),
      width: 150,
      dataIndex: "order_state",
      align: "center",
      render: (status) => (
        <OrderStepStatus type={OrderStepStatusType.STATE} status={status} />
      ),
      csvExport: true,
      csvRender: (text) => text,
    },
    {
      title: (
        <ColumnTitle
          openedFilter={openedFilter === "carrier_code"}
          title={strings("carrier_code")}
        />
      ),
      width: 90,
      dataIndex: "carrier_code",
      align: "center",
      render: (text) => <span>{text}</span>,
      csvExport: true,
      csvRender: (text) => text,
      filterDropdown: (props) => (
        <FilterDropdown
          {...props}
          strings={strings}
          type={"text"}
          allowEmpty={true}
        />
      ),
      onFilterDropdownOpenChange: (isOpen) =>
        handleOnFilterDropdownOpenChange(isOpen, "carrier_code"),
      filteredValue: filters.carrier_code || {},
    },
    {
      title: (
        <ColumnTitle
          openedFilter={openedFilter === "product_code"}
          title={strings("o_product_code")}
        />
      ),
      width: 95,
      dataIndex: "product_code",
      align: "center",
      render: (text) => <span>{text}</span>,
      csvRender: (text) => text,
      filterDropdown: (props) => (
        <FilterDropdown
          {...props}
          strings={strings}
          type={"text"}
          allowEmpty={true}
        />
      ),
      onFilterDropdownOpenChange: (isOpen) =>
        handleOnFilterDropdownOpenChange(isOpen, "product_code"),
      filteredValue: filters.product_code || {},
    },
    {
      title: strings("commentary"),
      width: 200,
      dataIndex: "commentaries",
      align: "center",
      render: ({ commentary, sealogis_commentary, all }, record, index) => {
        const isEditingCell =
          index === isEdit.index && "commentary" === isEdit.key;
        const { edi_one, edi_two, sealogis } = all || {};
        return (
          <EditionComponent
            type="text"
            value={(isEditingCell ? sealogis_commentary : commentary)?.value}
            style={{ color: commentary?.color, maxWidth: 170 }}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isEdit={isEditingCell}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              "commentary",
            )}
            setIsEdit={() =>
              handleEditionFieldClick({ index, key: "commentary", record })
            }
            onCancel={() =>
              handleEditionFieldClick({ index: -1, key: "commentary", record })
            }
            required={false}
            canWrite={canWrite}
            updateInfos={{ key: "sealogis_commentary", id: record.id }}
            showTooltip
            tooltipTitle={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ color: edi_one?.color }}>
                  {strings("delivery_instruction")}: {edi_one?.value}
                </Typography>
                <Typography style={{ color: edi_two?.color }}>
                  {strings("delivery_instruction_two")}: {edi_two?.value}
                </Typography>
                <Typography style={{ color: sealogis?.color }}>
                  {strings("sealogis_commentary")}: {sealogis?.value}
                </Typography>
              </div>
            }
          />
        );
      },
      csvExport: true,
      csvRender: (text) => text,
    },
    {
      title: (
        <ColumnTitle
          openedFilter={openedFilter === "clients"}
          title={strings("customer_name")}
        />
      ),
      width: 200,
      dataIndex: "clients",
      align: "center",
      render: (clients) => (
        <MultipleValueDisplay
          id={"customer_name"}
          keyValueOne={"remitter"}
          value1={clients?.remitter?.name}
          value2={clients?.log?.name}
          value3={clients?.tpt?.name}
          strings={strings}
        />
      ),
      csvExport: true,
      csvRender: (text) => text,
      filterDropdown: (props) => (
        <FilterDropdown
          {...props}
          strings={strings}
          onSearch={(value) => {
            dispatch && dispatch(BlActions.searchForClients(value));
          }}
          type={"search"}
        />
      ),
      onFilterDropdownOpenChange: (isOpen) =>
        handleOnFilterDropdownOpenChange(isOpen, "clients"),
      filteredValue: filters.clients || [],
    },
    {
      title: strings("customer_department"),
      width: 75,
      dataIndex: "clients",
      align: "center",
      render: (clients) => (
        <MultipleValueDisplay
          id={"customer_department"}
          keyValueOne={"remitter"}
          value1={clients?.remitter?.address?.zip_code}
          value2={clients?.log?.address?.zip_code}
          value3={clients?.tpt?.address?.zip_code}
          strings={strings}
        />
      ),
      csvExport: true,
      csvRender: (text) => text,
    },
    {
      title: (
        <ColumnTitle
          openedFilter={openedFilter === "formatted_asked_delivery_date"}
          title={strings("asked_delivery_date")}
        />
      ),
      width: DATE_COLUMN_WIDTH,
      dataIndex: "formatted_asked_delivery_date",
      align: "center",
      defaultSortOrder: "descend",
      sorter: () => {},
      sortOrder: sorter?.field === "asked_delivery_date" ? sorter?.order : null,
      sortKey: "asked_delivery_date",
      render: (text) => <span>{text}</span>,
      csvExport: true,
      csvRender: (text) => text,
      filterDropdown: (props) => (
        <FilterDropdown {...props} strings={strings} type={"date"} />
      ),
      onFilterDropdownOpenChange: (isOpen) =>
        handleOnFilterDropdownOpenChange(
          isOpen,
          "formatted_asked_delivery_date",
        ),
      filteredValue: filters.formatted_asked_delivery_date || [],
    },
    {
      title: strings("real_delivery_date"),
      width: DATE_COLUMN_WIDTH,
      dataIndex: "real_delivery_dates",
      align: "center",
      defaultSortOrder: "descend",
      sorter: () => {},
      sortOrder:
        sorter?.field === "sorting_view.real_delivery_date"
          ? sorter?.order
          : null,
      sortKey: "sorting_view.real_delivery_date",
      render: (value, record, index) => {
        return (
          <SealogisVsTeliae
            value={value}
            strings={strings}
            dataKey={"real_delivery_date"}
            record={record}
            index={index}
            isEdit={isEdit}
            handleEditionFieldClick={handleEditionFieldClick}
            canWrite={canWrite}
          />
        );
      },
      csvExport: true,
      csvRender: (text) => text,
    },
    {
      title: (
        <ColumnTitle
          openedFilter={openedFilter === "delivery_state"}
          title={strings("delivery_state")}
        />
      ),
      width: 145,
      dataIndex: "delivery_state",
      align: "center",
      render: (delivery_state, record, index) => (
        <EditionComponent
          type="select"
          value={delivery_state?.code}
          strings={strings}
          selector={selectDeliveryStates}
          isBlocked={isEdit.index !== -1}
          required={false}
          canWrite={canWrite}
          isEdit={index === isEdit.index && "delivery_state" === isEdit.key}
          isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
            "delivery_state",
          )}
          setIsEdit={() =>
            handleEditionFieldClick({ index, key: "delivery_state", record })
          }
          onCancel={() =>
            handleEditionFieldClick({
              index: -1,
              key: "delivery_state",
              record,
            })
          }
          updateInfos={{ key: "delivery_state", id: record.id }}
        />
      ),
      csvExport: true,
      csvRender: (text) => text,
      filters: deliveryStates,
      onFilterDropdownOpenChange: (isOpen) =>
        handleOnFilterDropdownOpenChange(isOpen, "delivery_state"),
      filteredValue: filters.delivery_state || [],
    },
    {
      title: (
        <ColumnTitle
          openedFilter={openedFilter === "delivery_status"}
          title={strings("delivery_status")}
        />
      ),
      width: 200,
      dataIndex: "delivery_status",
      align: "center",
      render: ({ current, sealogis, theoretical }, record, index) => {
        const isEditingCell =
          index === isEdit.index && "delivery_status" === isEdit.key;
        return (
          <EditionComponent
            type="select"
            hideOptions={["EN COURS"]}
            value={
              (isEditingCell
                ? sealogis
                : sealogis?.value
                ? sealogis
                : theoretical
              )?.value?.code
            }
            strings={strings}
            selector={selectDeliveryStatus}
            isBlocked={isEdit.index !== -1}
            style={{
              color: (sealogis?.value ? sealogis : theoretical)?.color,
              maxWidth: 170,
            }}
            isEdit={isEditingCell}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              "delivery_status",
            )}
            setIsEdit={() =>
              handleEditionFieldClick({ index, key: "delivery_status", record })
            }
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key: "delivery_status",
                record,
              })
            }
            required={false}
            canWrite={canWrite}
            updateInfos={{ key: "delivery_status", id: record.id }}
            showTooltip
            tooltipTitle={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ color: theoretical?.color }}>
                  {strings("theoretical")}: {theoretical?.value?.code}
                </Typography>
                <Typography style={{ color: sealogis?.color }}>
                  {strings("sealogis")}: {sealogis?.value?.code}
                </Typography>
              </div>
            }
          />
        );
      },
      /*render: (delivery_status, record, index) => (
              <EditionComponent
                type="select"
                value={delivery_status?.code}
                strings={strings}
                selector={selectDeliveryStatus}
                isBlocked={isEdit.index !== -1}
                required={false}
                canWrite={canWrite}
                isEdit={index === isEdit.index && "delivery_status" === isEdit.key}
                isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
                  "delivery_status",
                )}
                setIsEdit={() =>
                  handleEditionFieldClick({ index, key: "delivery_status", record })
                }
                onCancel={() =>
                  handleEditionFieldClick({
                    index: -1,
                    key: "delivery_status",
                    record,
                  })
                }
                updateInfos={{ key: "delivery_status", id: record.id }}
              />
            ),*/
      csvExport: true,
      csvRender: (text) => text,
      filters: deliveryStatus,
      onFilterDropdownOpenChange: (isOpen) =>
        handleOnFilterDropdownOpenChange(isOpen, "delivery_status"),
      filteredValue: filters.delivery_status || [],
    },
    {
      title: strings("cde_gross_weight"),
      width: 100,
      dataIndex: "volumes",
      align: "center",
      render: (volumes) => (
        <VolumeComponent volumes={volumes} strings={strings} type="weight" />
      ),
      csvExport: true,
      csvRender: (text) => text,
    },
    {
      title: strings("nb_packages"),
      width: 50,
      dataIndex: "volumes",
      align: "center",
      render: (volumes) => (
        <VolumeComponent
          volumes={volumes}
          strings={strings}
          type="nb_total_packages"
        />
      ),
      csvExport: true,
      csvRender: (text) => text,
    },
    {
      title: strings("nb_pal"),
      width: 50,
      dataIndex: "volumes",
      align: "center",
      render: (volumes) => (
        <VolumeComponent
          volumes={volumes}
          strings={strings}
          type="nb_pallets"
        />
      ),
      csvExport: true,
      csvRender: (text) => text,
    },
    {
      title: (
        <ColumnTitle
          openedFilter={openedFilter === "charter_references"}
          title={strings("charter_reference")}
        />
      ),
      width: 140,
      dataIndex: "charter_references",
      align: "center",
      render: (values, record, index) => {
        const { sealogis, charter } = values;
        const isEditingCell =
          index === isEdit.index && "charter_reference" === isEdit.key;
        return (
          <EditionComponent
            id={"edition-charter-reference"}
            type="text"
            value={isEditingCell ? sealogis : sealogis || charter}
            style={{ color: sealogis ? "blue" : "turquoise", maxWidth: 170 }}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              "charter_reference",
            )}
            isEdit={isEditingCell}
            setIsEdit={() =>
              handleEditionFieldClick({
                index,
                key: "charter_reference",
                record,
              })
            }
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key: "charter_reference",
                record,
              })
            }
            required={false}
            canWrite={canWrite}
            updateInfos={{ key: "charter_reference", id: record.id }}
            showTooltip={charter !== null}
            tooltipTitle={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ color: "turquoise" }}>
                  {strings("charter")}: {charter}
                </Typography>
                <Typography style={{ color: "blue" }}>
                  {strings("sealogis")}: {sealogis}
                </Typography>
              </div>
            }
          />
        );
      },
      csvExport: true,
      csvRender: (text) => text,
      filterDropdown: (props) => (
        <FilterDropdown {...props} strings={strings} allowEmpty={true} />
      ),
      onFilterDropdownOpenChange: (isOpen) =>
        handleOnFilterDropdownOpenChange(isOpen, "charter_references"),
      filteredValue: filters.charter_references || [],
    },
    {
      title: (
        <ColumnTitle
          title={strings("asked_appointment_date")}
          openedFilter={openedFilter === "asked_appointment_date"}
        />
      ),
      width: DATE_COLUMN_WIDTH,
      dataIndex: "asked_appointment_dates",
      align: "center",
      defaultSortOrder: "descend",
      sorter: () => {},
      sortKey: "charter_tracking.asked_appointment_date",
      sortOrder:
        sorter?.field === "charter_tracking.asked_appointment_date"
          ? sorter?.order
          : null,
      render: (value, record, index) => (
        <SealogisVsTeliae
          value={value}
          strings={strings}
          dataKey={"asked_appointment_date"}
          record={record}
          index={index}
          isEdit={isEdit}
          handleEditionFieldClick={handleEditionFieldClick}
          canWrite={canWrite}
        />
      ),
      csvExport: true,
      csvRender: (text) => text,
      filterDropdown: (props) => (
        <FilterDropdown {...props} strings={strings} type={"date"} />
      ),
      onFilterDropdownOpenChange: (isOpen) =>
        handleOnFilterDropdownOpenChange(isOpen, "asked_appointment_date"),
      filteredValue: filters.asked_appointment_dates || [],
    },
    {
      title: (
        <ColumnTitle
          openedFilter={openedFilter === "appointment_dates"}
          title={strings("appointment_date_messaging")}
        />
      ),
      width: DATE_COLUMN_WIDTH,
      dataIndex: "appointment_dates",
      align: "center",
      defaultSortOrder: "descend",
      sorter: () => {},
      sortOrder:
        sorter?.field === "charter_tracking.appointment_date"
          ? sorter?.order
          : null,
      sortKey: "charter_tracking.appointment_date",
      render: (value, record, index) => {
        return (
          <SealogisVsTeliae
            value={value}
            strings={strings}
            dataKey={"appointment_date"}
            record={record}
            index={index}
            isEdit={isEdit}
            handleEditionFieldClick={handleEditionFieldClick}
            canWrite={canWrite}
          />
        );
      },
      csvExport: true,
      csvRender: (text) => text,
      filterDropdown: (props) => (
        <FilterDropdown {...props} strings={strings} type={"date"} />
      ),
      onFilterDropdownOpenChange: (isOpen) =>
        handleOnFilterDropdownOpenChange(isOpen, "appointment_dates"),
      filteredValue: filters.appointment_dates || [],
    },
    {
      title: strings("action"),
      width: 190,
      key: "action",
      align: "center",
      fixed: "right",
      csvExport: false,
      render: (_, item, index) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TrackinUrlButton
              url={item.carrier_tracking_url}
              icon={<ShippingBox />}
              className={"align-items"}
              tooltipTitle={strings("tracking")}
            />
            {canWrite ? (
              <Button
                id={"pod"}
                icon={<AuditOutlined className={"audit-icon"} />}
                shape="circle"
                className={
                  item.pod_file || item.receipt_url
                    ? "audit-icon-btn"
                    : "audit-icon-no-pod"
                }
                style={
                  item.pod_file || item.receipt_url
                    ? { borderColor: "#0480FC" }
                    : { borderColor: "grey" }
                }
                onClick={() => {
                  setCurrentBL(item.bl_num);
                  setIsModalePODVisible(true);
                  setCurrentPodFile(item.pod_file);
                  setCurrentReceiptUrl(item.receipt_url);
                }}
              />
            ) : (
              <TrackinUrlButton
                onClick={() => onClickTracking(item)}
                url={item.pod_file ? item.pod_file : item.receipt_url}
                icon={<AuditOutlined />}
                tooltipTitle={strings("POD")}
              />
            )}

            <EditButton
              id={"pallet"}
              tooltipTitle={strings("order_pallet_volumes")}
              onClick={() => setPalletsIndex(index)}
              icon={<InsertRowBelowOutlined />}
            />

            <EditButton
              tooltipTitle={strings("sup_info")}
              onClick={() => setToEditIndex(index)}
            />

            <ModifyButton
              modify={() => onModify(item)}
              tooltipTitle={strings("order_detail")}
            />
          </div>
        );
      },
    },
  ];

  return (
    <DataTable
      items={valueList}
      reset={reset}
      setReset={setReset}
      columns={columns}
      isPagination
      pagination={{ ...pagination, total: totalBls }}
      sorter={sorter}
      filters={filters}
      loading={subLoading}
      allowScrollOnElement={true}
    />
  );
};
