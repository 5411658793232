import { createReducer } from "reduxsauce";

import { types } from "./actions";

const initialState = {
  order_states: [],
  delivery_states: [],
  delivery_status: [],
  delay_reasons: [],
  dispute_reasons: [],
  responsibilities: [],
  warehouseStatus: [],
  sk: {
    extra_cost_reason_destination: [],
    extra_cost_reason_start: [],
    offset_pattern_delivery: [],
    offset_pattern_pickup: [],
    responsibilities_sk: [],
    shipment_status: [],
  },
};

const orderStatesSuccess = (state = initialState, action) => {
  const { values } = action;
  const order_states = values.map((os) => ({ ...os, name: os.sealogis_value }));
  return { ...state, order_states };
};

const deliveryStatusSuccess = (state = initialState, action) => {
  const { delivery_states, delivery_status } = action;
  return { ...state, delivery_states, delivery_status };
};

const delayReasonsSuccess = (state = initialState, action) => {
  const { delay_reasons } = action;
  return { ...state, delay_reasons };
};

const disputeReasonsSuccess = (state = initialState, action) => {
  const { dispute_reasons } = action;
  return { ...state, dispute_reasons };
};

const responsibilitiesSuccess = (state = initialState, action) => {
  const { responsibilities } = action;
  return { ...state, responsibilities };
};

const warehouseStatusSuccess = (state = initialState, action) => {
  const { under_order_step_status: warehouseStatus } = action;
  return { ...state, warehouseStatus };
};

const skRelatedStatusSuccess = (state = initialState, action) => {
  const { status } = action;
  const {
    extra_cost_reason_destination,
    extra_cost_reason_start,
    offset_pattern_delivery,
    offset_pattern_pickup,
    responsibilities_sk,
    shipment_status,
  } = status || {};
  return {
    ...state,
    sk: {
      ...state.sk,
      extra_cost_reason_destination,
      extra_cost_reason_start,
      offset_pattern_delivery,
      offset_pattern_pickup,
      responsibilities_sk,
      shipment_status,
    },
  };
};

export default createReducer(initialState, {
  [types.ORDER_STATES_SUCCESS]: orderStatesSuccess,
  [types.DELIVERY_STATUS_SUCCESS]: deliveryStatusSuccess,
  [types.DELAY_REASON_SUCCESS]: delayReasonsSuccess,
  [types.DISPUTE_REASON_SUCCESS]: disputeReasonsSuccess,
  [types.RESPONSIBILITY_SUCCESS]: responsibilitiesSuccess,
  [types.WAREHOUSE_STATUS_SUCCESS]: warehouseStatusSuccess,
  [types.SK_RELATED_STATUS_SUCCESS]: skRelatedStatusSuccess,
});
