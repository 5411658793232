import Home from "./Home";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { getTranslate } from "react-localize-redux";
import { BlActions } from "../../../redux/bl";

const mapStateToProps = ({ locale, bl }) => ({
  strings: getTranslate(locale),
  list: bl.bls,
  sk_list: bl.sk_bls,
  bls_export: bl.bls_export,
  filters: bl.filters,
  blSearch: bl.blSearch,
  pagination: bl.pagination,
  sorter: bl.sorter,
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path, param) => dispatch(push(path, { param })),
  blsRequest: () => dispatch(BlActions.blsRequest()),
  setFilters: (filters) => dispatch(BlActions.setFilters(filters)),
  setBlSearch: (blSearch) => dispatch(BlActions.setBlSearch(blSearch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
