import { DatePicker } from "antd";
import { useMemo } from "react";
import { createDate, dateFormat } from "../../../../utils/wrappers/dateWrapper";
import moment from "moment";
import locale from "antd/es/date-picker/locale/fr_FR";
import { SHORT_DATE_FORMAT } from "../../../../utils/mappers";

const DateEdit = ({ value, onChange, format = "dd/MM/yy" }) => {
  const dateValue = useMemo(
    () => (value ? moment(createDate(value, format)) : null),
    //eslint-disable-next-line
    [value],
  );

  const handleOnChange = (value) => {
    onChange(dateFormat(value, format));
  };

  return (
    <DatePicker
      format={SHORT_DATE_FORMAT}
      locale={locale}
      value={dateValue}
      onChange={handleOnChange}
    />
  );
};

export default DateEdit;
