import { set } from "lodash";
import { wrappers } from "../../utils/wrappers";

export const orderParams = (filters, sorter) => {
  let where = `is_divided: { _eq: false }`;
  let params = "";
  let variables = {};
  /*console.log("filters", filters)
  console.log("typeof", typeof filters?.state)
  const types = typeof filters?.state === "object" ? filters?.state?.filter((s) => s.includes("IN_PROGRESS"))?.map((state) => {
    return state.split("IN_PROGRESS_")[1]
  }) : []
  const states = typeof filters?.state === "object" ? filters?.state?.map((state) => {
    if (state.includes("IN_PROGRESS")) {
      return "IN_PROGRESS"
    }
    return state
  }) : undefined;
  console.log("types", types)
  console.log("states", states)*/
  const filtersWhere = {
    client_code: {
      value: filters.client,
      where: "{client_supplier_code: { _in: $client_code }}",
      type: "[String]",
    },
    br_num: {
      value: Array.isArray(filters.br_num)
        ? filters.br_num[0]
        : filters.br_number,
      where: `{ br_num: { _regex: $br_num } }`,
      type: "String",
    },
    bl_num: {
      value: Array.isArray(filters.bl_num)
        ? filters.bl_num[0]
        : filters.bl_number,
      where: `{_or: [
          { bl_num: { _regex: $bl_num } },
          { original_bl_num: { _regex: $bl_num } }
        ]}`,
      type: "String",
    },
    /*status_code: {
      value: states
          ? states
          : filters.delivered === false
              ? ["DELIVERED"]
              : null,
      where: `
        {
          order_state: { code: { _${
            !states && filters.delivered === false ? "nin" : "in"
          }: $status_code } },
          ${types?.length > 0 ? `order_steps: {order_steps_partners: {
            _or: [
              {partner: {type_code: {_in: ${types}}}},
              {flux_step_partner: {partner: {type_code: {_in: ${types}}}}},
            ]
          }}`: ''}
        }
      `,
      type: "[String]",
      skipEmpty: true,
    },*/
    error: {
      value: filters.error ? filters.error : null,
      where: `{
        error: { _eq: $error }
      }`,
      type: "Boolean",
      skipEmpty: true,
    },
    status_code: {
      value: filters.state
        ? filters.state
        : filters.delivered === false
        ? ["DELIVERED"]
        : null,
      where: `
        { order_state: { code: { _${
          !filters.state && filters.delivered === false ? "nin" : "in"
        }: $status_code } } }
        `,
      type: "[String]",
      skipEmpty: true,
    },
    warehouse_status: {
      value: filters.warehouse_status,
      where:
        "bext_trackings: {\nunder_order_step_status: { id: { _eq: $warehouse_status } }\n}",
      type: "Int",
    },
    delivery_state: {
      value: filters.delivery_state?.length ? filters.delivery_state : null,
      where: `{_or: [
        { delivery_state_id: { _in: $delivery_state } }
        ${
          filters.delivery_state?.includes(-1)
            ? ", { delivery_state_id: { _is_null: true } }"
            : ""
        }
      ]}`,
      type: "[Int]",
      skipEmpty: true,
    },
    delivery_status: {
      value: filters.delivery_status?.length ? filters.delivery_status : null,
      where: `{_or: [
        {delivery_status_id: { _in: $delivery_status }}
        ${
          filters.delivery_status?.includes(-1)
            ? ", { delivery_status_id: { _is_null: true } }"
            : ""
        }
      ]}`,
      type: "[Int]",
      skipEmpty: true,
    },
    asked_first_date: {
      value: filters.asked_shipping_date?.firstDate
        ? wrappers.toEndorStartDay(filters.asked_shipping_date?.firstDate, true)
        : null,
      where:
        "{asked_delivery_date: {\n_lte: $asked_second_date\n_gte: $asked_first_date\n}}",
      type: "timestamptz",
    },
    asked_second_date: {
      value: filters.asked_shipping_date?.secondDate
        ? wrappers.toEndorStartDay(
            filters.asked_shipping_date?.secondDate,
            false,
          )
        : null,
      type: "timestamptz",
    },
    asked_delivery_first_date: {
      value:
        Array.isArray(filters.formatted_asked_delivery_date) &&
        filters.formatted_asked_delivery_date[0]
          ? wrappers.toEndorStartDay(
              filters.formatted_asked_delivery_date[0],
              true,
            )
          : filters.asked_delivery_date &&
            wrappers.toEndorStartDay(filters.asked_delivery_date, true),
      where:
        "{asked_delivery_date: {\n_lte: $asked_delivery_second_date\n_gte: $asked_delivery_first_date\n}}",
      type: "timestamptz",
    },
    asked_delivery_second_date: {
      value:
        Array.isArray(filters.formatted_asked_delivery_date) &&
        filters.formatted_asked_delivery_date[0]
          ? wrappers.toEndorStartDay(
              filters.formatted_asked_delivery_date[0],
              false,
            )
          : filters.asked_delivery_date &&
            wrappers.toEndorStartDay(filters.asked_delivery_date, false),
      type: "timestamptz",
    },
    clients: {
      value: filters.clients?.length ? filters.clients : null,
      where:
        '{client_orders: {data_type: {_eq: "remitter"}, client: { name: { _in: $clients } } }}',
      type: "[String]",
    },
    client_name_input: {
      value: filters.client_name_input
        ? `%${filters.client_name_input}%`
        : null,
      where:
        '{client_orders: {data_type: {_eq: "remitter"}, client: {name: {_ilike: $client_name_input}}}}',
      type: "String",
    },
    charter_references: {
      value: filters.charter_references?.length
        ? filters.charter_references[0]
        : null,
      where:
        filters.charter_references?.length > 0 &&
        filters.charter_references[0] !== " "
          ? "{charter_reference: {_regex: $charter_references }}"
          : '{_or: [{charter_reference: {_is_null: true}}, {charter_reference: {_eq: ""}}]}',
      type: "String",
      skipEmpty: true,
      skipValue: " ",
    },
    edi_first_date: {
      value: filters.edi_date?.length
        ? wrappers.toEndorStartDay(filters.edi_date[0], true)
        : null,
      where: `{sorting_view: {receipt_date: {\n_lte: $edi_second_date\n_gte: $edi_first_date\n}}}`,
      type: "timestamptz",
    },
    edi_second_date: {
      value: filters.edi_date?.length
        ? wrappers.toEndorStartDay(filters.edi_date[0], false)
        : null,
      type: "timestamptz",
    },
    shipping_first_date: {
      value: filters.formatted_shipping_date?.length
        ? wrappers.toEndorStartDay(filters.formatted_shipping_date[0], true)
        : null,
      where: `{sorting_view: {date_shipping: {_lte: $shipping_second_date _gte: $shipping_first_date}}}`,
      type: "timestamptz",
    },
    shipping_second_date: {
      value: filters.formatted_shipping_date?.length
        ? wrappers.toEndorStartDay(filters.formatted_shipping_date[0], false)
        : null,
      type: "timestamptz",
    },
    pickup_first_date: {
      value: filters.pickup_dates?.length
        ? wrappers.toEndorStartDay(filters.pickup_dates[0], true)
        : null,
      where: `{charter_tracking: {pickup_date: {_lte: $pickup_second_date _gte: $pickup_first_date}}}`,
      type: "timestamptz",
    },
    pickup_second_date: {
      value: filters.pickup_dates?.length
        ? wrappers.toEndorStartDay(filters.pickup_dates[0], false)
        : null,
      type: "timestamptz",
    },
    dde_prep_first_date: {
      value: filters.dde_prep_dates?.length
        ? wrappers.toEndorStartDay(filters.dde_prep_dates[0], true)
        : null,
      where: `{dde_prep_date: {_lte: $dde_prep_second_date _gte: $dde_prep_first_date}}`,
      type: "timestamptz",
    },
    dde_prep_second_date: {
      value: filters.dde_prep_dates?.length
        ? wrappers.toEndorStartDay(filters.dde_prep_dates[0], false)
        : null,
      type: "timestamptz",
    },
    appointment_first_date: {
      value: filters.appointment_dates?.length
        ? wrappers.toEndorStartDay(filters.appointment_dates[0], true)
        : null,
      where:
        "{charter_tracking: {appointment_date: {_lte: $appointment_second_date, _gte: $appointment_first_date}}}",
      type: "timestamptz",
    },
    appointment_second_date: {
      value: filters.appointment_dates?.length
        ? wrappers.toEndorStartDay(filters.appointment_dates[0], false)
        : null,
      type: "timestamptz",
    },
    asked_appointment_first_date: {
      value: filters.asked_appointment_dates?.length
        ? wrappers.toEndorStartDay(filters.asked_appointment_dates[0], true)
        : null,
      where: `{charter_tracking: {asked_appointment_date: {_lte: $asked_appointment_second_date, _gte: $asked_appointment_first_date}}}`,
      type: "timestamptz",
    },
    asked_appointment_second_date: {
      value: filters.asked_appointment_dates?.length
        ? wrappers.toEndorStartDay(filters.asked_appointment_dates[0], false)
        : null,
      type: "timestamptz",
    },
    carrier_code: {
      value: filters.carrier_code?.length
        ? `%${filters.carrier_code[0]}%`
        : null,
      where:
        filters.carrier_code?.length > 0 && filters.carrier_code[0] !== " "
          ? `{bext_trackings: {_or: [
            {carrier_code: {_ilike: $carrier_code}}, 
            {telia_carrier_code: {_ilike: $carrier_code}}
          ]}}`
          : `{_or: [
            {_not: {bext_trackings: {id: {_gte: 0}}}},
            {bext_trackings: {_or: [
              {carrier_code: {_is_null: true}}, 
              {carrier_code: {_eq: ""}},
              {telia_carrier_code: {_is_null: true}},
              {telia_carrier_code: {_eq: ""}}
            ]}}
          ]}`,
      type: "String",
      skipEmpty: true,
      skipValue: "% %",
    },
    product_code: {
      value: filters.product_code?.length
        ? `%${filters.product_code[0]}%`
        : null,
      where:
        filters.product_code?.length > 0 && filters.product_code[0] !== " "
          ? `{bext_trackings: {_or: [
            {carrier_name: {_ilike: $product_code}},
            {product_code: {_ilike: $product_code}}
          ]}}`
          : `{_or: [
            {_not: {bext_trackings: {id: {_gte: 0}}}},
            {bext_trackings: {_or: [
              {carrier_name: {_is_null: true}},
              {carrier_name: {_eq: ""}},
              {product_code: {_is_null: true}},
              {product_code: {_eq: ""}}
            ]}}
          ]}`,
      type: "String",
      skipEmpty: true,
      skipValue: "% %",
    },
  };
  let hasAddAnd = false;
  Object.keys(filtersWhere).forEach((key) => {
    const {
      value,
      where: w,
      type,
      skipEmpty,
      skipValue,
    } = filtersWhere[key] || {};
    if (value) {
      let useValue = value;
      if (skipEmpty && Array.isArray(value)) {
        useValue = value.filter((el) => el !== -1 && el !== "EMPTY");
      }

      if (skipValue && useValue === skipValue) useValue = null;
      if (useValue) variables[key] = useValue;
      params += `$${key}: ${type}\n`;

      if (!hasAddAnd) {
        where += "_and: [";
        hasAddAnd = true;
      }
      if (w) where += `${w}\n`;
    }
  });
  if (hasAddAnd) where += "]";

  const fields = sorter?.field?.split(".") || [];
  let orderBy = {};
  if (fields.length) {
    orderBy = [];
    set(orderBy, [0, ...fields], sorter?.order === "ascend" ? "asc" : "desc");
    set(orderBy, [1, "id"], sorter?.order === "ascend" ? "asc" : "desc");
    /*orderBy["id"] = sorter?.order === "ascend" ? "asc" : "desc";*/
  } else if (sorter?.field) {
    orderBy = {
      [sorter.field]: sorter?.order === "ascend" ? "asc" : "desc",
      id: sorter?.order === "ascend" ? "asc" : "desc",
    };
  } else {
    orderBy = { id: "desc" };
  }
  /*variables["id"] = 1244;
  params += `$id: Int`;
  where += "id: {_eq: $id}";
  console.log(params, variables, where);*/
  return { variables, where, params, orderBy };
};
