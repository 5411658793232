import React, { useRef } from "react";
import { Button, DatePicker, Input, Space } from "antd";
import locale from "antd/es/date-picker/locale/fr_FR";
import { SearchOutlined, CheckOutlined } from "@ant-design/icons";
import { SHORT_DATE_FORMAT } from "../../../utils/mappers";
import { SearchFilter } from "./fluxFilter/filters/SearchFilter";
import { BlActions, selectClientsForOrders } from "../../../redux/bl";

const Icons = {
  text: <SearchOutlined />,
  date: <SearchOutlined />,
  search: <CheckOutlined />,
};

export const FilterDropdown = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  close,
  dataIndex = "",
  type = "text",
  strings = () => {},
  onSearch = null,
  allowEmpty = false,
}) => {
  const searchInput = useRef(null);
  const searchFilterRef = useRef(null);
  const handleSearch = () => {
    if (allowEmpty && !selectedKeys.length) {
      setSelectedKeys(" ");
    }
    if (confirm) confirm();
  };
  const handleReset = () => {
    if (clearFilters) {
      clearFilters();
      confirm && confirm();
      if (searchFilterRef?.current) {
        searchFilterRef.current.clear();
      }
    }
  };

  const components = {
    text: (props) => (
      <Input
        ref={searchInput}
        placeholder={strings("search")}
        value={`${selectedKeys[0] || ""}`}
        onChange={(e) => {
          if (onSearch) onSearch(e.target.value);
          else setSelectedKeys(e.target.value ? [e.target.value] : []);
        }}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
          display: "block",
          width: "250px",
        }}
      />
    ),
    date: (props) => (
      <DatePicker
        format={SHORT_DATE_FORMAT}
        locale={locale}
        value={selectedKeys[0] ? selectedKeys[0] : null}
        onChange={(value) => {
          setSelectedKeys(value ? [value] : []);
        }}
        style={{
          width: "250px",
          display: "block",
        }}
      />
    ),
    search: () => (
      <SearchFilter
        ref={searchFilterRef}
        placeHolder={`${strings("filter")} ${strings("delivered_client")}`}
        name={"delivered_client"}
        searchAction={BlActions.searchForClients}
        resetSearchAction={BlActions.resetSearchedClients}
        selector={selectClientsForOrders}
        selectStyle={{
          width: "250px",
        }}
        schema={{ value: "name", name: "name" }}
        strings={strings}
        onReset={handleReset}
        onChangeFilter={(value) => {
          setSelectedKeys(value);
        }}
      />
    ),
  };

  return (
    <div
      style={{
        padding: 8,
        position: "relative",
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Space align={"center"}>
        {components[type]()}
        <Button
          onClick={handleReset}
          size="small"
          style={{
            width: 60,
          }}
        >
          {strings("reset")}
        </Button>
        <Button
          type="primary"
          onClick={handleSearch}
          icon={Icons[type]}
          size="small"
          style={{
            width: 60,
          }}
        >
          {strings("ok")}
        </Button>
      </Space>
    </div>
  );
};
