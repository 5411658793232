import React from "react";
import "./style.css";
import {Table} from "antd";
import { InformationButton, MailButton } from "../button";
import { ShippingBox } from "../../../assets/svg";
import moment from "moment";
import { get } from "lodash";
import { bl_types, create_majStatut } from "../../../utils/global-helpers";
import {
  OrderStepStatus,
  OrderStepStatusType,
} from "../orderStepStatus/OrderStepStatus";
import { BasicComponents } from "../basic";
import VolumeComponent from "../VolumeComponent";
import { AuditOutlined } from "@ant-design/icons";
import TrackingUrlButton from "../TrackinUrlButton";
import TrackinUrlButton from "../TrackinUrlButton";

const ROW_SIZE = 30;

const ExpandStep = ({ data, strings, showDrawer, bl }) => {
  const openMail = (item) => {
    const body = `le ${moment().format("Do MMMM yyyy")} à ${moment().format(
      "HH:mm",
    )}%0D%0DCher ${
      item.partner?.contact_name1 || ""
    }%0DSealogis vous adresse ce message concernant :%0D%0DLa commande : ${
      bl.o_bl_number || ""
    }%0DPour le destinataire : ${
      bl.r_consignee_name || ""
    }%0DEn livraison finale: ${bl.r_consignee_addr1 || ""}${
      bl.r_consignee_addr2 ? `, ${bl.r_consignee_addr2}` : ""
    }${bl.r_consignee_addr3 ? `, ${bl.r_consignee_addr3}` : ""}${
      bl.r_consignee_addr4 ? `, ${bl.r_consignee_addr4}` : ""
    }${bl.r_consignee_zip ? `, ${bl.r_consignee_zip}` : ""}${
      bl.r_consignee_city ? `, ${bl.r_consignee_city}` : ""
    }${
      bl.r_consignee_country_name ? `, ${bl.r_consignee_country_name}` : ""
    }%0DPrévue le ${
      item.o_delivery_date
        ? moment(item.o_delivery_date).format("Do MMMM yyyy")
        : ""
    } à ${
      item.o_delivery_time ? moment(item.o_delivery_time).format("HH:mm") : ""
    }%0D%0DObjet du mail :%0D%0D%0D%0DBien à vous ,%0D%0DL’équipe Sealogis, département 4PL`;
    const url = `mailto:${
      item.partner?.contact_mail1 || ""
    }?subject=Track and Trace - ${bl.o_bl_number || ""}&body=${body}`;
    window.open(url, "_self");
  };

  const columnsPartner = [
    {
      title: strings("type"),
      dataIndex: "partner_type",
      key: "partner_type",
      align: "center",
      width: 150,
      render: (item, record) => (
        <span id={`type-${record.id}`}>{item.name}</span>
      ),
    },
    {
      title: strings("partner"),
      dataIndex: "name",
      key: "name",
      align: "center",
      width: 220,
      render: (item, record) => (
        <span id={`partnerName-${record.id}`}>{item}</span>
      ),
    },
    {
      title: strings("status"),
      dataIndex: "under_order_steps",
      align: "center",
      width: 600,
      render: (under_order_steps, record) => (
        <div
          style={{ height: get(under_order_steps, "length", 1) * ROW_SIZE }}
          className={"col-cell"}
        >
          {under_order_steps?.map(({ under_order_step_status }, index) => (
            <div key={`status_${index}`} style={{ height: ROW_SIZE + 5 }}>
              <OrderStepStatus
                id={`status-${record.id}-${index}`}
                type={OrderStepStatusType.STEP}
                status={under_order_step_status}
              />
            </div>
          ))}
        </div>
      ),
    },
    {
      title: strings("maj_status"),
      dataIndex: "under_order_steps",
      align: "center",
      width: 150,
      render: (under_order_steps, record) => (
        <div
          style={{ height: get(under_order_steps, "length", 1) * ROW_SIZE }}
          className={"col-cell"}
        >
          {under_order_steps?.map(
            ({ type, order_step_status_id, ...rest }, index) => {
              const step_type = bl_types[type];
              const maj_status = create_majStatut(
                rest[step_type?.maj_status_datetime],
              );
              const partner_maj_status = create_majStatut(
                rest["partner_datetime_etat"],
              );
              return (
                <BasicComponents.Tooltip
                  key={`maj_status_${index}`}
                  style={{ height: ROW_SIZE + 5 }}
                  title={
                    partner_maj_status
                      ? partner_maj_status === "missing_date"
                        ? strings(partner_maj_status)
                        : `${strings("teliae_data")} ${partner_maj_status}`
                      : "-"
                  }
                >
                  <div className={"maj-status-div"} style={{ height: ROW_SIZE + 2 }}>
                    <span id={`maj-status-${record.id}-${index}`}>
                    {maj_status
                      ? maj_status === "missing_date"
                        ? strings(maj_status)
                        : maj_status
                      : "-"}
                    </span>
                  </div>
                </BasicComponents.Tooltip>
              );
            },
          )}
        </div>
      ),
    },
    {
      title: strings("state"),
      dataIndex: "under_order_steps",
      align: "center",
      width: 250,
      render: (under_bl_steps, record) => (
        <div
          style={{ height: get(under_bl_steps, "length", 1) * ROW_SIZE }}
          className={"col-cell"}
        >
          {under_bl_steps?.map(({ under_order_step_status }, index) => (
            <div key={`state_${index}`} style={{ height: ROW_SIZE + 5 }}>
              <OrderStepStatus
                id={`under_bl_step-${record.id}-${index}`}
                type={OrderStepStatusType.STATE}
                status={under_order_step_status?.order_state}
              />
            </div>
          ))}
        </div>
      ),
    },
    {
      title: strings("appointment_date"),
      dataIndex: "appointment_date",
      align: "center",
      width: 250,
      render: (date, record) => (
        <span id={`appointment_date-${record.id}`}>{date}</span>
      ),
    },
    {
      title: strings("real_delivery_date"),
      width: 250,
      dataIndex: "real_delivery_date",
      align: "center",
      render: (date, record) => (
        <span id={`real_date-${record.id}`}>{date}</span>
      ),
    },
    {
      title: strings("nb_pal"),
      width: 150,
      dataIndex: "volumes",
      align: "center",
      render: (volumes, record) => (
        <VolumeComponent
          id={`volumes-${record.id}`}
          strings={strings}
          type={"nb_pallets"}
          volumes={volumes}
        />
      ),
    },
    {
      title: strings("nb_packages"),
      width: 150,
      dataIndex: "volumes",
      align: "center",
      render: (volumes, record) => (
        <VolumeComponent
          id={`nb_package-${record.id}`}
          strings={strings}
          type={"nb_total_packages"}
          volumes={volumes}
        />
      ),
    },
    {
      title: strings("nb_bulk_packages"),
      width: 150,
      dataIndex: "volumes",
      align: "center",
      render: (volumes) => (
        <VolumeComponent
          strings={strings}
          type={"nb_bulk_packages"}
          volumes={volumes}
        />
      ),
    },
    {
      title: strings("cde_gross_weight"),
      width: 150,
      dataIndex: "volumes",
      align: "center",
      render: (volumes, record) => (
        <VolumeComponent
          id={`cde_gross_weight-${record.id}`}
          strings={strings}
          type={"weight"}
          volumes={volumes}
        />
      ),
    },
    {
      title: strings("action"),
      key: "action",
      width: 175,
      align: "center",
      render: (item, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <InformationButton
              modify={() => {
                showDrawer(item);
              }}
            />
            <MailButton
              modify={() => {
                openMail(item, item.order);
              }}
            />
            <TrackinUrlButton
              id={`shipping-box-${record.id}`}
              url={item.carrier_tracking_url}
              className={"align-items"}
              icon={<ShippingBox />}
              tooltipTitle={strings("tracking")}
            />
            <TrackingUrlButton
              url={item.receipt_url}
              icon={<AuditOutlined />}
              tooltipTitle={strings("POD")}
            />
          </div>
        );
      },
    },
  ];

  const partners = data?.order_steps_partners?.map((step_partner) => ({
    ...step_partner,
    ...step_partner?.partner,
    ...step_partner?.flux_step_partner?.partner,
    under_order_steps: step_partner?.under_order_steps.sort(
      (a, b) => new Date(a.datetime_etat) - new Date(b.datetime_etat),
    ),
    is_planned: data?.is_planned,
  }));

  return (
    <Table
      rowClassName={(record) => {
        return `${
          record?.is_planned === false ? "table-row-yellow " : ""
        }row-flux`;
      }}
      rowKey={(record) => record.id || record.key}
      locale={{ emptyText: strings("no_partners") }}
      columns={columnsPartner}
      dataSource={partners || []}
      pagination={false}
    />
  );
};

export default ExpandStep;
