import React, { useMemo } from "react";
import { Col, Row } from "antd";
import moment from "moment/moment";

export const PartnerInfo = ({ strings, type, partner }) => {
  const formattedPartner = useMemo(() => {
    if (!partner) return [];
    return Object.keys(partner).filter((item) => !!partner[item]);
    //eslint-disable-next-line
  }, [partner?.id]);

  return (
    <>
      <Row>
        <Col span={7}>
          <p className="bloc-header">{strings(type)} :</p>
        </Col>
      </Row>
      {formattedPartner.map((item, index) => (
        <Row key={`bl-info-recipient-item-number-${index}`}>
          <Col span={4}>{strings(item)} :</Col>
          <Col span={6}>
            {item.match(/date/g)
              ? moment(formattedPartner[item]).format("DD-MM-YYYY")
              : formattedPartner[item]}
          </Col>
        </Row>
      ))}
    </>
  );
};
