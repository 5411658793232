import { gql } from "@apollo/client";

const queries = {
  adminFile: () => gql`
    query {
      admin_file(order_by: { created_at: desc }) {
        id
        created_at
        cgu
        privacy_policy
      }
    }
  `,
};

export default queries;
