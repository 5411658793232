import { DateTime } from "luxon";
import moment from "moment";

export const dateFormat = (date, format = "dd/MM/yy") => {
  let d = date;
  //eslint-disable-next-line
  if (typeof date !== "Date") d = new Date(date);
  return date ? DateTime.fromJSDate(d).toFormat(format) : null;
};

export const momentToDate = (date) => {
  return date.toDate();
};

export const toMoment = (date) => {
  return moment(date);
};

export const createDate = (date, format = "dd/MM/yy") => {
  return DateTime.fromFormat(date, format).toJSDate();
};

export const daysFromNow = (date) => {
  let d = date;
  //eslint-disable-next-line
  if (typeof date !== "Date") d = new Date(date);
  return DateTime.now().diff(DateTime.fromJSDate(d), ["days"]).values.days;
};

export const toEndorStartDay = (date, isStart) => {
  let d = date;
  //eslint-disable-next-line
  if (typeof date !== "Date") d = new Date(date);
  if (isStart) return moment(DateTime.fromJSDate(d).startOf("day").toJSDate());
  return moment(DateTime.fromJSDate(d).endOf("day").toJSDate());
};

export const verifyIfDateIsPassed = (date, add) => {
  let dtA = DateTime.fromJSDate(new Date(date));
  const dtB = DateTime.fromJSDate(new Date());

  if (add) {
    dtA = dtA.plus({ [add.unit]: add.duration });
  }

  return dtB > dtA;
};
