import { gql } from "@apollo/client";

const mutations = {
  updateAdminFile: () => gql`
    mutation update_users_by_pk(
      $id: Int!
      $cgu: Int
      $privacy_policy: Int
      $renew_pwd: Boolean
    ) {
      update_users_by_pk(
        pk_columns: { id: $id }
        _set: {
          cgu: $cgu
          privacy_policy: $privacy_policy
          renew_pwd: $renew_pwd
        }
      ) {
        id
        created_at
        updated_at
        email
        user_role {
          role
        }
        renew_pwd
        activated
        cgu
        privacy_policy
        company
        first_name
        last_name
        note
        password
        phone
      }
    }
  `,
};

export default mutations;
