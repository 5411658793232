import { createSelector } from "reselect";

export const selectAdminFile = createSelector(
  (state) => state.app,
  ({ cgu = null, privacy_policy = null }) => ({ cgu, privacy_policy }),
);

export const selectRemitter = createSelector(
  ({ app }) => app.remitter,
  (remitter) => remitter,
);
