import PODModale from "./PODModale";
import { connect } from "react-redux";
import { getTranslate } from "react-localize-redux";
import { AuthActions } from "../../../redux/auth";

const mapStateToProps = ({ auth, locale }) => ({
  renewPwd: auth.current_user?.renew_pwd,
  strings: getTranslate(locale),
});

const mapDispatchToProps = (dispatch) => ({
  validateModalFirstConnection: (values) =>
    dispatch(AuthActions.validateModalFirstConnectionRequest(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PODModale);
