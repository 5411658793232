import { createAction } from "../../utils";

// Types
export const types = {
  LOGIN_REFRESH: "LOGIN_REFRESH",

  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",

  RENEW_SUCCESS: "RENEW_SUCCESS",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",

  LOGOUT: "LOGOUT",
  USER_DESACTIVATED: "LOGIN_USER_DESACTIVATED",
  USER_DESACTIVATED_CLOSE_MODALE: "USER_DESACTIVATED_CLOSE_MODALE",
  RENEW_ADMIN_FILE_SUCCESS: "RENEW_ADMIN_FILE_SUCCESS",
  CURRENT_USER_SUCCESS: "CURRENT_USER_SUCCESS",

  VALIDATE_MODAL_FIRST_CO_REQUEST: "VALIDATE_MODAL_FIRST_CO_REQUEST",
  VALIDATE_MODAL_FIRST_CO_SUCCESS: "VALIDATE_MODAL_FIRST_CO_SUCCESS",
  VALIDATE_MODAL_FIRST_CO_FAILURE: "VALIDATE_MODAL_FIRST_CO_FAILURE",

  GET_CURRENT_USER_REQUEST: "GET_CURRENT_USER_REQUEST",
  GET_ADMIN_FILES_SIGNED_URL: "GET_ADMIN_FILES_SIGNED_URL",
};

// Actions
const actions = {
  getCurrentUserRequest: () => createAction(types.GET_CURRENT_USER_REQUEST),
  loginRefresh: () => createAction(types.LOGIN_REFRESH),
  loginRequest: (username, password) =>
    createAction(types.LOGIN_REQUEST, { username, password }),
  loginSuccess: (token_type, token, refresh_token, authorization) =>
    createAction(types.LOGIN_SUCCESS, {
      token_type,
      token,
      refresh_token,
      authorization,
    }),
  loginFailure: () => createAction(types.LOGIN_FAILURE),
  renewSuccess: (renewPwd) => createAction(types.RENEW_SUCCESS, { renewPwd }),

  changePasswordRequest: (password) =>
    createAction(types.CHANGE_PASSWORD_REQUEST, { password }),
  changePasswordSuccess: () => createAction(types.CHANGE_PASSWORD_SUCCESS),

  logout: () => createAction(types.LOGOUT),
  loginUserDesactivated: () => createAction(types.USER_DESACTIVATED),
  closeModaleUserDesactivated: () =>
    createAction(types.USER_DESACTIVATED_CLOSE_MODALE),
  currentUserSuccess: (data) =>
    createAction(types.CURRENT_USER_SUCCESS, { data }),

  validateModalFirstConnectionRequest: (values) =>
    createAction(types.VALIDATE_MODAL_FIRST_CO_REQUEST, values),
  validateModalFirstConnectionSuccess: () =>
    createAction(types.VALIDATE_MODAL_FIRST_CO_SUCCESS),
  validateModalFirstConnectionFailure: () =>
    createAction(types.VALIDATE_MODAL_FIRST_CO_FAILURE),

  getAdminFileSignedUrl: ({
    location,
    callbacks = { onSuccess: () => {}, onError: () => {} },
  }) => createAction(types.GET_ADMIN_FILES_SIGNED_URL, { location, callbacks }),
};

export default actions;
