import { authenticatedQuery, authenticatedService } from "./middleware";
import statusQueries from "./GraphQLRequest/statusQueries";
import { methods } from "../utils";

const { POST } = methods;
const routes = {
  calculDelay: process.env.REACT_APP_MATRIX_API_URL + "/tt/calculate-delays",
  calculEdiDelay:
    process.env.REACT_APP_MATRIX_API_URL + "/tt/calculate-edi-delay",
};

const services = {
  orderStates: () => authenticatedQuery(statusQueries.orderStates()),
  deliveryStatus: () => authenticatedQuery(statusQueries.deliveryStatus()),
  disputeReasons: () => authenticatedQuery(statusQueries.disputeReason()),
  delayReasons: () => authenticatedQuery(statusQueries.delayReason()),
  responsibilities: () => authenticatedQuery(statusQueries.responsibility()),
  calculDelay: (id) => authenticatedService(POST, routes.calculDelay, { id }),
  calculEdiDelay: (id) =>
    authenticatedService(POST, routes.calculEdiDelay, { id }),
  warehouseStatus: () => authenticatedQuery(statusQueries.warehouseStatus()),
  skRelatedStatusRequest: () =>
    authenticatedQuery(statusQueries.skRelatedStatus),
};

export default services;
