import { call, put, select, takeLatest, debounce } from "redux-saga/effects";
import { types } from "./actions";
import { LoaderActions } from "../loader";
import { getTranslate } from "react-localize-redux";
import {
  showError,
  showInfo,
  hideInfo,
  showSuccess,
} from "../../utils/notifications-helper";
import { BlActions } from "./index";
import { BlService, StatusService } from "../../services";
import moment from "moment";
import {
  createDate,
  dateFormat,
  momentToDate,
} from "../../utils/wrappers/dateWrapper";
import { v4 as uuid } from "uuid";
import { get } from "lodash";

const handleDate = (key, format, values) => {
  const value = values[key];
  return value ? createDate(value, format) : null;
};

const handleUpdateForBcuOrSealogis = (
  key,
  values,
  data,
  options = { customDateFormat: "dd/MM/yy", bcuData: {} },
) => {
  let realKey = key.split(".")[1];
  const handleIdKeys = (optionKey, searchKey) => {
    const option = get(options, optionKey, []);
    const valToFind = values[key];
    const ret = option.find((el) => el[searchKey] === valToFind);
    return ret?.id || -1;
  };
  let useValue = null;
  switch (true) {
    case realKey.includes("hour"):
      useValue = values[key] ? momentToDate(values[key]) : null;
      realKey = realKey.replace("_hour", "_hours");
      break;
    case realKey.includes("_date"):
      useValue = handleDate(key, options.customDateFormat, values);
      break;
    case realKey === "bcu_removal_location_city_id":
      const { bcuData } = options;
      const bcuVal = values[key];
      const bcu = bcuData.find((el) => el.loading_zone === bcuVal);
      useValue = bcu?.id;
      break;
    case realKey === "partner_carrier_id":
      useValue = handleIdKeys("carriers", "sealogis_code");
      break;
    case realKey === "offset_pattern_pickup_id":
      useValue = handleIdKeys(
        "skStatus.offset_pattern_pickup",
        "sealogis_code",
      );
      break;
    case realKey === "offset_responsibility_id":
      useValue = handleIdKeys("skStatus.responsibilities_sk", "sealogis_code");
      break;
    case realKey === "shipment_status_id":
      useValue = handleIdKeys("skStatus.shipment_status", "sealogis_code");
      break;
    case realKey === "extra_cost_reason_start_id":
      useValue = handleIdKeys(
        "skStatus.extra_cost_reason_start",
        "sealogis_code",
      );
      break;
    case realKey === "extra_cost_reason_start_responsibility_id":
      useValue = handleIdKeys("skStatus.responsibilities_sk", "sealogis_code");
      break;
    case realKey === "extra_cost_reason_destination_id":
      useValue = handleIdKeys(
        "skStatus.extra_cost_reason_destination",
        "sealogis_code",
      );
      break;
    case realKey === "extra_cost_reason_destination_responsibility_id":
      useValue = handleIdKeys("skStatus.responsibilities_sk", "sealogis_code");
      break;
    case realKey === "xc_status_id":
      const idVal = handleIdKeys("xcStatus", "code");
      useValue = idVal !== -1 ? idVal : null;
      break;
    default:
      useValue = values[key];
      break;
  }
  const ret = { value: useValue === -1 ? data[key] : useValue, realKey };
  if (key.includes("bcu")) {
    ret.isBcu = true;
  } else if (key.includes("sk")) {
    ret.isSk = true;
  } else {
    ret.isSealogis = true;
  }
  return ret;
};

function* exportClassicRequest({ filters, sorter }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const key = `open${Date.now()}`;
  showInfo("bl_export_start", strings, "", key);
  const [error, response] = yield call(BlService.exportClassic, {
    filters,
    sorter,
  });
  if (error) showError("bl_export_failed", strings);
  else if (response) {
    const url = window.URL.createObjectURL(response.data);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = `export-order-${moment().format("DD_MM_YYYY")}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  }
  hideInfo(key);
  yield put(LoaderActions.loaded());
}
function* exportSKRequest({ filters, sorter }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const key = `open${Date.now()}`;
  showInfo("bl_export_start", strings, "", key);
  const [error, response] = yield call(BlService.exportSK, { filters, sorter });
  if (error) showError("bl_export_failed", strings);
  else if (response) {
    const url = window.URL.createObjectURL(response.data);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = `export-order-${moment().format("DD_MM_YYYY")}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  }
  hideInfo(key);
  yield put(LoaderActions.loaded());
}

function* blsRequest({ offset, limit, filters, sorter }) {
  /*yield put(LoaderActions.loading());*/
  const locale = yield select((state) => state.locale);
  const { current_user } = yield select((state) => state.auth);
  /*const whereRemitter = `flux: {user_fluxs: {id_user: {_eq: $user_id}}}`;*/
  const whereRemitter = `role_sealogis_remitters: {role_sealogis_remitters_users: {id_user: {_eq: $user_id}}}`;
  const strings = getTranslate(locale);
  const [error, response] = yield call(BlService.bls, {
    offset,
    limit,
    filters,
    sorter,
    whereRemitter,
    userId: current_user.id,
  });
  if (error) showError("bl_failed", strings);
  else if (response) yield put(BlActions.blsSuccess(response));
  /*yield put(LoaderActions.loaded());*/
}

function* blRequest({ id }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);

  const [error, response] = yield call(BlService.bl, id);
  //eslint-disable-next-line
  const [error2, response2] = yield call(StatusService.calculEdiDelay, id);

  if (error) showError("bl_failed", strings);
  else if (response)
    yield put(BlActions.blSuccess(response, response2?.data || {}));

  yield put(LoaderActions.loaded());
}

function* blUpdateRequest({ values, callbacks, silentUpdate }) {
  const keys = Object.keys(values);
  const {
    id,
    delivery_state,
    delivery_status,
    delay_reason,
    dispute_reason,
    responsibility,
    custom_date_format,
    ...data
  } = values;
  const { onSuccess, onFailure } = callbacks;
  const [
    locale,
    deliveryStates,
    deliveryStatus,
    disputeReason,
    delayReason,
    responsibilities,
    skStatus,
    bcuDataStored,
    xcStatus,
    carriers,
  ] = yield select(({ locale, status, data }) => [
    locale,
    status.delivery_states,
    status.delivery_status,
    status.dispute_reasons,
    status.delay_reasons,
    status.responsibilities,
    status.sk,
    data.bcu_data,
    data.xc_status,
    data.partner_carriers,
  ]);
  const strings = getTranslate(locale);
  let otherData = {};
  let updateType = "";
  let where = "";

  keys.forEach((key) => {
    switch (true) {
      case key === "custom_date_format":
        // DO NOT PROCESS THAT KEY
        break;
      case key.includes("data_order_bcu.") ||
        key.includes("data_order_sealogis") ||
        key.includes("data_order_sk"):
        const {
          isBcu: isBcuRet,
          isSealogis: isSealogisRet,
          isSk: isSkRet,
          value: retValue,
          realKey,
        } = handleUpdateForBcuOrSealogis(key, values, data, {
          customDateFormat: custom_date_format,
          bcuData: bcuDataStored,
          skStatus,
          xcStatus,
          carriers,
        });
        console.log({
          isBcu: isBcuRet,
          isSealogis: isSealogisRet,
          isSk: isSkRet,
          value: retValue,
          realKey,
        });
        if (isBcuRet) {
          otherData[realKey] = retValue;
          updateType = "bcu";
        } else if (isSealogisRet) {
          otherData[realKey] = retValue;
          updateType = "sealogis";
        } else if (isSkRet) {
          otherData[realKey] = retValue;
          updateType = "sk";
        }
        delete data[key];
        break;
      case key.includes("sealogis_volumes.") || key.includes("bcu_volumes."):
        //eslint-disable-next-line
        const [_, keyName] = key.split(".");
        otherData[keyName] = data[key];
        updateType = "vol";
        const [type] = key.split("_");
        where = type;
        break;
      case key === "delivery_state":
        const state = deliveryStates.find(
          ({ code }) => code === delivery_state,
        );
        data["delivery_state_id"] = state?.id || null;
        values["delivery_state"] = state;
        break;
      case key === "dispute_reason":
        const di_reason = disputeReason.find(
          ({ code }) => code === dispute_reason,
        );
        data["dispute_reason_id"] = di_reason?.id || null;
        values["dispute_reason"] = di_reason;
        break;
      case key === "delay_reason":
        const de_reason = delayReason.find(({ code }) => code === delay_reason);
        data["delay_reason_id"] = de_reason?.id || null;
        values["delay_reason"] = de_reason;
        break;
      case key === "responsibility":
        const res = responsibilities.find(
          ({ code }) => code === responsibility,
        );
        data["responsibility_id"] = res?.id || null;
        values["responsibility"] = res;
        break;
      case key === "delivery_status":
        const status = deliveryStatus.find(
          ({ code }) => code === delivery_status,
        );
        data["delivery_status_id"] = status?.id || null;
        values["delivery_status"] = status;
        break;
      case key.includes("_date"):
        const value = values[key];
        const date = value ? createDate(values[key]) : null;
        data[key] = date;
        values[`formatted_${key}`] = dateFormat(date);
        break;
      case key.includes("nc_details"):
        //eslint-disable-next-line
        values["nc_details"] = values.nc_details;
        break;
      default:
        break;
    }
  });
  let serviceCall = null;
  let updateData = otherData;
  switch (updateType) {
    case "bcu":
      serviceCall = BlService.updateOrderDataBcu;
      break;
    case "sealogis":
      serviceCall = BlService.updateOrderDataSealogis;
      break;
    case "sk":
      serviceCall = BlService.updateOrderDataSk;
      break;
    case "vol":
      serviceCall = BlService.updateVolume;
      break;
    default:
      serviceCall = BlService.updateBl;
      updateData = data;
      break;
  }
  console.log("updateData", updateData);
  const [error, response] = yield call(serviceCall, id, updateData, where);
  if (error) {
    if (!silentUpdate) showError("bl_update_failed", strings);
    yield put(BlActions.blUpdateFailure());
    onFailure();
  } else if (response) {
    if (!silentUpdate) showSuccess("bl_update_success", strings);
    yield put(BlActions.blUpdateSuccess(values));
    onSuccess();
  }
}

function* importFile({ importType, file, blNum }) {
  const formData = new FormData();
  yield put(LoaderActions.loading());
  formData.append("file", file);
  if (blNum) formData.append("blNum", blNum);

  const service = (type) => {
    switch (type) {
      case "BEXT":
        return BlService.importBextFile;
      case "POD":
        return BlService.importPODFile;
      case "AFFRET":
        return BlService.importCharterFile;
      case "MANUAL_ORDER":
        return BlService.importManualOrderFile;
      default:
        return BlService.importTeliaFile;
    }
  };
  const [error, response] = yield call(service(importType), formData);

  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  if (error) {
    showError("bext_bl_import_failed", strings);
  } else {
    const insertErrors = response.data.errors;
    if (importType !== "POD" && insertErrors?.length) {
      yield put(BlActions.setImportReport(insertErrors));
      /*showInfo("bext_bl_errors_in_import", strings);*/
    }
    if (importType === "POD") {
      yield put(BlActions.uploadPodSuccess(response.data[0]));
    }
    showSuccess("bext_bl_import_success", strings);
  }
  yield put(LoaderActions.loaded());
}

function* getEdiFile({ file_type, file_name, callback }) {
  const locale = yield select((state) => state.locale);
  const { client } = yield select((state) => state.bl.current);
  const remitter = client?.flux?.sealogis_remitter;
  const strings = getTranslate(locale);

  const [errors, response] = yield call(BlService.getEdiFile, {
    type: file_type,
    file_name,
    remitter_name: remitter?.name,
    remitter_code: remitter?.code,
    wms_code: remitter?.wms_code,
  });
  if (errors) {
    showError("edi_file_error", strings);
  } else if (response) {
    const url = window.URL.createObjectURL(response.data);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = file_name;
    a.click();
    window.URL.revokeObjectURL(url);
  }
  callback();
}

function* getFilesRemitter({ remitterId, typeId, orderId }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const serviceCall = typeId
    ? BlService.getFilesRemitterByTypeId
    : BlService.getFilesRemitterByOrderId;
  const data = typeId
    ? {
        remitterId,
        typeId,
      }
    : {
        remitterId,
        orderId,
      };
  const [error, response] = yield call(serviceCall, data);
  if (response) {
    yield put(BlActions.getFilesRemitterSuccess(response.files_remitter));
  } else if (error) {
    showError("get_files_remitter_error", strings);
  }
}

function* createFileRemitter({ file, remitterId, typeId, orderId, callback }) {
  console.log({ file, remitterId, typeId, orderId, callback });
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const form = new FormData();
  const fileName = file.name;
  const uFileName = fileName.replace(/(\.[\w\d_-]+)$/i, (e) => `${uuid()}${e}`);
  let res = [];
  form.append("file", file);
  form.append("fileName", uFileName);
  console.log(form);
  const [error, response] = yield call(BlService.createImageOvh, form);
  console.log({ error, response });
  if (!error) {
    const base = {
      remitter_id: remitterId,
      location: response?.data?.location,
      name: fileName,
    };
    const data = typeId
      ? {
          ...base,
          file_type_id: typeId,
        }
      : {
          ...base,
          order_id: orderId,
        };
    res = yield call(BlService.createFileRemitter, data);
  }
  if (error || res[0]) showError("create_file_remitter_error", strings);
  else if (res[1]) {
    yield put(
      BlActions.createFileRemitterSuccess(
        res[1].insert_files_remitter?.returning[0],
      ),
    );
    showSuccess("create_file_remitter_success", strings);
  }
  callback();
}

function* createArticleFile({ file, articleId, callback }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const form = new FormData();
  const fileName = file.name;
  const uFileName = fileName.replace(/(\.[\w\d_-]+)$/i, (e) => `${uuid()}${e}`);
  let res = [];
  form.append("file", file);
  form.append("fileName", uFileName);
  const [error, response] = yield call(BlService.createImageOvh, form);
  if (!error) {
    res = yield call(BlService.createArticleFile, {
      article_orders_id: articleId,
      u_name: response?.data?.location,
      name: fileName,
    });
  }
  if (error || res[0]) showError("create_article_file_error", strings);
  else if (res[1]) {
    yield put(
      BlActions.createArticleFileSuccess(
        res[1].insert_article_order_files?.returning[0],
        articleId,
      ),
    );
    showSuccess("create_article_file_success", strings);
  }
  callback();
}

function* deleteFileRemitter({ fileId, callback }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, response] = yield call(BlService.deleteFileRemitter, fileId);
  if (error) showError("delete_file_remitter_error", strings);
  else {
    yield put(
      BlActions.deleteFileRemitterSuccess(
        response?.update_files_remitter?.returning[0]?.id,
      ),
    );
    showSuccess("delete_file_remitter_success", strings);
  }
  callback();
}

function* deleteArticleFile({ fileId, callback }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, response] = yield call(BlService.deleteArticleFile, fileId);
  if (error) showError("delete_article_error", strings);
  else {
    yield put(
      BlActions.deleteArticleFileSuccess(
        fileId,
        response?.update_article_order_files?.returning[0]?.article_orders_id,
      ),
    );
    showSuccess("delete_article_success", strings);
  }
  callback();
}

function* searchForClient({ value, callbacks }) {
  const [error, response] = value?.length
    ? yield call(BlService.searchForClients, `%${value}%`)
    : [
        null,
        {
          clients: [],
          clients_aggregate: { aggregate: { count: 0 } },
        },
      ];
  if (response) {
    const clients = response.clients;
    const totalResults = response.clients_aggregate.aggregate.count;
    yield put(BlActions.searchForClientsSuccess(clients, totalResults));
  } else if (error) {
    const locale = yield select((state) => state.locale);
    const strings = getTranslate(locale);
    showError("client_search_failed", strings);
  }
  const { onSuccess } = callbacks;
  onSuccess();
}

function* searchForOrders({ query, showOnDashboard, callback }) {
  const [error, response] = query?.length
    ? yield call(BlService.searchForOrders, {
        query: `%${query}%`,
        show_on_dashboard: showOnDashboard,
      })
    : [
        null,
        {
          orders: [],
        },
      ];
  if (response) {
    const orders = response.orders;
    yield put(BlActions.searchForOrdersSuccess(orders));
  } else if (error) {
    const locale = yield select((state) => state.locale);
    const strings = getTranslate(locale);
    showError("order_search_failed", strings);
  }
  if (callback) {
    callback();
  }
}

function* getSignedUrl({ location }) {
  const [error, response] = yield call(BlService.getImageOvh, location);
  if (response) {
    const url = response.data;
    yield put(BlActions.getSignedUrlSuccess(url));
  } else if (error) {
    const locale = yield select((state) => state.locale);
    const strings = getTranslate(locale);
    showError("client_search_failed", strings);
  }
}

function* getFileTypeRequest() {
  const [error, response] = yield call(BlService.getFileType);
  if (response) {
    yield put(BlActions.getFileTypeSuccess(response.file_type));
  } else if (error) {
    const locale = yield select((state) => state.locale);
    const strings = getTranslate(locale);
    showError("get_file_type_error", strings);
  }
}

function* getNumberFileTypeRequest(idRemitter) {
  const [error, response] = yield call(
    BlService.getNumberFileType,
    idRemitter.remitter_id,
  );
  if (response) {
    yield put(BlActions.getNumberFileTypeSuccess(response.sealogis_remitters));
  } else if (error) {
    const locale = yield select((state) => state.locale);
    const strings = getTranslate(locale);
    showError("get_file_type_error", strings);
  }
}

const sagas = [
  takeLatest(types.EXPORT_SK_REQUEST, exportSKRequest),
  takeLatest(types.EXPORT_CLASSIC_REQUEST, exportClassicRequest),
  debounce(200, types.BLS_REQUEST, blsRequest),
  takeLatest(types.GET_FILE_TYPE_REQUEST, getFileTypeRequest),
  takeLatest(types.BL_REQUEST, blRequest),
  takeLatest(types.BL_UPDATE_REQUEST, blUpdateRequest),
  takeLatest(types.IMPORT_FILE, importFile),
  takeLatest(types.GET_EDI_FILE_REQUEST, getEdiFile),
  takeLatest(types.GET_FILES_REMITTER_REQUEST, getFilesRemitter),
  takeLatest(types.CREATE_FILE_REMITTER_REQUEST, createFileRemitter),
  takeLatest(types.DELETE_FILE_REMITTER_REQUEST, deleteFileRemitter),
  takeLatest(types.CREATE_ARTICLE_FILE_REQUEST, createArticleFile),
  takeLatest(types.DELETE_ARTICLE_FILE_REQUEST, deleteArticleFile),
  debounce(250, types.SEARCH_FOR_CLIENT, searchForClient),
  debounce(250, types.SEARCH_FOR_ORDERS_REQUEST, searchForOrders),
  takeLatest(types.GET_SIGNED_URL, getSignedUrl),
  takeLatest(types.GET_NUMBER_FILE_TYPE_REQUEST, getNumberFileTypeRequest),
];

export default sagas;
