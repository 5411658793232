import { Input } from "antd";
import React, { useState } from "react";
import "./style.css";
import { CloseCircleFilled } from "@ant-design/icons";

const { Search } = Input;

const Filter = ({
  id,
  name,
  placeHolder,
  disabled,
  onFilterChange = () => {},
  filters = {},
  strings,
  ...props
}) => {
  const [value, setValue] = useState(filters[name] || "");

  const onSearch = (value) => {
    if (!value || value === "") {
      onFilterChange({ [name]: "" });
      return;
    }
    setValue(value);
    onFilterChange({ [name]: value });
    return value;
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;

    if (value.length === 0) {
      onSearch(value);
    }
    setValue(value);
  };

  const Suffix =
    value?.length > 0 ? (
      <CloseCircleFilled
        id={`${id}-closeCircle`}
        onClick={() => handleSearchChange({ target: { value: "" } })}
      />
    ) : (
      <span />
    );
  return (
    /*<div className={"filter_wrapper"}>*/
    <Search
      {...props}
      id={id ? id : "search"}
      placeholder={strings(placeHolder)}
      value={value}
      suffix={Suffix}
      onChange={handleSearchChange}
      onSearch={onSearch}
      disabled={disabled}
    />
    /*</div>*/
  );
};

export default Filter;
