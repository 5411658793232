import { PureAbility } from "@casl/ability";
import { permittedFieldsOf } from "@casl/ability/extra";
import { userRules } from "./userRules";

const fieldMatcher = (field, fields) =>
  field === "all" ? true : fields.includes(field);

const ability = new PureAbility([], { fieldMatcher });

let knownRole = "";

const updateAbilities = (role) => {
  if (role !== knownRole) {
    knownRole = role;
    const rules = userRules[role] || [];
    ability.update(rules);
  }
};

const getFields = (action, subject) => {
  const fields = permittedFieldsOf(ability, action, subject, {
    fieldsFrom: (rule) => rule.fields || [],
  });
  return fields;
};

export { updateAbilities, getFields };

export default ability;
