import { useSelector } from "react-redux";
import { currentUser } from "../../redux/auth/selector";
import { useEffect, useState } from "react";
import { updateAbilities } from "../casl/v2/ability";
import { selectRemitter } from "../../redux/app";

export const useRoleUser = () => {
  const [knownRole, setKnownRole] = useState("");
  const user = useSelector(currentUser);
  const remitter = useSelector(selectRemitter);

  useEffect(() => {
    if (user && remitter?.length) {
      const { formatted_roles /*, role*/ } = user;
      const role = (formatted_roles || {})[remitter];
      if (role && role !== knownRole) {
        setKnownRole(role);
        updateAbilities(role);
      }
    }
    //eslint-disable-next-line
  }, [user?.role, remitter]);

  return knownRole;
};
