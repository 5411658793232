import { createAction } from "../../utils";

// Types
export const types = {
  LOADING: "LOADING",
  LOADED: "LOADED",
};

// Actions
const actions = {
  loaded: () => createAction(types.LOADED),

  loading: () => createAction(types.LOADING),
};

export default actions;
