import {
  addTranslationForLanguage,
  initialize,
  localizeReducer,
} from "react-localize-redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { renderToStaticMarkup } from "react-dom/server";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import appReducers, { rootSaga } from "../redux";
import { loadState, saveState } from "../utils/storage";
import { history } from "./history";
import { languages, strings } from "../assets/localize";

/*const logger = createLogger({
  // ...options
});*/

const sagaMiddleware = createSagaMiddleware();

// Retrieves state from local storage
const persistedState = loadState();

// Configure store middleware
const middleware = [
  // Info: Apply sagaMiddleware in first !
  /*applyMiddleware(logger),*/
  applyMiddleware(sagaMiddleware),
  applyMiddleware(routerMiddleware(history)),
].concat(
  process.env.NODE_ENV === "development"
    ? [
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
          ? window.__REDUX_DEVTOOLS_EXTENSION__()
          : compose,
      ]
    : [],
);

// Create store
const store = createStore(
  combineReducers({
    router: connectRouter(history),
    locale: localizeReducer,
    ...appReducers,
  }),
  persistedState,
  compose(...middleware),
);

// Each sagas are isolated
// This ensure that the sagas will not be canceled if one failed
// (Be aware that should be a source of performance leak)
rootSaga.forEach((saga) =>
  sagaMiddleware.run(function* () {
    yield saga;
  }),
);

// Set up internationalization
store.dispatch(
  initialize({
    languages: languages,
    translation: strings.french,
    options: {
      renderToStaticMarkup,
      renderInnerHtml: true,
      defaultLanguage: "fr",
    },
  }),
);
store.dispatch(addTranslationForLanguage(strings.french, "fr"));

// Save state when changes occurred
store.subscribe(() => {
  const { auth, user, app } = store.getState();
  saveState({ auth, user, app });
});

export default store;
