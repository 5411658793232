import React from "react";
import { Table, Typography } from "antd";
import { useSelector } from "react-redux";
import { selectPalletsForModalTable } from "../../../../redux/pallets";

export const PalletsTable = ({ id, loading = false, strings = (_) => {} }) => {
  const { list: pallets, error } = useSelector(selectPalletsForModalTable);
  const columns = [
    {
      title: strings("pallet_modal_code"),
      dataIndex: "code",
      align: "center",
      render: (texts) => (
        <>
          {texts.map((el, index) => (
            <Typography key={`volume_number_uvc_${index}`}>{el}</Typography>
          ))}
        </>
      ),
    },
    {
      title: strings("pallet_modal_nb_pallet"),
      dataIndex: "number",
      align: "center",
    },
    {
      title: strings("pallet_modal_weight"),
      dataIndex: "weight",
      align: "center",
    },
    {
      title: strings("pallet_modal_type"),
      dataIndex: "pallet_type",
      align: "center",
    },
    {
      title: strings("pallet_modal_equivalent"),
      dataIndex: "equivalent",
      align: "center",
    },
  ];
  return (
    <>
      {error ? (
        <Typography> {strings("pallet_modal_no_data")} </Typography>
      ) : null}
      {pallets.length ? (
        <Table
          id={id}
          bordered
          pagination={false}
          columns={columns}
          dataSource={pallets || []}
          loading={loading}
        />
      ) : null}
    </>
  );
};
