import React, { useContext, useMemo } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { useDispatch } from "react-redux";
import FileUpload from "../../../components/fileUpload/FileUpload";
import { BlActions } from "../../../../redux/bl";
import ModalImportReport from "./ModalImportReport";
import { AbilityContext } from "../../../../utils/casl/v2/can";

const ImportXLS = ({ strings, className, paginationOptions, id }) => {
  const dispatch = useDispatch();
  const canCtx = useContext(AbilityContext);

  const onDownload = (type) => (file) => {
    if (dispatch) {
      dispatch(BlActions.importFile(type, file));
    }
  };

  const items = useMemo(() => {
    return [
      {
        label: (
          <FileUpload
            accept={"csv"}
            customDownloadFunction={onDownload("BEXT")}
          >
            <Button id={"bext-file"} style={{ width: 150 }}>
              {strings("bext_import")}
            </Button>
          </FileUpload>
        ),
        key: "0",
        customFilterKey: "csv_bext",
      },
      {
        label: (
          <FileUpload
            accept={"csv"}
            customDownloadFunction={onDownload("TELIA")}
          >
            <Button id={"teliae-file"} style={{ width: 150 }}>
              {strings("telia_import")}
            </Button>
          </FileUpload>
        ),
        key: "1",
        customFilterKey: "csv_telia",
      },
      {
        label: (
          <FileUpload
            accept={
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            }
            customDownloadFunction={onDownload("AFFRET")}
          >
            <Button id={"affret-file"} style={{ width: 150 }}>
              {strings("charter_import")}
            </Button>
          </FileUpload>
        ),
        key: "2",
        customFilterKey: "csv_charter",
      },
      {
        label: (
          <FileUpload
            accept={
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            }
            customDownloadFunction={onDownload("MANUAL_ORDER")}
          >
            <Button id={"manual-order-file"} style={{ width: 150 }}>
              {strings("manual_order_import")}
            </Button>
          </FileUpload>
        ),
        key: "3",
        customFilterKey: "csv_new_order",
      },
    ]
      .filter((el) => canCtx.can("display", el.customFilterKey))
      .map(({ customFilterKey, ...el }) => el);
    //eslint-disable-next-line
  }, [strings, onDownload, dispatch]);

  return (
    <div className={className}>
      <ModalImportReport
        id={"import-xls-report"}
        strings={strings}
        paginationOptions={paginationOptions}
      />
      <Dropdown trigger={"click"} menu={{ items }}>
        <Button id={id} icon={<UploadOutlined />} type="primary">
          {strings("import")}
        </Button>
      </Dropdown>
    </div>
  );
};

export default ImportXLS;
