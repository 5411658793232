import "./dataTable.css";

import { Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { BlActions, selectedIndex } from "../../../redux/bl";
import { useDispatch, useSelector } from "react-redux";
import useDimension from "../../../utils/hooks/useDimension";
import { getTranslate } from "react-localize-redux";

const DataTable = ({
  expandable,
  items,
  showHeader = true,
  reset,
  setReset = () => {},
  loading,
  itemClick,
  columns,
  actionsRender,
  setSelectedRowKeys,
  selectedRowKeys,
  isPagination = false,
  pagination = null,
  sorter = null,
  filters = {},
  allowScrollOnElement = false,
}) => {
  const [key, setKey] = useState(0);
  const dispatch = useDispatch();
  const { height } = useDimension();
  const data = items || [];
  const locale = useSelector((state) => state.locale);
  const strings = getTranslate(locale);
  const tableRef = useRef();
  const selectedIndexValue = useSelector(selectedIndex);

  if (data.length !== key) {
    setKey(data.length);
    if (setSelectedRowKeys) setSelectedRowKeys([]);
  }

  useEffect(() => {
    if (reset) {
      isPagination &&
        dispatch(BlActions.setPagination({ ...pagination, current: 1 }));
      setReset(false);
    }
    // eslint-disable-next-line
  }, [reset]);

  useEffect(() => {
    if (allowScrollOnElement && selectedIndexValue > -1 && !loading) {
      if (tableRef?.current) {
        const tableBody = tableRef.current.querySelector(".ant-table-body");
        const rowElement = tableRef.current.querySelector(
          //eslint-disable-next-line
          `tr[data-row-key=\"${selectedIndexValue}\"]`,
        );
        if (tableBody && rowElement) {
          tableBody.scrollTo({
            top: rowElement.offsetTop,
            behavior: "smooth",
          });
          rowElement.classList.add("selected-row-on-return-fadeout");
          if (dispatch) {
            dispatch(BlActions.setSelectedIndex(-1));
          }
          setTimeout(() => {
            rowElement.classList.remove("selected-row-on-return-fadeout");
          }, 6000);
        }
      }
    }
    //eslint-disable-next-line
  }, [selectedIndexValue, loading]);

  const onSelectChange = (selectedRowKeys) => {
    if (setSelectedRowKeys) setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = selectedRowKeys
    ? {
        selectedRowKeys,
        onChange: onSelectChange,
      }
    : null;

  const handleOnRowClick = (rest, index) => {
    if (itemClick) itemClick(rest);
  };

  return (
    <div>
      {selectedRowKeys &&
        selectedRowKeys.length > 0 &&
        actionsRender &&
        actionsRender()}
      <Table
        ref={tableRef}
        tableLayout="fixed"
        size={"small"}
        sticky={true}
        locale={{ emptyText: strings("no_values") }}
        showSorterTooltip={false}
        expandable={expandable}
        rowSelection={rowSelection}
        columns={columns}
        showHeader={showHeader}
        dataSource={data.map((item, index) => ({
          ...item,
          key: `row-${index}`,
        }))}
        rowClassName={(record) => {
          return `${record?.is_planned === false ? "table-row-yellow " : ""}${
            record?.error ? "table-row-red " : ""
          }row-flux`;
        }}
        rowKey={(record) => record.order || record.id}
        loading={loading}
        scroll={{
          y: height - 310,
        }}
        pagination={pagination ? pagination : false}
        onRow={(rest, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              handleOnRowClick(rest, rowIndex);
            },
          };
        }}
        onChange={(page, newFilters, sorterFilter) => {
          //setPagination({ ...pagination, current: page.current, pageSize: page.pageSize });
          if (newFilters) {
            dispatch(BlActions.setFilters({ ...filters, ...newFilters }));
          }
          isPagination &&
            dispatch(
              BlActions.setPagination({
                ...pagination,
                current: page.current,
                pageSize: page.pageSize,
              }),
            );
          if (sorterFilter.order) {
            isPagination &&
              dispatch(
                BlActions.setSorter({
                  ...sorter,
                  field: sorterFilter.column?.sortKey || sorterFilter.field,
                  order: sorterFilter.order,
                }),
              );
          } else {
            isPagination && dispatch(BlActions.setSorter({}));
          }
        }}
      />
    </div>
  );
};

export default DataTable;
