import { createReducer } from "reduxsauce";
import { types } from "./actions";

const initialState = {
  list: [],
  articles: [],
  listLoading: false,
};

const getOrderPalletsSuccess = (state = initialState, action) => {
  const { pallets, articles } = action;
  return {
    ...state,
    list: pallets,
    articles: articles,
    listLoading: false,
  };
};

const setPalletOrderLoader = (state = initialState) => {
  return {
    ...state,
    listLoading: true,
  };
};

const getOrderPalletsLoading = (state = initialState, action) => {
  const { loading } = action;
  return {
    ...state,
    listLoading: loading,
  };
};

const resetOrderPallets = (state = initialState) => ({
  ...state,
  list: [],
  articles: [],
});

export default createReducer(initialState, {
  [types.GET_ORDERS_PALLETS]: setPalletOrderLoader,
  [types.GET_ORDERS_PALLETS_SUCCESS]: getOrderPalletsSuccess,
  [types.GET_ORDERS_PALLETS_LOADING]: getOrderPalletsLoading,
  [types.RESET_ORDERS_PALLETS]: resetOrderPallets,
});
