import { createReducer } from "reduxsauce";

import { types } from "./actions";

const initialState = {
  renewPwd: false,
  token: null,
  token_type: null,
  attempts: 0,
  user_blocked: false,
  user_activated: true,
  current_user: {
    cgu: null,
    privacy_policy: null,
  },
};

// region Reducers
const loginAttempt = (state = initialState) => {
  const { attempts } = state;
  return { ...state, attempts: attempts + 1, user_activated: true };
};

const loginFailed = (state = initialState) => {
  const { attempts } = state;
  return { ...state, user_blocked: attempts >= 5 };
};

const loginUserDesactivated = (state = initialState) => {
  return { ...state, user_activated: false };
};

const loginSuccess = (state = initialState, action) => {
  const { token, refresh_token, token_type, authorization } = action;
  return {
    ...state,
    token,
    refresh_token,
    token_type,
    authorization,
    attempts: 0,
    user_activated: true,
  };
};

const logout = () => {
  return { ...initialState };
};

const renewSuccess = (state = initialState, action) => {
  const { renewPwd } = action;
  const { current_user } = state;
  current_user.renew_pwd = renewPwd;
  return { ...state, renewPwd, ...current_user };
};

const currentUserSuccess = (state = initialState, action) => {
  const { data } = action;
  data.role = data.user_role?.role;
  if (data.role_sealogis_remitters_users) {
    let formattedRoles = {};
    data.role_sealogis_remitters_users.forEach((el) => {
      const { role_sealogis_remitter } = el;
      const { sealogis_remitter, user_role } = role_sealogis_remitter;
      formattedRoles = {
        ...formattedRoles,
        [sealogis_remitter.code]: user_role.role,
      };
    });
    data.formatted_roles = formattedRoles;
  }
  return { ...state, current_user: data };
};

const changePasswordSuccess = (state = initialState) => {
  const { current_user } = state;
  current_user.renew_pwd = false;
  return { ...state, renewPwd: false, ...current_user };
};

const closeModaleUserDesactivated = (state = initialState, action) => {
  return { ...state, user_activated: true };
};

export default createReducer(initialState, {
  [types.LOGIN_REQUEST]: loginAttempt,
  [types.LOGIN_REFRESH]: loginAttempt,
  [types.LOGOUT]: logout,
  [types.LOGIN_FAILURE]: loginFailed,
  [types.LOGIN_SUCCESS]: loginSuccess,
  [types.RENEW_SUCCESS]: renewSuccess,
  [types.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [types.USER_DESACTIVATED]: loginUserDesactivated,
  [types.USER_DESACTIVATED_CLOSE_MODALE]: closeModaleUserDesactivated,
  [types.CURRENT_USER_SUCCESS]: currentUserSuccess,
});
