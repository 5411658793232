import { createReducer } from "reduxsauce";

import { types } from "./actions";

const initialState = {
  bcu_data: [],
  partner_carriers: [],
  type_flux: [],
  xc_status: [],
};

const bcuDataSuccess = (state = initialState, action) => {
  const { bcuData } = action;
  return { ...state, bcu_data: bcuData };
};

const typeFluxSuccess = (state = initialState, action) => {
  const { typeFlux } = action;
  return { ...state, type_flux: typeFlux };
};

const xcStatusSuccess = (state = initialState, action) => {
  const { xcStatus } = action;
  return { ...state, xc_status: xcStatus };
};

const partnerCarrierSuccess = (state = initialState, action) => {
  const { partners } = action;
  return { ...state, partner_carriers: partners };
};

export default createReducer(initialState, {
  [types.BCU_DATA_SUCCESS]: bcuDataSuccess,
  [types.PARTNER_CARRIER_SUCCESS]: partnerCarrierSuccess,
  [types.TYPE_FLUX_SUCCESS]: typeFluxSuccess,
  [types.XC_STATUS_SUCCESS]: xcStatusSuccess,
});
