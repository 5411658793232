import { useSubscription } from "@apollo/client";

export const useSub = (subscription, params = {}) => {
  const handleError = (error) => {
    console.log("DEBUG: web_socket error => ", error);
  };

  return useSubscription(subscription, {
    ...params,
    onError: handleError,
  });
};
