import React, { useEffect } from "react";
import { Button, Modal, Popconfirm, Space, Table, Upload } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  DeleteOutlined,
  DownloadOutlined,
  MinusOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { AuthActions } from "../../../redux/auth";
import { BlActions } from "../../../redux/bl";
import { showError } from "../../../utils/notifications-helper";
import "./style.scss";
import { CanWithChildren } from "../can";

const ArticleFileModal = ({
  articles,
  visible,
  strings,
  onClose = () => {},
}) => {
  const [{ loadingId, loadingEvent }, setIsLoading] = useState({});
  const dispatch = useDispatch();
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const handleResize = () => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFile = (file) => {
    const { u_name, id } = file || {};
    if (u_name) {
      setIsLoading({ loadingId: id, loadingEvent: "download" });
      dispatch(
        AuthActions.getAdminFileSignedUrl({
          location: u_name,
          callbacks: {
            onError: () => {},
            onSuccess: (url) => {
              console.log(url);
              url && window.open(url, "_blank");
              setIsLoading({});
            },
          },
        }),
      );
    }
  };

  const beforeUpload = (file, recordId) => {
    if (!file) return;
    if (file.size / 1024 / 1024 > 4) {
      showError("file_size_error", strings);
      return;
    }
    setIsLoading({ loadingEvent: "upload", loadingId: recordId });
    dispatch(
      BlActions.createArticleFileRequest(file, recordId, () =>
        setIsLoading({}),
      ),
    );
    return false;
  };

  const handleDelete = (fileId) => {
    setIsLoading({ loadingId: fileId, loadingEvent: "delete" });
    dispatch(
      BlActions.deleteArticleFileRequest(fileId, () => setIsLoading({})),
    );
  };

  const columns = [
    {
      title: strings("name"),
      dataIndex: "name",
    },
    {
      title: strings("create_date"),
      dataIndex: "created_at",
    },
    {
      title: strings("action"),
      fixed: "right",
      width: "100px",
      align: "center",
      render: (record) => (
        <Space>
          <Button
            id={`download-button-${record.id}`}
            shape={"circle"}
            icon={<DownloadOutlined />}
            onClick={() => handleFile(record)}
            disabled={
              loadingEvent &&
              !(loadingId === record.id && loadingEvent === "download")
            }
            loading={loadingId === record.id && loadingEvent === "download"}
          />
          <CanWithChildren
            action={"display"}
            subject={"btn_delete_article_file"}
          >
            <Popconfirm
              title={strings("confirm_action")}
              okText={strings("yes")}
              cancelText={strings("no")}
              onConfirm={() => handleDelete(record.id)}
            >
              <Button
                id={`delete-button-${record.id}`}
                shape={"circle"}
                danger
                icon={<DeleteOutlined />}
                disabled={
                  !record.deletable ||
                  (loadingEvent &&
                    !(loadingId === record.id && loadingEvent === "delete"))
                }
                loading={loadingId === record.id && loadingEvent === "delete"}
              />
            </Popconfirm>
          </CanWithChildren>
        </Space>
      ),
    },
  ];

  const expandable = {
    defaultExpandAllRows: true,
    expandRowByClick: false,
    expandIcon: ({ expanded, onExpand, record }) =>
      expanded ? (
        <Button
          type={"primary"}
          ghost
          size={"small"}
          icon={<MinusOutlined />}
          onClick={(e) => onExpand(record, e)}
        />
      ) : (
        <Button
          size={"small"}
          type={"primary"}
          icon={<PlusOutlined />}
          onClick={(e) => onExpand(record, e)}
        />
      ),
    expandedRowRender: (record) => (
      <Table
        bordered
        scr
        dataSource={record?.files || []}
        columns={columns}
        pagination={false}
      />
    ),
  };

  return (
    <Modal
      title={strings("title_doc")}
      open={visible}
      onOk={onClose}
      onCancel={onClose}
      width={1000}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Table
        tableLayout="fixed"
        size={"small"}
        scroll={{ y: dimensions.height - 400, x: "auto" }}
        expandable={expandable}
        rowClassName={"global-row"}
        dataSource={articles || []}
        rowKey={(record) => record.id}
        showSorterTooltip={false}
        showHeader={false}
        pagination={false}
        columns={[
          {
            sorter: (a, b) => a?.localeCompare(b.id),
            render: ({ article, id }) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontWeight: "bold", color: "#0480FC" }}>
                    {article.label}
                  </span>
                  <CanWithChildren
                    action={"display"}
                    subject={"btn_upload_article_file"}
                  >
                    <Upload
                      id={`upload-component`}
                      beforeUpload={(file) => beforeUpload(file, id)}
                      maxCount={1}
                      showUploadList={false}
                      customRequest={() => 1}
                      onPreview={() => {}}
                    >
                      <Button
                        id={`upload-button`}
                        type="primary"
                        icon={<UploadOutlined />}
                        disabled={loadingEvent}
                        loading={loadingId === id && loadingEvent === "upload"}
                      >
                        {strings("upload")}
                      </Button>
                    </Upload>
                  </CanWithChildren>
                </div>
              );
            },
          },
        ]}
      />
    </Modal>
  );
};

export default ArticleFileModal;
