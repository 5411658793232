import React from "react";
import { Route, Switch, Redirect } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./history";
import store from "./store";
import {
  Login,
  ErrorBoundary,
  Home,
  FluxTracking,
  BlInfo,
} from "../ui/screens";

const AuthRoute = ({ component: Component, ...rest }) => {
  // fixme: Add your own validation logic for auth pages (ex. a valid token)
  const { token } = store.getState().auth;
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <ErrorBoundary>
            <Component {...props} {...rest} />
          </ErrorBoundary>
        ) : (
          <Redirect to={{ pathname: "/", state: { ...rest.location } }} />
        )
      }
    />
  );
};

// Main router which handle user navigation
const Router = () => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path={"/"} component={Login} />
        <AuthRoute exact path={"/home"} component={Home} />
        <AuthRoute exact path={"/fluxTracking/:id"} component={FluxTracking} />
        <AuthRoute exact path={"/blInfo/:id"} component={BlInfo} />
        <Route component={() => <div>404</div>} />
      </Switch>
    </ConnectedRouter>
  );
};

export default Router;
