import AuthLayout from "../../layouts/authLayout";
import React, { useEffect, useMemo, useState } from "react";
import { DataTable, BlFilter } from "../../components";
import "./style.css";
import FluxFrameInformation from "./fluxFrameInformation";
import {
  MinusOutlined,
  PlusOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import { Button, Row, Typography } from "antd";
import BlStepDrawer from "../../components/blStepDrawer";
import ExpandStep from "../../components/expandStep";
import { useRoleUser } from "../../../utils/hooks/useRoleUser";

const FluxTracking = ({ strings, id, BlRequest, BlClear, bl }) => {
  const [list, setList] = useState([]);
  const [valueList, setValueList] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [filters, setFilters] = useState({
    types: 0,
    state: "",
    cities: "",
    status: "",
  });
  const [visible, setVisible] = useState(false);
  const [currentBlStep, setCurrentBlStep] = useState({});
  useRoleUser();

  useEffect(() => {
    if (id && BlRequest) {
      BlRequest(id);
    }
    return () => BlClear();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (bl) {
      setList(bl.order_steps);
    }
  }, [bl]);

  useEffect(() => {
    if (list) setValueList(list);
  }, [list]);

  const onClose = () => {
    setVisible(false);
  };

  const columns = [
    {
      dataIndex: "round",
      sorter: (a, b) => a?.localeCompare(b.id),
      render: (text) => (
        <span
          style={{ fontWeight: "bold", color: "#0480FC" }}
          id={`step-${text + 1}`}
        >
          {"Étape " + (text + 1)}
        </span>
      ),
    },
  ];

  const onScrollTop = () => {
    const element = document.getElementById("content");
    element.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    window.scrollTo(0, 0);
  };

  const showDrawer = (item) => {
    setCurrentBlStep(item);
    setVisible(true);
  };

  const allRowsKeys = useMemo(() => {
    const ark = valueList.map(({ order, id }) => order || id);
    setExpandedRowKeys(ark);
    return ark;
  }, [valueList]);

  const changeRowsKey = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys((prevState) => [
        ...prevState,
        record.order || record.id,
      ]);
    } else {
      setExpandedRowKeys(
        expandedRowKeys.filter((id) => id !== (record.order || record.id)),
      );
    }
  };

  const onClickAllExpand = () => {
    setExpandedRowKeys((prevState) =>
      prevState.length === 0 ? allRowsKeys : [],
    );
  };

  const filterList = (list) => {
    return list?.filter((item) => {
      return (
        (filters.types === 0 ||
          filters.types.length === 0 ||
          item.order_steps_partners.find(
            ({ partner }) => partner?.partner_type?.id === filters.types,
          ) !== undefined) &&
        (filters.state.length === 0 ||
          item.order_steps_partners.find(({ under_order_steps }) =>
            under_order_steps?.find(
              ({ etat_livraison }) => etat_livraison === filters.state,
            ),
          ) !== undefined) &&
        (filters.cities.length === 0 ||
          item.order_steps_partners.find(
            ({ partner }) => partner?.address?.city === filters.cities,
          ) !== undefined) &&
        (filters.status.length === 0 ||
          item.order_steps_partners.find(({ under_order_steps }) =>
            under_order_steps?.find(
              ({ order_step_statut }) =>
                order_step_statut?.id === filters.status,
            ),
          ) !== undefined)
      );
    });
  };

  const expandable = {
    defaultExpandAllRows: true,
    expandRowByClick: true,
    expandedRowKeys: expandedRowKeys,
    onExpand: changeRowsKey,
    expandIcon: ({ expanded, onExpand, record }) =>
      expanded ? (
        <Button
          type={"primary"}
          ghost
          size={"small"}
          icon={<MinusOutlined />}
          onClick={(e) => onExpand(record, e)}
        />
      ) : (
        <Button
          size={"small"}
          type={"primary"}
          icon={<PlusOutlined />}
          onClick={(e) => onExpand(record, e)}
        />
      ),
    expandedRowRender: (record) => (
      <ExpandStep
        data={record}
        strings={strings}
        showDrawer={showDrawer}
        bl={bl}
      />
    ),
  };

  const { cities, states } = useMemo(() => {
    const cities = [];
    const states = [];
    for (const l of list) {
      for (const order_steps_partner of l?.order_steps_partners) {
        const city =
          order_steps_partner?.flux_step_partner?.partner?.address?.city;
        if (city && !cities.find(({ code }) => city === code)) {
          cities.push({ code: city, name: city });
        }

        for (const uos of order_steps_partner?.under_order_steps) {
          if (
            uos?.etat_livraison &&
            !states.find(({ code }) => uos.etat_livraison === code)
          ) {
            states.push({ code: uos.etat_livraison, name: uos.etat_livraison });
          }
        }
      }
    }
    return { cities, states };
  }, [list]);

  return (
    <>
      <BlStepDrawer
        onClose={onClose}
        visible={visible}
        item={currentBlStep}
        bl={bl}
        strings={strings}
      />
      <AuthLayout isBack={bl?.bl_num}>
        <div className="bl_information">
          <FluxFrameInformation strings={strings} bl={bl} />
        </div>
        <BlFilter
          id={id}
          strings={strings}
          setFilters={setFilters}
          filters={filters}
          bl={bl}
          cities={cities}
          states={states}
          btnCloseStep={
            <Button
              type="primary"
              onClick={onClickAllExpand}
              icon={
                expandedRowKeys.length === 0 ? (
                  <PlusOutlined />
                ) : (
                  <MinusOutlined />
                )
              }
            />
          }
        />
        {bl?.hasNoSteps && (
          <Typography>{strings("order_only_received")}</Typography>
        )}
        {valueList?.length > 0 && (
          <DataTable
            columns={columns}
            showHeader={false}
            items={filterList(valueList)}
            expandable={expandable}
          />
        )}
        <Row style={{ justifyContent: "flex-end", marginRight: 18 }}>
          <Button
            style={{ margin: "10px 0" }}
            type="primary"
            shape="circle"
            icon={<VerticalAlignTopOutlined />}
            onClick={onScrollTop}
          />
        </Row>
      </AuthLayout>
    </>
  );
};

export default FluxTracking;
