import { createAction } from "../../utils";

export const types = {
  BLS_REQUEST: "BLS_REQUEST",
  BLS_SUCCESS: "BLS_SUCCESS",
  SET_BLS: "SET_BLS",
  SET_SKBLS: "SET_SKBLS",
  BL_REQUEST: "BL_REQUEST",
  BL_SUCCESS: "BL_SUCCESS",
  BL_UPDATE_REQUEST: "BL_UPDATE_REQUEST",
  BL_UPDATE_SUCCESS: "BL_UPDATE_SUCCESS",
  BL_UPDATE_FAILURE: "BL_UPDATE_FAILURE",
  BL_CLEAR: "BL_CLEAR",
  SET_FILTERS: "SET_FILTERS",
  RESET_FILTERS: "RESET_FILTERS",
  SET_BL_SEARCH: "SET_BL_SEARCH",
  EXPORT_SK_REQUEST: "EXPORT_SK_REQUEST",
  EXPORT_CLASSIC_REQUEST: "EXPORT_CLASSIC_REQUEST",
  SET_PAGINATION: "SET_PAGINATION",
  SET_SORTER: "SET_SORTER",
  IMPORT_FILE: "IMPORT_FILE",
  GET_EDI_FILE_REQUEST: "GET_EDI_FILE_REQUEST",
  GET_EDI_FILE_SUCCESS: "GET_EDI_FILE_SUCCESS",
  SET_IMPORT_REPORTS: "SET_IMPORT_REPORTS",
  CREATE_FILE_REMITTER_REQUEST: "CREATE_FILE_REMITTER_REQUEST",
  CREATE_FILE_REMITTER_SUCCESS: "CREATE_FILE_REMITTER_SUCCESS",
  CREATE_ARTICLE_FILE_REQUEST: "CREATE_ARTICLE_FILE_REQUEST",
  CREATE_ARTICLE_FILE_SUCCESS: "CREATE_ARTICLE_FILE_SUCCESS",
  DELETE_FILE_REMITTER_REQUEST: "DELETE_FILE_REMITTER_REQUEST",
  DELETE_FILE_REMITTER_SUCCESS: "DELETE_FILE_REMITTER_SUCCESS",
  DELETE_ARTICLE_FILE_REQUEST: "DELETE_ARTICLE_FILE_REQUEST",
  DELETE_ARTICLE_FILE_SUCCESS: "DELETE_ARTICLE_FILE_SUCCESS",
  SEARCH_FOR_CLIENT: "SEARCH_FOR_CLIENT",
  SEARCH_FOR_CLIENT_SUCCESS: "SEARCH_FOR_CLIENT_SUCCESS",
  SEARCH_FOR_CLIENT_RESET: "SEARCH_FOR_CLIENT_RESET",
  GET_SIGNED_URL: "GET_SIGNED_URL",
  GET_SIGNED_URL_SUCCESS: "GET_SIGNED_URL_SUCCESS",
  RESET_SIGNED_URL: "RESET_SIGNED_URL",
  UPLOAD_POD_SUCCESS: "UPLOAD_POD_SUCCESS",
  RESET_POD_SEND_FILE: "RESET_POD_SEND_FILE",
  SET_SELECTED_INDEX: "SET_SELECTED_INDEX",
  GET_FILE_TYPE_REQUEST: "GET_FILE_TYPE_REQUEST",
  GET_NUMBER_FILE_TYPE_REQUEST: "GET_NUMBER_FILE_TYPE_REQUEST",
  GET_NUMBER_FILE_TYPE_SUCCESS: "GET_NUMBER_FILE_TYPE_SUCCESS",
  GET_FILE_TYPE_SUCCESS: "GET_FILE_TYPE_SUCCESS",
  GET_FILES_REMITTER_REQUEST: "GET_FILES_REMITTER_REQUEST",
  GET_FILES_REMITTER_SUCCESS: "GET_FILES_REMITTER_SUCCESS",
  SEARCH_FOR_ORDERS_REQUEST: "SEARCH_FOR_ORDERS_REQUEST",
  SEARCH_FOR_ORDERS_SUCCESS: "SEARCH_FOR_ORDERS_SUCCESS",
};

const actions = {
  getFilesRemitterRequest: ({ typeId, remitterId, orderId }) =>
    createAction(types.GET_FILES_REMITTER_REQUEST, {
      typeId,
      remitterId,
      orderId,
    }),
  getFilesRemitterSuccess: (values) =>
    createAction(types.GET_FILES_REMITTER_SUCCESS, { values }),
  getFileTypeRequest: () => createAction(types.GET_FILE_TYPE_REQUEST),
  getFileTypeSuccess: (values) =>
    createAction(types.GET_FILE_TYPE_SUCCESS, { values }),
  getNumberFileTypeRequest: (remitter_id) =>
    createAction(types.GET_NUMBER_FILE_TYPE_REQUEST, { remitter_id }),
  getNumberFileTypeSuccess: (values) =>
    createAction(types.GET_NUMBER_FILE_TYPE_SUCCESS, { values }),
  exportSKRequest: (filters, sorter) =>
    createAction(types.EXPORT_SK_REQUEST, { filters, sorter }),
  exportClassicRequest: (filters, sorter) =>
    createAction(types.EXPORT_CLASSIC_REQUEST, { filters, sorter }),
  blsRequest: (offset, limit, filters, sorter) =>
    createAction(types.BLS_REQUEST, { offset, limit, filters, sorter }),
  blsSuccess: (values) => createAction(types.BLS_SUCCESS, { values }),
  setBls: (data) => createAction(types.SET_BLS, { data }),
  setSkBls: (data) => createAction(types.SET_SKBLS, { data }),
  blRequest: (id) => createAction(types.BL_REQUEST, { id }),
  blSuccess: (values, edi_calcul) =>
    createAction(types.BL_SUCCESS, { values, edi_calcul }),
  blUpdate: (
    values,
    callbacks = { onSuccess: () => {}, onFailure: () => {} },
    silentUpdate = false,
  ) =>
    createAction(types.BL_UPDATE_REQUEST, { values, callbacks, silentUpdate }),
  blUpdateSuccess: (values) =>
    createAction(types.BL_UPDATE_SUCCESS, { values }),
  blUpdateFailure: () => createAction(types.BL_UPDATE_FAILURE),
  bl_clear: () => createAction(types.BL_CLEAR),
  setFilters: (filters) => createAction(types.SET_FILTERS, { filters }),
  resetFilters: () => createAction(types.RESET_FILTERS),
  setPagination: (pagination) =>
    createAction(types.SET_PAGINATION, { pagination }),
  setSorter: (sorter) => createAction(types.SET_SORTER, { sorter }),
  setBlSearch: (blSearch) => createAction(types.SET_BL_SEARCH, { blSearch }),
  importFile: (importType, file, blNum = null) =>
    createAction(types.IMPORT_FILE, { importType, file, blNum }),
  setImportReport: (reports) =>
    createAction(types.SET_IMPORT_REPORTS, { reports }),
  resetImportReport: () =>
    createAction(types.SET_IMPORT_REPORTS, { reports: [] }),
  getEdiFile: (file_type, file_name, callback) =>
    createAction(types.GET_EDI_FILE_REQUEST, {
      file_type,
      file_name,
      callback,
    }),
  createFileRemitterRequest: ({
    file,
    remitterId,
    typeId,
    orderId,
    callback,
  }) =>
    createAction(types.CREATE_FILE_REMITTER_REQUEST, {
      file,
      remitterId,
      typeId,
      orderId,
      callback,
    }),
  createFileRemitterSuccess: (toAdd) =>
    createAction(types.CREATE_FILE_REMITTER_SUCCESS, { toAdd }),
  deleteFileRemitterRequest: (fileId, callback) =>
    createAction(types.DELETE_FILE_REMITTER_REQUEST, { fileId, callback }),
  deleteFileRemitterSuccess: (fileId) =>
    createAction(types.DELETE_FILE_REMITTER_SUCCESS, { fileId }),
  createArticleFileRequest: (file, articleId, callback) =>
    createAction(types.CREATE_ARTICLE_FILE_REQUEST, {
      file,
      articleId,
      callback,
    }),
  createArticleFileSuccess: (toAdd, id) =>
    createAction(types.CREATE_ARTICLE_FILE_SUCCESS, { id, toAdd }),
  deleteArticleFileRequest: (fileId, callback) =>
    createAction(types.DELETE_ARTICLE_FILE_REQUEST, { fileId, callback }),
  deleteArticleFileSuccess: (fileId, articleId) =>
    createAction(types.DELETE_ARTICLE_FILE_SUCCESS, { fileId, articleId }),

  searchForClients: (
    value,
    callbacks = { onSuccess: () => {}, onError: () => {} },
  ) => createAction(types.SEARCH_FOR_CLIENT, { value, callbacks }),
  searchForClientsSuccess: (clients, nbTotalClients) =>
    createAction(types.SEARCH_FOR_CLIENT_SUCCESS, { clients, nbTotalClients }),
  resetSearchedClients: () => createAction(types.SEARCH_FOR_CLIENT_RESET),
  getSignedUrl: (location) => createAction(types.GET_SIGNED_URL, { location }),
  getSignedUrlSuccess: (url) =>
    createAction(types.GET_SIGNED_URL_SUCCESS, { url }),
  resetSignedUrl: () => createAction(types.RESET_SIGNED_URL),
  uploadPodSuccess: (order) =>
    createAction(types.UPLOAD_POD_SUCCESS, { order }),
  resetPodFileSuccess: () => createAction(types.RESET_POD_SEND_FILE),
  setSelectedIndex: (index) =>
    createAction(types.SET_SELECTED_INDEX, { index }),
  searchForOrdersRequest: (query, showOnDashboard, callback) =>
    createAction(types.SEARCH_FOR_ORDERS_REQUEST, {
      query,
      showOnDashboard,
      callback,
    }),
  searchForOrdersSuccess: (orders) =>
    createAction(types.SEARCH_FOR_ORDERS_SUCCESS, { orders }),
};

export default actions;
