import React, { useEffect, useState } from "react";
import "./style.css";
import AuthLayout from "../../layouts/authLayout";
import { PartnerInfo } from "./PartnerInfo";

const BlInfo = ({ strings, id, bl, BlRequest }) => {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    if (id && BlRequest) {
      BlRequest(id);
    }
  });

  useEffect(() => {
    if (bl) {
      setPartners([
        { data: bl.expediteur, type: "expediteur" },
        { data: bl.destinataire, type: "destinataire" },
        { data: bl.expedition, type: "expedition" },
        { data: bl.commande, type: "commande" },
        { data: bl.transporteur, type: "transporteur" },
        { data: bl.article_orders, type: "articles" },
      ]);
    }
    //eslint-disable-next-line
  }, [bl?.id]);

  return (
    <AuthLayout>
      <span className={"bl-number"}>
        {strings("bl_number")} : {bl?.bl_num}
      </span>
      <div style={{ overflow: "scroll" }}>
        {partners.map(({ type, data }) => (
          <PartnerInfo
            key={`bl-info-partner-type-${type}`}
            strings={strings}
            type={type}
            partner={data}
          />
        ))}
      </div>
    </AuthLayout>
  );
};

export default BlInfo;
