/* eslint-disable import/no-anonymous-default-export */
import { gql } from "@apollo/client";

export default {
  orderSubscription: (where, params) => gql`
    subscription ordersSub(
      ${params}
      $order_by: [orders_order_by!]
      $offset: Int!
      $limit: Int
    ) {
      orders(
        where: {${where}}
        order_by: $order_by
        offset: $offset
        limit: $limit
      ) {
        id
        pickup_date
        dde_prep_date
        bl_num
        br_num
        status_code
        asked_delivery_date
        delivery_instruction
        nc_details
        del_prep
        del_liv
        del_liv_jo
        eca_liv
        asked_eca_liv
        sealogis_commentary
        fields_in_edition
        asked_appointment_date
        appointment_date
        real_delivery_date
        charter_reference
        original_bl_num
        is_charter_canceled
        pod_file
        sorting_view {
          receipt_date
        }
        order_volumes {
          data_type
          nb_pallets
          nb_total_packages
          weight
        }
        telia_trackings {
          id
          receipt_url
          carrier_code
          tracking_url
          appointment_date
          real_delivery_date
          shipping_date
          product_code
          under_order_step_status {
            sealogis_value
            code
          }
        }
        bext_trackings {
          id
          date_shipping
          carrier_code
          carrier_name
          telia_carrier_code
          product_code
          under_order_step_status {
            sealogis_value
            code
          }
        }
        charter_tracking {
          id
          reference
          product_code
          carrier_code
          pickup_date
          dde_prep_date
          appointment_date
          asked_appointment_date
          delivery_date
        }
        error
        edi_trackings(where: {type: {_eq: "HANMOV"}}) {
          id
          receipt_date
        }
        dispute_reason {
          id
          code
        }
        delay_reason {
          id
          code
        }
        responsibility {
          id
          code
        }
        delivery_state {
          id
          code
        }
        theoretical_delivery_status {
          id
          code
        }
        delivery_status {
          id
          code
        }
        
        order_steps {
          id
          round
          order_steps_partners {
            id
            flux_step_partner {
              id
              partner {
                id
                partner_type {
                  id
                  name
                  code
                }
              }
            }
            partner {
              id
              partner_type {
                id
                name
                code
              }
            }
            under_order_steps(order_by: {id: asc}) {
              id
              order_step_statut {
                id
                name
              }
              under_order_step_status {
                id
                sealogis_value
                code
                order_state {
                  id
                  sealogis_value
                  code
                }
              }
            }
          }
        }
        
        client_orders(
          where: {
            client: { party_function_code_qualifier: { _in: ["DP", "UC"] } }
          }
        ) {
          data_type
          client {
            id
            name
            party_function_code_qualifier
            address {
              id
              zip_code
            }
          }
        }
        order_state {
          code
          sealogis_value
        }
        finishedSteps: order_steps_aggregate(
          where: { order_step_statut: { name: { _eq: "Livre" } } }
        ) {
          aggregate {
            count
          }
        }
        allSteps: order_steps_aggregate {
          aggregate {
            count
          }
        }
        client {
          id
          name
          code
        }
        flux {
          id
          name
          flux_steps_aggregate {
            aggregate {
              count
            }
          }
        }
        original_order {
          edi_trackings(where: {type: {_eq: "HANMOV"}}) {
            id
            receipt_date
          }
          client_orders(
            where: {
              client: { party_function_code_qualifier: { _in: ["DP", "UC"] } }
            }
          ) {
            data_type
            client {
              id
              name
              party_function_code_qualifier
              address {
                id
                zip_code
              }
            }
          }
        }
      }
    }
  `,

  skOrderSubscription: (where, params) => gql`
    subscription ordersSub(
      ${params}
      $order_by: [orders_order_by!]
      $offset: Int!
      $limit: Int
    ) {
      orders(
        where: {
          ${where}
          show_on_dashboard: {_eq: true}
        }
        order_by: $order_by
        offset: $offset
        limit: $limit
      ) {
        id
        bl_num
        delivery_num
        order_num
        fields_in_edition
        multi_removals
        asked_delivery_date
        theoretical_weight
        real_delivery_date
        charter_reference
        delivery_code
        bcu_removal_location {
          code
          id
        }
        files_remitters(where: {archived: {_eq: false}}) {
          id
        }
        order_volumes {
          data_type
          nb_pallets
          nb_big_bag
          nb_stackable_bulk_packages
          nb_bulk_packages
          nb_total_packages
          equivalent_80_per_120
          raw_weight
          weight
          nb_total_packages
        }
        order_data_bcu {
          id
          shipment_number
          removal_date
          removal_date_hours
          asked_delivery_date
          commentary
          big_bag
          stackable_bulk_box
          bulk_box
          pallets
          bcu_removal_location_city {
            id
            loading_zone
          }
        }
        order_data_sealogis {
          id
          update_status_SAP
          update_rate_SAP
          extra_cost_start
          extra_cost_destination
          extra_cost_commentary
          commentary
          removal_date
          removal_date_hours
          is_carrier_assigned
          is_volume_accepted
          xc_status_id
          xc_status {
            id
            code
            label
          }
          delivery_code
          partner {
            sealogis_code
          }
          offset_pattern_pickup {
            sealogis_code
          }
          offset_responsibility {
            sealogis_code
          }
          shipment_status {
            sealogis_code
          }
          extra_cost_reason_start {
            sealogis_code
          }
          extra_cost_reason_start_responsibility {
            sealogis_code
          }
          extra_cost_reason_destination {
            sealogis_code
          }
          extra_cost_reason_destination_responsibility {
            sealogis_code
          }
        }
        order_data_sk {
          commentary
          validation
          type_flux_id
          type_flux_sk {
            id
            code
            label
          }
        }
        client_orders(where: {data_type: {_eq: "remitter"}}) {
          client_code
          client {
            name
            delivery_mode
            address {
              addr1
              city
              country_code
              zip_code
            }
          }
        }
      }
    }
  `,
  testBls: (where, params, whereRemitter) => gql`
    query getHomeData(
      ${params}
      $user_id: Int
      $order_by: orders_order_by
    ) {
      sealogis_remitters(
        where: {${whereRemitter}}
      ) {
        id
        code
        wms_code
        name
      }

      orders_aggregate(
        where: {${where}}
      ) {
        aggregate {
            count
        }
      }
    }
    `,
  testBl: gql`
    query ($id: Int) {
      partner_type {
        id
        name
      }

      orders(where: { id: { _eq: $id } }) {
        id
        bl_num
        br_num
        status_code
        asked_delivery_date
        theoretical_weight
        mesured_weight
        sealogis_commentary
        delivery_instruction
        pickup_date
        dde_prep_date
        original_bl_num
        is_charter_canceled
        sorting_view {
          receipt_date
        }
        error
        edi_tracking_failures(where: { done: { _eq: false } }) {
          id
          type
          error
          file_name
          done
        }
        edi_trackings(order_by: { created_at: desc }) {
          id
          file_name
          type
          created_at
          receipt_date
        }
        article_orders {
          id
          article {
            code
            id
            label
          }
          files(
            where: { archived: { _eq: false } }
            order_by: { created_at: desc }
          ) {
            id
            name
            u_name
            deletable
            created_at
          }
        }
        telia_trackings {
          id
          receipt_url
          shipping_reference
          carrier_code
          tracking_url
          carrier_code
          appointment_date
          real_delivery_date
        }
        bext_trackings {
          id
          telia_carrier_code
          under_order_step_status {
            sealogis_value
            code
          }
        }
        charter_tracking {
          product_code
          delivery_date
          appointment_date
        }
        delivery_state {
          id
          code
        }
        theoretical_delivery_status {
          id
          code
        }
        delivery_status {
          id
          code
        }

        order_volumes {
          data_type
          nb_pallets
          nb_total_packages
          nb_bulk_packages
          weight
        }

        order_state {
          code
        }
        client_orders(
          where: {
            client: { party_function_code_qualifier: { _in: ["DP", "UC"] } }
          }
        ) {
          data_type
          client_code
          client {
            code
            address {
              addr1
              addr2
              zip_code
              city
            }
            name
            email
            phone_number
            party_function_code_qualifier
          }
        }

        order_parties {
          id
          party_function_code_qualifier
          partner_client_code
          sealogis_remitter {
            EORI_number
            address
            ape_code
            code
            comercial
            email
            fax_number
            identification_number
            name
            name_of_company
            phone_number
            town
            tva_number
            zip_code
          }
        }

        flux {
          id
          flux_steps_aggregate {
            aggregate {
              count
            }
          }
        }

        order_steps_aggregate {
          aggregate {
            count
          }
        }
        order_steps(order_by: { round: asc }) {
          id
          round
          order_steps_partners {
            id
            partner {
              id
              name
              sealogis_code
              edi_code
              address {
                id
                city
              }
              partner_type {
                id
                name
                code
              }
            }
            flux_step_partner {
              id
              partner {
                id
                name
                sealogis_code
                edi_code
                address {
                  id
                  city
                }
                partner_type {
                  id
                  name
                  code
                }
              }
            }
            under_order_steps(order_by: { id: asc }) {
              id
              etat_livraison
              type
              datetime_etat
              partner_datetime_etat
              arrival_datetime
              departure_datetime
              load_datetime
              prepare_datetime
              print_datetime
              request_datetime
              ship_datetime
              order_step_status_id
              order_step_statut {
                id
                name
              }
              under_order_step_status {
                id
                sealogis_value
                code
                order_state {
                  id
                  sealogis_value
                  code
                }
              }
            }
          }
          order_step_statut {
            name
          }
        }
        client {
          id
          name
          address
          zip_code
          phone_number
          email
          flux {
            sealogis_remitter {
              code
              name
              wms_code
            }
            flux_steps_aggregate {
              aggregate {
                count
              }
            }
          }
        }
        flux {
          id
          name
        }
        original_order {
          edi_trackings(order_by: { created_at: desc }) {
            id
            file_name
            type
            created_at
            receipt_date
          }
          client_orders(
            where: {
              client: { party_function_code_qualifier: { _in: ["DP", "UC"] } }
            }
          ) {
            data_type
            client {
              id
              name
              party_function_code_qualifier
              address {
                id
                zip_code
              }
            }
          }
        }
      }
    }
  `,
  bls: () => gql`
    query get_bls {
      bl {
        id
        o_bl_number
        o_order_date
        s_shipper_code_5
        s_shipper_code_10
        s_rem_code
        s_shipper_name
        s_shipper_code
        s_shiper_addr1
        s_shipper_addr2
        s_shipper_addr3
        s_shipper_zip
        s_shipper_city
        s_shipper_country_code
        s_shipper_country_name
        s_shipper_contact_name
        s_shipper_tel
        s_shipper_area
        s_shipper_mail
        s_removal_place_name
        s_removal_place_code
        s_removal_place_addr1
        s_removal_place_addr2
        s_removal_place_addr3
        s_removal_place_zip
        s_removal_place_city
        s_removal_place_country_code
        s_removal_place_country_name
        s_removal_place_contact_name
        s_removal_place_contact_tel
        s_removal_place_mail
        s_removal_flag
        r_consignee_code
        r_consignee_name
        r_consignee_addr1
        r_consignee_addr2
        r_consignee_addr3
        r_consignee_addr4
        r_consignee_zip
        r_consignee_city
        r_consignee_contact_name
        r_consignee_tel
        r_consignee_ref
        r_consignee_mail
        r_consignee_tel2
        r_consignee_insee_country
        r_consignee_country_name
        r_consignee_type
        e_total_packages
        e_package_number
        e_pallett_number
        e_consigned_pallett_number
        e_weight
        e_nature
        e_tax_units
        e_total_packages_nb
        e_url_BL
        e_UM_number
        e_dangerous_goods
        e_lenght
        e_Volume
        e_linear_floor
        e_value
        e_counter_refund
        e_commodity_value
        o_declare_value_currency
        o_reimburs_currency
        o_commodity_value_currency
        e_customer_account
        e_preparator_code
        e_Incoterm
        e_area
        e_transport_amount
        e_width
        e_iata_code
        e_contract_number
        e_nb_palet1
        e_typ_palet1
        e_nb_palet2
        e_typ_palet2
        e_typ_palet3
        e_nb_palet3
        e_height
        e_vol_alco
        e_vol_alco_pur
        e_ref_alco_doc
        e_cat_prod
        e_temp_min
        e_temp_max
        e_temp_unit
        e_consi_lang
        e_adress_type
        e_invoice_name
        o_bl_number
        o_order_date
        o_delivery_instructions
        o_delivery_remarks
        o_delivery_date
        o_delivery_orderdate_earlier
        o_delivery_orderdate_later
        o_delivery_time_earlier
        o_delivery_time_later
        o_delivery_time
        o_point_relais
        o_order
        o_removal_date
        o_removal_instruction
        o_removal_date_start
        o_removal_date_end
        o_removal_time_ealier
        o_removal_time_later
        o_time_wish_start
        o_time_wish_end
        o_flag
        o_receipt_number
        o_product_code
        o_road_code
        o_carrier_info
        o_action_code
        o_nbr_days_max
        c_carrier_id
        c_site_carrier
        c_site_product
        c_site_trafic
        flux {
          steps(order_by: { order: asc }) {
            steps_partners(order_by: { order: asc }) {
              order
              required
              open_days
              delay
              partner {
                id
                city
                name
                contact_mail1
                contact_mail2
                contact_name1
                contact_tel1
                contact_tel2
                country
                zip
                address1
                address2
                image
                partner_type {
                  name
                }
              }
            }
            order
          }
          client
          steps_aggregate {
            aggregate {
              count
            }
          }
        }
        bl_steps(order_by: { order: asc }) {
          order
          id
          is_planned
          bl_steps_partners(order_by: { order: asc }) {
            order
            partner {
              id
              city
              name
              image
              partner_type {
                name
              }
            }
          }
          under_bl_steps {
            carrier_tracking_url
            etat
            arrival_date
            arrival_time
            departure_date
            departure_time
            load_date
            load_time
            etat_livraison
            date_etat
            prepare_date
            prepare_time
            print_date
            print_time
            request_date
            request_time
            ship_date
            ship_time
            time_etat
            type
          }
        }
      }
    }
  `,
  bl: () => gql`
    query getBl($id: Int) {
      bl(where: { id: { _eq: $id } }) {
        bl_steps(order_by: { order: asc }) {
          order
          id
          is_planned
          bl_steps_partners(order_by: { order: asc }) {
            order
            partner {
              id
              city
              name
              contact_mail1
              contact_mail2
              contact_name1
              contact_tel1
              contact_tel2
              country
              zip
              address1
              address2
              image
              partner_type {
                name
              }
            }
          }
          under_bl_steps {
            id
            etat
            carrier_tracking_url
            arrival_date
            arrival_time
            departure_date
            departure_time
            load_date
            load_time
            etat_livraison
            date_etat
            prepare_date
            prepare_time
            print_date
            print_time
            request_date
            request_time
            ship_date
            ship_time
            time_etat
            type
          }
        }
        o_bl_number
        s_shipper_code_5
        s_shipper_code_10
        s_rem_code
        s_shipper_name
        s_shipper_code
        s_shiper_addr1
        s_shipper_addr2
        s_shipper_addr3
        s_shipper_zip
        s_shipper_city
        s_shipper_country_code
        s_shipper_country_name
        s_shipper_contact_name
        s_shipper_tel
        s_shipper_area
        s_shipper_mail
        s_removal_place_name
        s_removal_place_code
        s_removal_place_addr1
        s_removal_place_addr2
        s_removal_place_addr3
        s_removal_place_zip
        s_removal_place_city
        s_removal_place_country_code
        s_removal_place_country_name
        s_removal_place_contact_name
        s_removal_place_contact_tel
        s_removal_place_mail
        s_removal_flag
        r_consignee_code
        r_consignee_name
        r_consignee_addr1
        r_consignee_addr2
        r_consignee_addr3
        r_consignee_addr4
        r_consignee_zip
        r_consignee_city
        r_consignee_contact_name
        r_consignee_tel
        r_consignee_ref
        r_consignee_mail
        r_consignee_tel2
        r_consignee_insee_country
        r_consignee_country_name
        r_consignee_type
        e_total_packages
        e_package_number
        e_pallett_number
        e_consigned_pallett_number
        e_weight
        e_nature
        e_tax_units
        e_total_packages_nb
        e_url_BL
        e_UM_number
        e_dangerous_goods
        e_lenght
        e_Volume
        e_linear_floor
        e_value
        e_counter_refund
        e_commodity_value
        e_customer_account
        e_preparator_code
        e_Incoterm
        e_area
        e_transport_amount
        e_width
        e_iata_code
        e_contract_number
        e_nb_palet1
        e_typ_palet1
        e_nb_palet2
        e_typ_palet2
        e_nb_palet3
        e_typ_palet3
        e_height
        e_vol_alco
        e_vol_alco_pur
        e_ref_alco_doc
        e_cat_prod
        e_temp_min
        e_temp_max
        e_temp_unit
        e_consi_lang
        e_adress_type
        e_invoice_name
        o_bl_number
        o_declare_value_currency
        o_reimburs_currency
        o_commodity_value_currency
        o_order_date
        o_delivery_instructions
        o_delivery_remarks
        o_delivery_date
        o_delivery_orderdate_earlier
        o_delivery_orderdate_later
        o_delivery_time_earlier
        o_delivery_time_later
        o_delivery_time
        o_point_relais
        o_order
        o_removal_date
        o_removal_instruction
        o_removal_date_start
        o_removal_date_end
        o_removal_time_ealier
        o_removal_time_later
        o_time_wish_start
        o_time_wish_end
        o_flag
        o_receipt_number
        o_product_code
        o_road_code
        o_carrier_info
        o_action_code
        o_nbr_days_max
        c_carrier_id
        c_site_carrier
        c_site_product
        c_site_trafic
        articles {
          do_ref_code
          do_ref_lib
          do_ref_quant
          do_ref_um
          do_ean13
          id
        }
        flux {
          steps(order_by: { order: asc }) {
            order
            steps_partners(order_by: { order: asc }) {
              open_days
              delay
              required
              order
              partner {
                id
                city
                name
                contact_mail1
                contact_mail2
                contact_name1
                contact_tel1
                contact_tel2
                country
                zip
                address1
                address2
                image
                partner_type {
                  name
                }
              }
            }
          }
          steps_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  `,
  export: () => gql`
    query exportOrders($filters: FiltersInput, $sorter: SorterInput) {
      exportOrders(filters: $filters, sorter: $sorter) {
        data
      }
    }
  `,
  searchForClient: gql`
    query searchForBlClients($value: String!) {
      client_orders(
        where: {
          data_type: { _eq: "remitter" }
          client: { name: { _ilike: $value } }
        }
        offset: 0
        limit: 10
      ) {
        id
        client {
          id
          name
        }
      }
      client_orders_aggregate(
        where: {
          data_type: { _eq: "remitter" }
          client: { name: { _ilike: $value } }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  getFileType: gql`
    query getFileType {
      file_type {
        id
        name
      }
    }
  `,
  getNumberFileType: gql`
    query getFilesRemitter($remitter_id: Int!) {
      sealogis_remitters(where: { id: { _eq: $remitter_id } }) {
        id
        name
        stock: files_remitters_aggregate(
          where: { archived: { _eq: false }, file_type_id: { _eq: 1 } }
        ) {
          aggregate {
            count: count
          }
        }
        arrivage: files_remitters_aggregate(
          where: { archived: { _eq: false }, file_type_id: { _eq: 2 } }
        ) {
          aggregate {
            count: count
          }
        }
        kpi: files_remitters_aggregate(
          where: { archived: { _eq: false }, file_type_id: { _eq: 3 } }
        ) {
          aggregate {
            count: count
          }
        }
        pricelist: files_remitters_aggregate(
          where: { archived: { _eq: false }, file_type_id: { _eq: 4 } }
        ) {
          aggregate {
            count: count
          }
        }
      }
    }
  `,
  getFilesRemitterByTypeId: gql`
    query getFilesRemitterByTypeId($file_type_id: Int!, $remitter_id: Int!) {
      files_remitter(
        where: {
          archived: { _eq: false }
          file_type_id: { _eq: $file_type_id }
          remitter_id: { _eq: $remitter_id }
        }
      ) {
        id
        name
        location
        created_at
        file_type_id
        remitter_id
      }
    }
  `,
  getFilesRemitterByOrderId: gql`
    query getFilesRemitterByTypeId($order_id: Int!, $remitter_id: Int!) {
      files_remitter(
        where: {
          archived: { _eq: false }
          order_id: { _eq: $order_id }
          remitter_id: { _eq: $remitter_id }
        }
      ) {
        id
        name
        location
        created_at
        order_id
        remitter_id
      }
    }
  `,
  searchForClients: gql`
    query searchForClients($value: String!) {
      clients(
        where: {
          name: { _ilike: $value }
          client_orders: { data_type: { _eq: "remitter" } }
        }
        offset: 0
        limit: 10
        distinct_on: name
      ) {
        id
        name
      }
      clients_aggregate(
        where: {
          name: { _ilike: $value }
          client_orders: { data_type: { _eq: "remitter" } }
        }
        distinct_on: name
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  searchForOrders: gql`
    query searchForOrders($query: String!, $show_on_dashboard: Boolean) {
      orders(
        where: {
          delivery_num: { _ilike: $query }
          show_on_dashboard: { _eq: $show_on_dashboard }
        }
        offset: 0
        limit: 10
        distinct_on: delivery_num
      ) {
        id
        bl_num
        delivery_num
        created_at
      }
    }
  `,
};
