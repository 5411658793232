import { createSelector } from "reselect";

const WRITE_ALLOWED_ROLES = ["ADMIN", "USERWRITE"];

export const selectCurrentUserAdminFile = createSelector(
  (state) => state.auth?.current_user,
  (current_user) => {
    return {
        cgu: current_user?.cgu,
        privacy_policy: current_user?.privacy_policy
    };
  },
);

export const canUserWrite = createSelector(
  (state) => state.auth.current_user,
  (current_user) => {
    return WRITE_ALLOWED_ROLES.includes(current_user?.role);
  },
);

export const currentUser = createSelector(
  (state) => state.auth.current_user,
  (user) => user,
);
