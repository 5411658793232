import React from "react";
import { Can } from "../../../utils/casl/v2/can";

export const CanWithChildren = ({ action, subject, children }) => {
  return (
    <Can I={action} an={subject}>
      {children}
    </Can>
  );
};
