import React from "react";
import { Upload } from "antd";

const FileUpload = ({
  accept,
  customDownloadFunction,
  fileList = [],
  children,
}) => {
  const beforeUpload = (file) => {
    if (customDownloadFunction) {
      customDownloadFunction(file);
      return false;
    }
    return true;
  };

  return (
    <Upload accept={accept} beforeUpload={beforeUpload} fileList={fileList}>
      {children}
    </Upload>
  );
};

export default FileUpload;
