import { encode } from "base-64";
import { methods } from "../utils";
import {
  authenticatedMutation,
  authenticatedQuery,
  authenticatedService,
  service,
} from "./middleware";
import authMutations from "./GraphQLRequest/authMutations";
import UserQueries from "./GraphQLRequest/userQueries";

const { POST, GET } = methods;
const routes = {
  user: process.env.REACT_APP_BASE_API_URL + "/user/",
  login: process.env.REACT_APP_AUTH_API_URL + "/auth/authorize",
  getToken: process.env.REACT_APP_AUTH_API_URL + "/auth/token",
  changePassword: process.env.REACT_APP_AUTH_API_URL + "/auth/change-password",
  getSignedurl: `${process.env.REACT_APP_STORAGE_URL}/codex`,
};

const services = {
  login: ({ username, password }) =>
    service(
      GET,
      routes.login,
      {},
      {
        prompt: "none",
        response_type: "code",
        redirect_uri: process.env.REACT_APP_AUTH_API_URL,
        client_id: "SEALOGIS_BACKEND",
      },
      {
        Authorization: encode(username + ":" + password),
      },
    ),
  getToken: ({ username, password, code }) =>
    service(
      POST,
      routes.getToken,
      {
        grant_type: "authorization_code",
        code: code,
        redirect_uri: window.location.origin,
      },
      {},
      {
        Authorization: encode(username + ":" + password),
      },
    ),
  refreshToken: ({ authorization, refresh_token }) =>
    service(
      POST,
      routes.getToken,
      {
        grant_type: "refresh_token",
        refresh_token: refresh_token,
        redirect_uri: window.location.origin,
      },
      {},
      {
        Authorization: authorization,
      },
    ),
  getUser: ({ email, bearer }) =>
    authenticatedQuery(
      UserQueries.userByEmail(),
      { Authorization: bearer },
      { email: email },
    ),
  getCurrentUser: ({ id }) =>
    authenticatedQuery(UserQueries.userById(), {}, { id }),
  updateAdminFile: (values) =>
    authenticatedMutation(authMutations.updateAdminFile(), {}, values),
  changePassword: ({ pwd, id }) =>
    authenticatedService(POST, routes.changePassword, { pwd, id }),
  getSignedUrl: (location) =>
    authenticatedService(GET, routes.getSignedurl, {}, { location }),
};

export default services;
