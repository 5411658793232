import { createReducer } from "reduxsauce";

import { types } from "./actions";

const initialState = {
  cgu: {
    id: null,
    filename: null,
  },
  privacy_policy: {
    id: null,
    filename: null,
  },
  remitter: "",
};

const adminFileSuccess = (state = initialState, actions) => {
  const { data } = actions;
  let cgu = data.find((file) => file?.cgu) || null;
  let privacy_policy = data.find((file) => file.privacy_policy) || null;
  return {
    ...state,
    cgu: {
      id: cgu?.id,
      filename: cgu?.cgu,
    },
    privacy_policy: {
      id: privacy_policy?.id,
      filename: privacy_policy?.privacy_policy,
    },
  };
};

const adminFileFailure = (state = initialState) => {
  return { ...state };
};

const setSelectedRemitter = (state = initialState, action) => {
  const { remitter } = action;
  return { ...state, remitter };
};

export default createReducer(initialState, {
  [types.ADMIN_FILE_SUCCESS]: adminFileSuccess,
  [types.ADMIN_FILE_FAILURE]: adminFileFailure,
  [types.SET_SELECTED_REMITTER]: setSelectedRemitter,
});
