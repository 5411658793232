import { Button, Modal, Space, Table } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BlActions } from "../../../redux/bl";
import { BasicComponents } from "../basic";

const EdiFileModal = ({ visible, strings, data, onClose = () => {} }) => {
  const [{ loadingId, loadingEvent }, setIsLoading] = useState({});
  const dispatch = useDispatch();
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const handleResize = () => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFile = (name, oldType, id) => {
    if (name && oldType) {
      setIsLoading({ loadingId: id, loadingEvent: "download" });
      dispatch(BlActions.getEdiFile(oldType, name, () => setIsLoading({})));
    }
  };

  const columns = [
    {
      title: strings("file_name"),
      dataIndex: "file_name",
      align: "center",
    },
    {
      title: strings("create_date"),
      dataIndex: "created_at",
      align: "center",
    },
    {
      title: strings("type"),
      dataIndex: "type",
      align: "center",
      render: (type) => strings(type),
    },
    {
      title: strings("action"),
      fixed: "right",
      width: "100px",
      align: "center",
      render: ({ file_name, oldType, id, disabled }) => (
        <Space>
          <BasicComponents.Tooltip
            color={"#000"}
            title={disabled && strings("unavailable_edi_file")}
          >
            <Button
              id={`download-button-${id}`}
              icon={<DownloadOutlined />}
              shape={"circle"}
              onClick={() => handleFile(file_name, oldType, id)}
              disabled={
                disabled ||
                (loadingEvent &&
                  !(loadingId === id && loadingEvent === "download"))
              }
              loading={loadingId === id && loadingEvent === "download"}
            />
          </BasicComponents.Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Modal
      title={strings("edi_file_order")}
      open={visible}
      onOk={onClose}
      onCancel={onClose}
      width={1000}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Table
        sticky
        scroll={{ y: dimensions.height - 400, x: "auto" }}
        bordered
        scr
        dataSource={data || []}
        columns={columns}
        pagination={false}
      />
    </Modal>
  );
};

export default EdiFileModal;
