import { call, put, select, takeLatest } from "redux-saga/effects";
import { types } from "./actions";
import { LoaderActions } from "../loader";
import { getTranslate } from "react-localize-redux";
import { showError } from "../../utils/notifications-helper";
import { StatusActions } from "./index";
import { StatusService } from "../../services";

function* orderStatesRequest() {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, response] = yield call(StatusService.orderStates);
  if (error) showError("status_failed", strings);
  else if (response)
    yield put(StatusActions.orderStatesSuccess(response.order_states));
  yield put(LoaderActions.loaded());
}

function* deliveryStatusRequest() {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, response] = yield call(StatusService.deliveryStatus);
  if (error) showError("status_failed", strings);
  else if (response) yield put(StatusActions.deliveryStatusSuccess(response));
  yield put(LoaderActions.loaded());
}

function* delayReasonRequest() {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, response] = yield call(StatusService.delayReasons);
  if (error) showError("status_failed", strings);
  else if (response) yield put(StatusActions.delayReasonSuccess(response));
  yield put(LoaderActions.loaded());
}

function* disputeReasonRequest() {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, response] = yield call(StatusService.disputeReasons);
  if (error) showError("status_failed", strings);
  else if (response) yield put(StatusActions.disputeReasonSuccess(response));
  yield put(LoaderActions.loaded());
}

function* responsibilityRequest() {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, response] = yield call(StatusService.responsibilities);
  if (error) showError("status_failed", strings);
  else if (response) yield put(StatusActions.responsibilitySuccess(response));
  yield put(LoaderActions.loaded());
}
function* warehouseStatusRequest() {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, response] = yield call(StatusService.warehouseStatus);
  if (error) showError("status_failed", strings);
  else if (response) yield put(StatusActions.warehouseStatusSuccess(response));
  yield put(LoaderActions.loaded());
}

function* skRelatedStatusRequest() {
  const [error, response] = yield call(StatusService.skRelatedStatusRequest);
  if (error) {
    const locale = yield select((state) => state.locale);
    const strings = getTranslate(locale);
    showError("status_failed", strings);
  } else if (response) {
    yield put(StatusActions.skRelatedStatusSuccess(response));
  }
}

const sagas = [
  takeLatest(types.ORDER_STATES_REQUEST, orderStatesRequest),
  takeLatest(types.DELIVERY_STATUS_REQUEST, deliveryStatusRequest),
  takeLatest(types.DELAY_REASON_REQUEST, delayReasonRequest),
  takeLatest(types.DISPUTE_REASON_REQUEST, disputeReasonRequest),
  takeLatest(types.RESPONSIBILITY_REQUEST, responsibilityRequest),
  takeLatest(types.WAREHOUSE_STATUS_REQUEST, warehouseStatusRequest),
  takeLatest(types.SK_RELATED_STATUS_REQUEST, skRelatedStatusRequest),
];

export default sagas;
