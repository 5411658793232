import { call, put, select } from "redux-saga/effects";
import { get } from "lodash";
import { default as AuthActions } from "../redux/auth/actions";
import { extractGraphQLCode, fetch, mutation, query } from "../utils";
import { AuthenticationService } from "./index";

/**
 * use it to fire the refresh service with the current auth.authorization and auth.refresh_token
 * @returns {Generator<*, string[]|*[], ?>}
 */
function* refresh() {
  const { refresh_token, authorization } = yield select((state) => state.auth);
  const [tokenError, tokenResponse] = yield call(
    AuthenticationService.refreshToken,
    {
      authorization,
      refresh_token,
    },
  );
  if (tokenResponse && tokenResponse.data) {
    const {
      access_token: token,
      token_type,
      refresh_token,
    } = tokenResponse.data;
    yield put(
      AuthActions.loginSuccess(token_type, token, refresh_token, authorization),
    );
    return [null, `${token_type} ${token}`];
  } else return [tokenError, null];
}

/**
 * Retry a GraphQL service, can be both query or mutation. The refresh method is used before trying to re-call the service.
 * @param service the current service to retry
 * @returns {Generator<*, *[], ?>}
 */
function* graphQLRetry(service) {
  let { error, queryString, headers, isMutation, variables } = service;
  const { attempts } = yield select((state) => state.auth);
  if (attempts === 1) {
    yield put(AuthActions.logout());
    return [error];
  }

  const [tokenError, token] = yield call(refresh);
  if (tokenError) {
    yield put(AuthActions.logout());
    return [tokenError];
  }

  headers = { ...headers, Authorization: `${token}` };
  const [finalError, finalResponse] = yield call(
    isMutation ? mutation : query,
    {
      queryString,
      headers,
      variables,
    },
  );

  return [finalError, finalResponse];
}

/**
 * GraphQL error manager. fire other middleware if necessary.
 * exemple: if a GraphQLError with "access-denied" is catch, then a Retry is fired.
 */
function* graphQLErrorHandling(service) {
  let { error, response } = service;
  const code = extractGraphQLCode(error);
  switch (code) {
    case "access-denied":
      // eslint-disable-next-line no-console
      return yield call(graphQLRetry, service);

    default:
      return [error, response];
  }
}

function* serviceRetry({ error, service }) {
  let { headers } = service;
  const { attempts } = yield select((state) => state.auth);
  if (attempts === 1) {
    yield put(AuthActions.logout());
    return [error];
  }

  const [tokenError, token] = yield call(refresh);
  if (tokenError) {
    yield put(AuthActions.logout());
    return [tokenError];
  }

  headers = { ...headers, Authorization: `${token}` };
  const [finalError, finalResponse] = yield call(fetch, {
    ...service,
    headers,
  });

  return [finalError, finalResponse];
}

/**
 *  Start a graphQL mutation with the Authorization Header
 * @param queryString
 * @param headers
 * @returns {Generator<*, *[]|*, Generator<*|CallEffect, *|[*, *], ?>>}
 */
export function* authenticatedMutation(queryString, headers = {}, variables) {
  const { token } = yield select((state) => state.auth);
  headers = { ...headers, Authorization: `Bearer ${token}` };
  let [error, response] = yield call(mutation, {
    queryString,
    headers,
    variables,
  });
  const isMutation = true;
  if (error)
    return yield call(graphQLErrorHandling, {
      error,
      response,
      queryString,
      headers,
      isMutation,
      variables,
    });
  else return [error, response];
}

/**
 *  Start a graphQL query with the Authorization Header
 * @param queryString
 * @param headers
 * @param variables
 * @returns {Generator<*, *[]|*, Generator<*|CallEffect, *|[*, *], ?>>}
 */
export function* authenticatedQuery(queryString, headers = {}, variables) {
  const { token } = yield select((state) => state.auth);
  headers = { ...headers, Authorization: `Bearer ${token}` };

  let [error, response] = yield call(query, {
    queryString,
    headers,
    variables,
  });
  const isMutation = false;
  if (error)
    return yield call(graphQLErrorHandling, {
      error,
      response,
      queryString,
      headers,
      isMutation,
      variables,
    });
  else return [error, response];
}

/**
 * Middleware for REST API
 */

function* authorize(service) {
  const { token_type, token } = yield select((state) => state.auth);
  service.headers = {
    ...service.headers,
    Authorization: `${token_type} ${token}`,
  };
  let [error, response] = yield call(fetch, service);
  if (get(error, ["response", "status"], null) === 401)
    return yield call(serviceRetry, {
      error,
      response,
      service,
    });
  else return [error, response];
}

export function* authenticatedService(
  method,
  url,
  data = {},
  params = {},
  headers = {},
  responseType = "",
  uploadCallBack = () => {},
) {
  return yield call(authorize, {
    method,
    url,
    data,
    params,
    headers,
    responseType,
    uploadCallBack,
  });
}

export function* service(
  method,
  url,
  data = {},
  params = {},
  headers = {},
  responseType = "",
) {
  return yield call(fetch, {
    method,
    url,
    data,
    params,
    headers,
    responseType,
  });
}
