import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import AddCMDModal from "./AddCMDModal";
import { BlActions } from "../../../../redux/bl";

const AddCMD = ({ strings, className, selectedClient, id }) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (dispatch) {
      dispatch(BlActions.getFileTypeRequest());
    }
  }, [dispatch]);

  const onOpenModal = () => {
    setVisible(!visible);
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <div className={className}>
      <AddCMDModal
        selectedClient={selectedClient}
        onClose={handleClose}
        visible={visible}
        strings={strings}
      />
      <Button
        id={id}
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => onOpenModal()}
      >
        {strings("add_cmd_bcu")}
      </Button>
    </div>
  );
};

export default AddCMD;
