import React from "react";
import { Checkbox } from "antd";

export const CheckboxEdit = ({ value, onChange, ...props }) => {
  const handleOnChange = (e) => {
    if (onChange) onChange(e?.target?.checked);
  };

  return <Checkbox {...props} checked={value} onChange={handleOnChange} />;
};
