import React from "react";
import { DatePicker } from "antd";
import "moment/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";

import "./style.css";
import moment from "moment/moment";

const { RangePicker } = DatePicker;

const DateFilter = ({ strings, filters, onFilterChange, name, ...props }) => {
  const dateFormat = strings("date_format");
  const { firstDate, secondDate } = filters?.asked_shipping_date || {};
  const value =
    firstDate && secondDate
      ? [moment(firstDate, dateFormat), moment(secondDate, dateFormat)]
      : [];

  const handleDateChange = (e) => {
    const [firstDate, secondDate] = e || ["", ""];
    onFilterChange({
      [name]: {
        secondDate,
        firstDate,
      },
    });
  };

  return (
    /*<div className={"date_picker_wrapper"}>*/
    <RangePicker
      {...props}
      format={dateFormat}
      onChange={handleDateChange}
      placeholder={[strings("start_date"), strings("end_date")]}
      value={value}
      locale={locale}
    />
    /*</div>*/
  );
};

export default DateFilter;
