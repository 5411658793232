import { authenticatedQuery } from "./middleware";
import dataQueries from "./GraphQLRequest/dataQueries";

const services = {
  xcStatus: () => authenticatedQuery(dataQueries.xcStatus),
  typeFlux: () => authenticatedQuery(dataQueries.typeFlux),
  bcuData: () => authenticatedQuery(dataQueries.bcuData),
  partnerCarrierRequest: () =>
    authenticatedQuery(dataQueries.partnerCarrierRequest),
};

export default services;
