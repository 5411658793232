import { ExportXLS } from "../../../components";
import React, { useMemo } from "react";
import "./style.css";
import { Col, Row, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { BlActions, selectClients } from "../../../../redux/bl";
import { selectOrderStates } from "../../../../redux/status";
import ImportXLS from "./ImportXLS";
import SelectFilter from "./filters/SelectFilter";
import DateFilter from "./filters/DateFilter";
import InputFilter from "./filters/InputFilter";
import SwitchFilter from "./filters/SwitchFilter";
import InputDate from "./filters/InputDate";
import { SearchFilter } from "./filters/SearchFilter";
import RemitterSelectFilter from "./filters/RemitterSelectFilter";
import ImportGED from "./ImportGED";
import AddCMD from "./AddCMD";
import { CanWithChildren } from "../../../components/can";

const FluxFilter = ({ strings, filters, sorter, setFilters, pagination }) => {
  const dispatch = useDispatch();
  const clients = useSelector(selectClients);

  const handleFilterChange = (toAdd, removeAll) => {
    const old = removeAll ? {} : filters;
    setFilters({ ...old, ...toAdd });
    dispatch(BlActions.setPagination({ ...pagination, current: 1 }));
  };

  const components = {
    selectRemitter: (props) => (
      <RemitterSelectFilter {...props} id={props.id} />
    ),
    select: (props) => <SelectFilter {...props} id={props.id} />,
    date: (props) => <DateFilter {...props} id={props.id} />,
    input: (props) => <InputFilter {...props} id={props.id} />,
    switch: (props) => <SwitchFilter {...props} id={props.id} />,
    simpleDate: (props) => <InputDate {...props} />,
    search: (props) => <SearchFilter {...props} id={props.id} />,
  };

  const selectedClient = useMemo(() => {
    return clients?.find((client) => client?.code === filters?.client);
  }, [filters, clients]);

  const firstRenderRow = [
    {
      id: "bl_number",
      type: "input",
      placeHolder: "search_bl",
      name: "bl_number",
      style: { width: "171px", marginRight: "10px" },
    },
    {
      id: "state",
      placeHolder: "state",
      name: "state",
      type: "select",
      schema: { value: "code", name: "name" },
      selector: selectOrderStates,
      selectStyle: { width: "155px", marginRight: "10px" },
      multiple: true,
    },
    /*{
      id: "delivered_client",
      type: "search",
      placeHolder: strings("delivered_client"),
      name: "delivered_client",
      /!*onSearch: onSearch,*!/
      searchAction: BlActions.searchForClients,
      selector: selectOrderClients,
      selectStyle: { width: "145px", marginRight: "10px" },
      schema: { value: "id", name: "name" },
      /!*selector: selectClients,
      selectStyle: { width: "145px" },*!/
    },*/
    {
      id: "delivered",
      type: "switch",
      name: "delivered",
      placeHolder: "delivered",
      style: { margin: 0 },
    },
    {
      id: "edi_tracking_failure",
      type: "switch",
      name: "error",
      placeHolder: "edi_tracking_failure",
      style: { margin: 0 },
    },
  ];

  const secondRenderRow = [
    {
      id: "asked_shipping_date",
      type: "date",
      name: "asked_shipping_date",
      style: { width: "270px", marginRight: "10px" },
    },
    {
      id: "client_number",
      type: "input",
      placeHolder: "client_number",
      name: "br_number",
      style: { width: "171px" },
    },
    {
      id: "client_name_input",
      type: "input",
      placeHolder: "client_name",
      name: "client_name_input",
      style: { width: "200px" },
    },
  ];

  return (
    <Row justify={"space-between"}>
      <Col flex={1} className={"flex-column col-marg"}>
        {/*<Row>
          <Col style={{ alignItems: "center" }}>
            <div className="switch_wrapper">
              <span className={"filter_header"}>
                {`${strings("filters")}:`}{" "}
              </span>
            </div>
          </Col>
        </Row>*/}
        <Space size={8} wrap className={"margin-space"}>
          {firstRenderRow.map(({ type, ...item }) =>
            components[type]({
              key: item.id,
              ...item,
              strings,
              filters,
              onFilterChange: handleFilterChange,
            }),
          )}
        </Space>
        <Space size={8} wrap>
          {secondRenderRow.map(({ type, ...item }) =>
            components[type]({
              key: item.id,
              ...item,
              strings,
              filters,
              onFilterChange: handleFilterChange,
            }),
          )}
        </Space>
      </Col>
      <Col className={"flex-end-justify col-marg"}>
        <Space size={8} wrap className={"flex-end-align-justify"}>
          <CanWithChildren action={"display"} subject={"btn_add_order"}>
            <AddCMD
              id={"import-cmd"}
              strings={strings}
              className="import-csv"
            />
          </CanWithChildren>
          <ImportGED
            selectedClient={selectedClient}
            strings={strings}
            id={"import-ged"}
            className="import-csv"
            paginationOptions={{ filters, sorter, pagination }}
          />
          <CanWithChildren action={"display"} subject={"btn_import_csv"}>
            <ImportXLS
              id={"import-xls"}
              strings={strings}
              className="import-csv"
              paginationOptions={{ filters, sorter, pagination }}
            />
          </CanWithChildren>
          <ExportXLS strings={strings} filters={filters} sorter={sorter} />
        </Space>
      </Col>
    </Row>
  );
};

export default FluxFilter;
