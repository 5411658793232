import { createAction } from "../../utils";

export const types = {
  ORDER_STATES_REQUEST: "ORDER_STATES_REQUEST",
  ORDER_STATES_SUCCESS: "ORDER_STATES_SUCCESS",
  DELIVERY_STATUS_REQUEST: "DELIVERY_STATUS_REQUEST",
  DELIVERY_STATUS_SUCCESS: "DELIVERY_STATUS_SUCCESS",
  DISPUTE_REASON_REQUEST: "DISPUTE_REASON_REQUEST",
  DISPUTE_REASON_SUCCESS: "DISPUTE_REASON_SUCCESS",
  DELAY_REASON_REQUEST: "DELAY_REASON_REQUEST",
  DELAY_REASON_SUCCESS: "DELAY_REASON_SUCCESS",
  RESPONSIBILITY_REQUEST: "RESPONSIBILITY_REQUEST",
  RESPONSIBILITY_SUCCESS: "RESPONSIBILITY_SUCCESS",
  WAREHOUSE_STATUS_REQUEST: "WAREHOUSE_STATUS_REQUEST",
  WAREHOUSE_STATUS_SUCCESS: "WAREHOUSE_STATUS_SUCCESS",
  SK_RELATED_STATUS_REQUEST: "SK_RELATED_STATUS_REQUEST",
  SK_RELATED_STATUS_SUCCESS: "SK_RELATED_STATUS_SUCCESS",
};

const actions = {
  orderStatesRequest: () => createAction(types.ORDER_STATES_REQUEST),
  orderStatesSuccess: (values) =>
    createAction(types.ORDER_STATES_SUCCESS, { values }),
  deliveryStatusRequest: () => createAction(types.DELIVERY_STATUS_REQUEST),
  deliveryStatusSuccess: ({ delivery_states, delivery_status }) =>
    createAction(types.DELIVERY_STATUS_SUCCESS, {
      delivery_states,
      delivery_status,
    }),
  disputeReasonRequest: () => createAction(types.DISPUTE_REASON_REQUEST),
  disputeReasonSuccess: (values) =>
    createAction(types.DISPUTE_REASON_SUCCESS, values),
  delayReasonRequest: () => createAction(types.DELAY_REASON_REQUEST),
  delayReasonSuccess: (values) =>
    createAction(types.DELAY_REASON_SUCCESS, values),
  responsibilityRequest: () => createAction(types.RESPONSIBILITY_REQUEST),
  responsibilitySuccess: (values) =>
    createAction(types.RESPONSIBILITY_SUCCESS, values),
  warehouseStatusRequest: () => createAction(types.WAREHOUSE_STATUS_REQUEST),
  warehouseStatusSuccess: (values) =>
    createAction(types.WAREHOUSE_STATUS_SUCCESS, values),
  skRelatedStatusRequest: () => createAction(types.SK_RELATED_STATUS_REQUEST),
  skRelatedStatusSuccess: (status) =>
    createAction(types.SK_RELATED_STATUS_SUCCESS, { status }),
};

export default actions;
