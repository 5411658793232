import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FileSearchOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import FilesModal from "./FilesModal";
import { BlActions } from "../../../../redux/bl";
import { selectNumberFilesTypes } from "../../../../redux/bl/selector";
import { AbilityContext } from "../../../../utils/casl/v2/can";

const ImportGED = ({
  strings,
  className,
  paginationOptions,
  selectedClient,
  id,
  orderId,
}) => {
  const dispatch = useDispatch();
  const [type, setType] = useState(undefined);
  const [visible, setVisible] = useState(false);
  const numberFilesByType = useSelector(selectNumberFilesTypes);
  const canCtx = useContext(AbilityContext);

  useEffect(() => {
    if (dispatch) {
      dispatch(BlActions.getFileTypeRequest());
    }
  }, [dispatch]);

  useEffect(() => {
    if (dispatch && selectedClient) {
      dispatch(BlActions.getNumberFileTypeRequest(selectedClient.id));
    }
  }, [dispatch, selectedClient]);

  const onOpenModal = useCallback((fileType) => {
    setType(fileType);
    setVisible(true);
  }, []);

  const items = useMemo(() => {
    return [
      {
        label: (
          <Badge
            count={numberFilesByType.stock}
            offset={[-2, 1]}
            size={"small"}
          >
            <Button onClick={() => onOpenModal("STOCK")} style={{ width: 150 }}>
              {strings("stock_import")}
            </Button>
          </Badge>
        ),
        key: "0",
      },
      {
        label: (
          <Badge
            count={numberFilesByType.arrivage}
            offset={[-2, 1]}
            size={"small"}
          >
            <Button
              onClick={() => onOpenModal("ARRIVAL")}
              style={{ width: 150 }}
            >
              {strings("arrival_import")}
            </Button>
          </Badge>
        ),
        key: "1",
      },
      {
        label: (
          <Badge count={numberFilesByType.kpi} offset={[-2, 1]} size={"small"}>
            <Button onClick={() => onOpenModal("KPI")} style={{ width: 150 }}>
              {strings("kpi_import")}
            </Button>
          </Badge>
        ),
        key: "2",
      },
      {
        label: (
          <Badge
            count={numberFilesByType.pricelist}
            offset={[-2, 1]}
            size={"small"}
          >
            <Button
              onClick={() => onOpenModal("PRICE LIST")}
              style={{ width: 150 }}
            >
              {strings("pricelist_import")}
            </Button>
          </Badge>
        ),
        key: "3",
        customFilterKey: "btn_add_ged_price_list",
      },
    ]
      .filter((el) => {
        if (!el.customFilterKey) return true;
        return canCtx.can("display", el.customFilterKey);
      })
      .map((el) => {
        const { customFilterKey, ...rest } = el;
        return rest;
      });
  }, [strings, onOpenModal, numberFilesByType, canCtx]);

  const handleClose = () => {
    setType(undefined);
    setVisible(false);
  };

  return (
    <div className={className}>
      <FilesModal
        selectedClient={selectedClient}
        onClose={handleClose}
        type={type}
        orderId={orderId}
        visible={visible}
        strings={strings}
        paginationOptions={paginationOptions}
      />
      {orderId ? (
        <Tooltip title={strings("import_ged_order_tooltip")}>
          <Button
            id={"ged-order"}
            icon={<FileSearchOutlined className={"audit-icon"} />}
            shape="circle"
            style={{ borderColor: "#0480FC" }}
            onClick={() => {
              onOpenModal();
            }}
          />
        </Tooltip>
      ) : (
        <Dropdown trigger={"click"} menu={{ items }}>
          <Button id={id} icon={<FileSearchOutlined />} type="primary">
            {strings("ged")}
          </Button>
        </Dropdown>
      )}
    </div>
  );
};

export default ImportGED;
