import { ArrowRightOutlined } from "@ant-design/icons";
import React from "react";

import ConfirmButton from "./ConfirmButton";

const ModifyButton = ({ loading, modify, onCancel, tooltipTitle }) => {
  return (
    <ConfirmButton
      loading={loading}
      ghost
      shape={"circle"}
      type={"primary"}
      icon={<ArrowRightOutlined />}
      onClick={modify}
      onCancel={onCancel}
      tooltipTitle={tooltipTitle}
    />
  );
};

export default ModifyButton;
