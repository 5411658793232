import "./style.scss";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import TextEdit from "./TextEdit";
import DateEdit from "./DateEdit";
import { Button, Form, Space } from "antd";
import React, { useEffect, useState } from "react";
import SelectEdit from "./SelectEdit";
import { useDispatch } from "react-redux";
import { BlActions } from "../../../../redux/bl";
import { TimePickerEdit } from "./TimePicker";
import { NumberEdit } from "./NumberEdit";
import { CheckboxEdit } from "./CheckboxEdit";
import SelectBooleanEdit from "./SelectBooleanEdit";

export const editWrapperTypes = {
  text: ({
    hideOptions,
    enableFields,
    format,
    optionsStyle,
    showSearch,
    ...props
  }) => <TextEdit allowClear {...props} />,
  number: ({
    hideOptions,
    enableFields,
    format,
    optionsStyle,
    showSearch,
    strings,
    ...props
  }) => <NumberEdit controls={false} {...props} />,
  date: ({ enableFields, hideOptions, optionsStyle, showSearch, ...props }) => (
    <DateEdit {...props} />
  ),
  time: ({ enableFields, hideOptions, optionsStyle, showSearch, ...props }) => (
    <TimePickerEdit {...props} />
  ),
  select: ({ format, ...props }) => <SelectEdit {...props} />,
  checkbox: ({
    enableFields,
    hideOptions,
    format,
    optionsStyle,
    showSearch,
    ...props
  }) => <CheckboxEdit {...props} />,
  "select-boolean": ({ format, showSearch, ...props }) => (
    <SelectBooleanEdit {...props} />
  ),
};

const EditWrapper = ({
  type,
  value,
  strings,
  disabled,
  selector,
  optionsStyle,
  showSearch,
  updateInfos,
  hideOptions,
  allowClear,
  enableFields,
  onCancel = () => {},
  required = true,
  format,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldValue("data", value);
    //eslint-disable-next-line
  }, [value]);

  const requestCallback = () => {
    setLoading(false);
    onCancel();
  };

  const handleFinish = async () => {
    const data = await form.validateFields();
    if (dispatch) {
      const { key, id } = updateInfos;
      setLoading(true);
      const updateObj = {
        id,
        [key]: data.data,
      };
      if (format?.length) {
        updateObj.custom_date_format = format;
      }
      dispatch(
        BlActions.blUpdate(updateObj, {
          onSuccess: requestCallback,
          onFailure: requestCallback,
        }),
      );
    }
  };

  return (
    <div className="editing-row-container">
      <Form form={form} onFinish={handleFinish}>
        <Form.Item
          name="data"
          style={{ marginBottom: 10 }}
          rules={[{ required: required, message: strings("no_data") }]}
        >
          {editWrapperTypes[type]({
            selector,
            hideOptions,
            allowClear,
            enableFields,
            format,
            optionsStyle,
            strings,
            disabled,
            showSearch,
          })}
        </Form.Item>
        <Form.Item style={{ marginBottom: 5 }}>
          <div className={"icon-editing-container"}>
            <Space>
              <Button
                icon={<CloseOutlined />}
                shape={"circle"}
                danger
                onClick={onCancel}
              />
              <Button
                disabled={disabled}
                loading={loading}
                htmlType="submit"
                shape={"circle"}
                style={{
                  color: disabled ? "darkgrey" : "green",
                  borderColor: disabled ? "darkgrey" : "green",
                }}
                icon={<CheckOutlined />}
              />
            </Space>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditWrapper;
