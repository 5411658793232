import { encode } from "base-64";
import { push } from "connected-react-router";
import { call, put, select, takeLatest, all } from "redux-saga/effects";

import { AppService, AuthenticationService } from "../../services";
import { CommonActions } from "../common";
import { LoaderActions } from "../loader";
import { default as AuthActions, types } from "./actions";
import { showError, showSuccess } from "../../utils/notifications-helper";
import { getTranslate } from "react-localize-redux";
import { AppActions } from "../app";
import { get } from "lodash";

function* login({ username, password }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);

  const [error, response] = yield call(AuthenticationService.login, {
    username,
    password,
  });
  if (response && response.request.responseURL) {
    const url = response.request.responseURL;
    const splt = url.split("=");
    const code = splt[1];
    //eslint-disable-next-line
    const [_, tokenResponse] = yield call(AuthenticationService.getToken, {
      username,
      password,
      code,
    });

    if (tokenResponse && tokenResponse.data.access_token) {
      const token = tokenResponse.data.access_token;
      const token_type = tokenResponse.data.token_type;
      const refresh_token = tokenResponse.data.refresh_token;

      const authorization = encode(username + ":" + password);

      yield put(
        AuthActions.loginSuccess(
          token_type,
          token,
          refresh_token,
          authorization,
        ),
      );

      const responses = yield all([
        call(AppService.adminFile),
        call(AuthenticationService.getUser, {
          email: username,
          bearer: token,
        }),
      ]);
      const responseAF = get(responses, [0, 1, "admin_file"], null);
      const responseUser = get(responses, [1, 1, "users", 0], null);
      if (responses.find((response) => !!response[0])) {
        yield put(AuthActions.loginFailure());
        showError("authentication_failed", strings);
        yield put(LoaderActions.loaded());
        return;
      }
      if (responseAF) {
        yield put(AppActions.adminFileSuccess(responseAF));
      }
      if (responseUser) {
        yield put(AuthActions.currentUserSuccess(responseUser));
        yield put(AuthActions.renewSuccess(responseUser?.renew_pwd));
      }
      yield put(
        AuthActions.loginSuccess(
          token_type,
          token,
          refresh_token,
          authorization,
        ),
      );
    }
  }

  yield put(LoaderActions.loaded());
  if (error) {
    if (error.response.status === 403) {
      // Saga de compte désactivé
      yield put(AuthActions.loginUserDesactivated());
      yield put(LoaderActions.loaded());
      return;
    } else {
      yield put(AuthActions.loginFailure());
      showError("authentication_failed", strings);
      return;
    }
  }
  yield put(push("/home"));
}

/**
 * Warning: This code is not used. The current refresh action is in middleware
 * @returns {Generator<*, string[]|*[], ?>}
 */
function* refresh() {
  const { refresh_token, authorization } = yield select((state) => state.auth);
  const [tokenError, tokenResponse] = yield call(
    AuthenticationService.refreshToken,
    {
      authorization,
      refresh_token,
    },
  );
  if (tokenResponse && tokenResponse.data.access_token) {
    const token = tokenResponse.data.access_token;
    const token_type = tokenResponse.data.token_type;
    const refresh_token = tokenResponse.data.refresh_token;
    yield put(
      AuthActions.loginSuccess(token_type, token, refresh_token, authorization),
    );
    return [null, `${token_type} ${token}`];
  } else return [tokenError, null];
}

function* logout() {
  yield put(CommonActions.resetReducers());
  yield put(push("/"));
}

function* changePassword({ password }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const { current_user } = yield select((state) => state.auth);

  const [error] = yield call(AuthenticationService.changePassword, {
    pwd: password,
    id: current_user.id,
    renew_pwd: false,
  });
  if (error) {
    if (error) showError("password_failed", strings);
    return;
  }
  showSuccess("password_success", strings);
  yield put(AuthActions.changePasswordSuccess());
  yield put(LoaderActions.loaded());
}

function* validateModalFirstConnectionRequest({
  password,
  cgu,
  privacy_policy,
}) {
  yield put(LoaderActions.loading());
  const strings = yield select((state) => getTranslate(state.locale));
  const { current_user } = yield select((state) => state.auth);
  const calls = [];
  if (password) {
    let base64data = encode(password);
    calls.push({
      query: AuthenticationService.changePassword,
      data: { pwd: base64data, id: current_user.id },
    });
  }
  if (cgu || privacy_policy) {
    calls.push({
      query: AuthenticationService.updateAdminFile,
      data: { id: current_user.id, cgu, privacy_policy, renew_pwd: false },
    });
  }
  const responses = yield all(
    calls.map(({ query, data }) => call(query, data)),
  );
  if (responses.find((response) => !!response[0])) {
    showError(password ? "password_failed" : "admin_file_failed", strings);
    yield put(LoaderActions.loaded());
    return;
  }

  const responseCP = get(responses, [password ? 0 : 1, 1, "data"], null);
  const responseUser = get(
    responses,
    [password ? 1 : 0, 1, "update_users_by_pk"],
    null,
  );

  if (responseCP) {
    yield put(AuthActions.changePasswordSuccess());
  }
  if (responseUser) {
    yield put(AuthActions.currentUserSuccess(responseUser));
  }
  showSuccess(password ? "password_success" : "admin_file_success", strings);
  yield put(LoaderActions.loaded());
}

function* getCurrentUserRequest() {
  const strings = yield select((state) => getTranslate(state.locale));
  const { current_user } = yield select((state) => state.auth);
  const [error, response] = yield call(AuthenticationService.getCurrentUser, {
    id: current_user.id,
  });
  if (error) {
    if (error) showError("get_current_user_failed", strings);
    return;
  }
  if (response) {
    const user = response.users[0];
    yield put(AuthActions.currentUserSuccess(user));
    yield put(AuthActions.renewSuccess(user?.renew_pwd));
  }
}

function* getAdminFilesSignedUrl({ location, callbacks }) {
  const [error, response] = yield call(
    AuthenticationService.getSignedUrl,
    location,
  );

  if (error) {
    const strings = yield select((state) => getTranslate(state.locale));
    showError("signed_url_error", strings);
  } else if (response) {
    const { onSuccess } = callbacks;
    onSuccess(response.data);
  }
}

const sagas = [
  takeLatest(types.LOGIN_REQUEST, login),
  takeLatest(types.LOGIN_REFRESH, refresh),
  takeLatest(types.LOGOUT, logout),
  takeLatest(types.CHANGE_PASSWORD_REQUEST, changePassword),
  takeLatest(
    types.VALIDATE_MODAL_FIRST_CO_REQUEST,
    validateModalFirstConnectionRequest,
  ),
  takeLatest(types.GET_CURRENT_USER_REQUEST, getCurrentUserRequest),
  takeLatest(types.GET_ADMIN_FILES_SIGNED_URL, getAdminFilesSignedUrl),
];

export default sagas;
