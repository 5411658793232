import { createSelector } from "reselect";

const PalletTypeMapper = {
  1: "80x120",
  1.25: "100x120",
};

export const selectPalletsLoading = createSelector(
  (state) => state.pallets.listLoading,
  (loading) => loading,
);

export const selectArticleForModalTableClassic = createSelector(
  (state) => state.pallets.articles,
  (articles) => {
    return articles.map((article) => {
      if (!article.article.lost_packaging) {
        return {
          key: article.id,
          code: <p style={{ color: "red" }}>{article.article.internal_code}</p>,
        };
      }
      const articleUvcByPallet = article.article.uvc_by_palet || 1;
      let rawNbPallets = article.asked_quantity / articleUvcByPallet;
      let nbPallets =
        Math.round((article.asked_quantity / articleUvcByPallet) * 10) / 10;
      if (nbPallets === 0)
        nbPallets =
          Math.round((article.asked_quantity / articleUvcByPallet) * 100) / 100;
      const nbFullPallets = Math.floor(nbPallets);
      const nbUvcFullPallets = articleUvcByPallet * nbFullPallets;
      let equivalentPicking = Math.round((nbPallets - nbFullPallets) * 10) / 10;
      if (equivalentPicking === 0)
        equivalentPicking = Math.round((nbPallets - nbFullPallets) * 100) / 100;
      let equivalentValue =
        Math.round(
          nbPallets * article.article.lost_packaging.value_80_per_120 * 10,
        ) / 10;
      if (equivalentValue === 0)
        equivalentValue =
          Math.round(
            nbPallets * article.article.lost_packaging.value_80_per_120 * 100,
          ) / 100;

      const nbUvcPicking = Math.round(
        articleUvcByPallet * (rawNbPallets - nbFullPallets),
      );
      return {
        key: article.id,
        code: article.article.internal_code,
        uvc: articleUvcByPallet,
        quantity: article.asked_quantity,
        nbPallets: nbPallets,
        nbFullPallets: nbFullPallets,
        nbUvcFullPallets: nbUvcFullPallets,
        equivalentPicking: equivalentPicking,
        nbUvcPicking: nbUvcPicking,
        palletType:
          PalletTypeMapper[article.article.lost_packaging.value_80_per_120],
        equivalentValue: equivalentValue,
      };
    });
  },
);

export const selectArticleForModalTableSK = createSelector(
  (state) => state.pallets.articles,
  (articles) => {
    return articles.map((article) => {
      if (!article.article.lost_packaging) {
        return {
          key: article.id,
          code: <p style={{ color: "red" }}>{article.article.internal_code}</p>,
        };
      }
      console.log("article", article);
      const articleUvcByPallet =
        article.article.conversion_handling_unit_um || 1;
      let rawNbPallets = article.asked_quantity / articleUvcByPallet;
      let nbPallets =
        Math.round((article.asked_quantity / articleUvcByPallet) * 10) / 10;
      if (nbPallets === 0)
        nbPallets =
          Math.round((article.asked_quantity / articleUvcByPallet) * 100) / 100;
      const nbFullPallets = Math.floor(nbPallets);
      const nbUvcFullPallets = articleUvcByPallet * nbFullPallets;
      let equivalentPicking = Math.round((nbPallets - nbFullPallets) * 10) / 10;
      if (equivalentPicking === 0)
        equivalentPicking = Math.round((nbPallets - nbFullPallets) * 100) / 100;
      let equivalentValue =
        Math.round(
          nbPallets * article.article.lost_packaging.value_80_per_120 * 10,
        ) / 10;
      if (equivalentValue === 0)
        equivalentValue =
          Math.round(
            nbPallets * article.article.lost_packaging.value_80_per_120 * 100,
          ) / 100;

      const nbUvcPicking = Math.round(
        articleUvcByPallet * (rawNbPallets - nbFullPallets),
      );
      return {
        key: article.id,
        code: article.article.internal_code,
        uvc: articleUvcByPallet,
        quantity: article.asked_quantity,
        nbPallets: nbPallets,
        nbFullPallets: nbFullPallets,
        nbUvcFullPallets: nbUvcFullPallets,
        equivalentPicking: equivalentPicking,
        nbUvcPicking: nbUvcPicking,
        palletType:
          PalletTypeMapper[article.article.lost_packaging.value_80_per_120],
        equivalentValue: equivalentValue,
      };
    });
  },
);

export const selectPalletsForModalTable = createSelector(
  (state) => state.pallets.list,
  (list) => {
    if (!list.length) {
      return { error: "NO LIST", list: [] };
    }
    const ret = list
      .map((pallet) => ({
        id: pallet.id,
        key: pallet.id,
        number: pallet.number,
        pallet_type: pallet.is_picking ? "Picking" : "Homogène",
        pallet_type_for_sort: pallet.is_picking ? 1 : 0,
        pallet_type_eq: `${pallet.is_picking ? "Picking" : "Homogène"} / ${
          pallet.equivalent
        }`,
        weight: pallet.net_weight,
        equivalent: pallet.equivalent,
        code: pallet.article_order_pallets.map(
          (articlePallets) =>
            `${articlePallets.article_order.article.internal_code}: ${articlePallets.number_uvc}`,
        ),
      }))
      .sort((a, b) => a.pallet_type_for_sort - b.pallet_type_for_sort);
    return { list: ret };
  },
);

/*  return {
      pallets: pallets.sort(
        (a, b) => a.pallet_type_for_sort - b.pallet_type_for_sort,
      ),
      totals,
    };
  },
);*/

export const selectTotalsForModalTableClassic = createSelector(
  (state) => state.pallets.list,
  (list) => {
    const totals = { full_80: 0 };
    list.forEach((pallet) => {
      const equivalent = parseFloat(pallet.equivalent);
      if (!totals[equivalent]) {
        totals[equivalent] = pallet.number;
      } else {
        totals[equivalent] += pallet.number;
      }
      if (equivalent === 1) {
        totals.full_80 += pallet.number;
      } else {
        totals.full_80 += pallet.number * equivalent;
      }
    });
    totals.full_80_rounded = Math.round(totals.full_80);
    return totals;
  },
);

export const selectTotalsForModalTableSK = createSelector(
  (state) => state.pallets.list,
  (list) => {
    const totals = { full_80: 0 };
    list.forEach((pallet) => {
      const equivalent = parseFloat(pallet.equivalent);
      if (!totals[equivalent]) {
        totals[equivalent] = pallet.number;
      } else {
        totals[equivalent] += pallet.number;
      }
      if (equivalent === 1) {
        totals.full_80 += pallet.number;
      } else {
        totals.full_80 += pallet.number * equivalent;
      }
    });
    totals.full_80_rounded = Math.round(totals.full_80);
    return totals;
  },
);
