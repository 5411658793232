import { call, put, select, takeLatest } from "redux-saga/effects";
import { types } from "./actions";
import { LoaderActions } from "../loader";
import { getTranslate } from "react-localize-redux";
import { showError } from "../../utils/notifications-helper";
import { AppActions } from "./index";
import { AppService } from "../../services";

function* getAdminFileRequest() {
  yield put(LoaderActions.loading());
  const strings = yield select((state) => getTranslate(state.locale));

  const [error, response] = yield call(AppService.adminFile);
  if (error) {
    showError("status_failed", strings);
    yield put(AppActions.adminFileFailure());
  } else if (response)
    yield put(AppActions.adminFileSuccess(response.admin_file));
  yield put(LoaderActions.loaded());
}

const sagas = [takeLatest(types.ADMIN_FILE_REQUEST, getAdminFileRequest)];

export default sagas;
