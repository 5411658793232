import React from "react";
import { Button, Modal } from "antd";
import { useDispatch } from "react-redux";

import "./style.scss";
import FileUpload from "../fileUpload/FileUpload";
import { BlActions } from "../../../redux/bl";
import { AuditOutlined } from "@ant-design/icons";

const PODModale = ({
  strings,
  showModal,
  titleModale,
  podFile,
  receiptUrl,
  blNum,
  onClose = () => {},
}) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
  };

  const onClickTracking = () => {
    if (podFile) {
      dispatch(BlActions.getSignedUrl(podFile));
    } else {
      window.open(receiptUrl);
    }
  };

  const onDownload = (type) => (file) => {
    if (dispatch) {
      dispatch(BlActions.importFile(type, file, blNum));
    }
  };

  return (
    <Modal
      className={"modal-pod"}
      title={`${strings(titleModale)} pour BL ${blNum}`}
      open={showModal}
      closable={false}
      footer={
        <>
          <Button
            id={"validate-modal-FC"}
            type={"primary"}
            onClick={() => handleClose()}
          >
            {strings("close")}
          </Button>
        </>
      }
    >
      {(podFile || receiptUrl) && (
        <Button
          icon={<AuditOutlined />}
          id={"modal_pod_view_button"}
          onClick={() => onClickTracking()}
          tooltipTitle={strings("POD")}
        >
          {strings("view_pod")}
        </Button>
      )}

      <FileUpload
        accept={"image/*,.pdf"}
        customDownloadFunction={onDownload("POD")}
        id={"modal_pod_upload_button"}
      >
        <Button>{strings("upload_pod")}</Button>
      </FileUpload>
    </Modal>
  );
};

export default PODModale;
