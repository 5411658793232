import React, { useContext } from "react";
import { Button } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { BlActions } from "../../../redux/bl";
import { useDispatch } from "react-redux";
import { AbilityContext } from "../../../utils/casl/v2/can";

const ExportXLS = ({ strings, sorter = {}, filters = {}, style = {} }) => {
  const dispatch = useDispatch();
  const canCtx = useContext(AbilityContext);

  const onClick = async () => {
    if (dispatch) {
      const isSk = canCtx.can("board", "sk");
      console.log("isSk: ", isSk);
      if (isSk) {
        dispatch(BlActions.exportSKRequest(filters, sorter));
      } else {
        dispatch(BlActions.exportClassicRequest(filters, sorter));
      }
    }
  };

  return (
    <Button
      icon={<SaveOutlined />}
      style={style}
      onClick={onClick}
      type="primary"
    >
      {strings("export")}
    </Button>
  );
};

export default ExportXLS;
