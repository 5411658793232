import React from "react";
import { Progress } from "antd";

const ProgressBl = ({ percent, strings, width, type = "line" }) => {
  return (
    <div style={{ display: "flex", width: width }}>
      <Progress type={type} percent={percent} />
    </div>
  );
};

export default ProgressBl;
