import { gql } from "@apollo/client";

const queries = {
  orderStates: () => gql`
    query getOrderStates {
      order_states(where: { code: { _neq: "AWAITING_PREPARATION" } }) {
        id
        sealogis_value
        code
      }
    }
  `,
  deliveryStatus: () => gql`
    query getDeliveryStatus {
      delivery_states {
        id
        code
      }
      delivery_status {
        id
        code
      }
    }
  `,
  disputeReason: () => gql`
    query getDisputeReason {
      dispute_reasons {
        id
        code
      }
    }
  `,
  delayReason: () => gql`
    query getDelayReason {
      delay_reasons {
        id
        code
      }
    }
  `,
  responsibility: () => gql`
    query getResponsibility {
      responsibilities {
        id
        code
      }
    }
  `,
  warehouseStatus: () => gql`
    query getWarehouseStatus {
      under_order_step_status {
        id
        sealogis_value
        code
      }
    }
  `,

  skRelatedStatus: gql`
    query getAllRelatedSkStatus {
      extra_cost_reason_start {
        id
        sealogis_code
      }
      extra_cost_reason_destination {
        id
        sealogis_code
      }
      offset_pattern_pickup {
        id
        sealogis_code
      }
      offset_pattern_delivery {
        id
        sealogis_code
      }
      responsibilities_sk {
        id
        sealogis_code
      }
      shipment_status {
        id
        sealogis_code
      }
    }
  `,
};

export default queries;
