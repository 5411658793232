import React from "react";
import "./style.css";
import { Drawer } from "antd";
import { CloseButton } from "../button";

const BlStepDrawer = ({ strings, onClose, visible, item, bl }) => {
  const infos = [
    { key: strings("ref_client"), value: bl?.r_consignee_ref },
    { key: strings("ref_id_unique"), value: bl?.bl_num },
    { key: strings("partner"), value: item?.name },
    { key: `${strings("address")} 1`, value: item?.partner?.address1 },
    { key: `${strings("address")} 2`, value: item?.partner?.address2 },
    { key: strings("code_postal"), value: item?.partner?.zip },
    { key: strings("city"), value: item?.partner?.city },
    { key: strings("country"), value: item?.partner?.country },
    {
      key: strings("contact_name"),
      value: item?.partner?.contact_name1,
    },
    { key: strings("tel"), value: item?.partner?.contact_tel1 },
    { key: strings("tel"), value: item?.partner?.contact_tel2 },
    {
      key: `${strings("email")} 1`,
      value: item?.partner?.contact_mail1,
    },
    { key: `${strings("email")} 2`, value: item?.partner?.contact_tel2 },
  ];

  return (
    <>
      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        open={visible}
        width={"30%"}
      >
        <div className="drawer-header">
          <div className="close-button">
            <CloseButton modify={onClose} type="primary" shape="circle" />
          </div>
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%", textAlign: "center" }}>
              <span className="header-span">
                {strings("step")} {(item?.order ?? 0) + 1}
              </span>
            </div>
            <div style={{ width: "100%", textAlign: "center" }}>
              <span className="header-span">{item?.type}</span>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {item?.partner?.image && (
                <img style={{ maxWidth: "50%" }} src={item?.partner?.image} alt={""}/>
              )}
            </div>
          </div>
        </div>
        <div className="information-wrapper">
          <div
            style={{
              width: "100%",
            }}
          >
            {infos.map(({ key, value }) => (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
                key={`custom_key_${value}`}
              >
                <span
                  style={{ width: "40%" }}
                  className={"title-infos"}
                >{`${key}: `}</span>
                <span
                  style={{
                    width: "60%",
                    textAlign: "start",
                    overflowWrap: "break-word",
                  }}
                >
                  {value}
                </span>
              </div>
            ))}
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default BlStepDrawer;
