import { EditOutlined } from "@ant-design/icons";
import "./style.scss";
import { useMemo } from "react";
import EditWrapper, { editWrapperTypes } from "./EditWrapper";
import { Button, Popover, Typography } from "antd";
import { BasicComponents } from "../../../components/basic";

export const EditionComponent = ({
  id,
  value,
  type = "text",
  disabled = false,
  disabledBtn,
  hideOptions = [],
  enableFields = [],
  allowClear = true,
  updateInfos,
  selector,
  showSearch = false,
  optionsStyle = null,
  strings,
  isEdit,
  style = {},
  showTooltip = false,
  showTooltipReadOnly = false,
  tooltipTitle,
  isBlocked = false,
  isBlockedByMultiEdition = false,
  canWrite = false,
  required = true,
  setIsEdit = () => {},
  onCancel = () => {},
  format,
}) => {
  const isError = useMemo(
    () => !Object.keys(editWrapperTypes).includes(type),
    [type],
  );

  const reformatValue = (val) => {
    if (type === "select-boolean") {
      return val ? strings("yes") : strings("no");
    }
    return val;
  };

  if (!canWrite) {
    if (showTooltipReadOnly) {
      return (
        <BasicComponents.Tooltip color={"#FFF"} title={tooltipTitle}>
          <Typography style={{ ...style }}>{reformatValue(value)}</Typography>
        </BasicComponents.Tooltip>
      );
    }
    return <Typography style={{ ...style }}>{reformatValue(value)}</Typography>;
  }

  const Content = () => {
    return <Typography style={{ ...style }}>{reformatValue(value)}</Typography>;
  };

  return (
    <div className="edition-row-container">
      {!isError && isEdit ? (
        <EditWrapper
          type={type}
          disabled={disabled}
          strings={strings}
          allowClear={allowClear}
          hideOptions={hideOptions}
          enableFields={enableFields}
          value={value}
          selector={selector}
          showSearch={showSearch}
          optionsStyle={optionsStyle}
          onCancel={onCancel}
          updateInfos={updateInfos}
          required={required}
          format={format}
        />
      ) : (
        <>
          {showTooltip ? (
            <BasicComponents.Tooltip color={"#FFF"} title={tooltipTitle}>
              <Content />
            </BasicComponents.Tooltip>
          ) : (
            <Content />
          )}
          <Popover
            title={
              isBlockedByMultiEdition || isBlocked || disabledBtn
                ? strings("edit_blocked_title")
                : false
            }
            content={
              isBlockedByMultiEdition
                ? strings("edit_blocked_multi_content")
                : isBlocked
                ? strings("edit_blocked_content")
                : disabledBtn
                ? disabledBtn
                : false
            }
          >
            <Button
              id={id}
              className="icon-edition"
              shape={"circle"}
              disabled={isBlockedByMultiEdition || isBlocked || disabledBtn}
              onClick={() => setIsEdit()}
              icon={<EditOutlined />}
            />
          </Popover>
        </>
      )}
    </div>
  );
};

export default EditionComponent;
