import React, { useEffect, useMemo } from "react";
import { Form, Input, Modal, Checkbox, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AuthActions, selectCurrentUserAdminFile } from "../../../redux/auth";
import { selectAdminFile } from "../../../redux/app/selector";
import { get, pick } from "lodash";
import "./style.scss";

const FirstConnectionModal = ({
  renewPwd,
  strings,
  validateModalFirstConnection,
}) => {
  const [form] = Form.useForm();
  const userAdminFile = useSelector(selectCurrentUserAdminFile);
  const adminFile = useSelector(selectAdminFile);
  const dispatch = useDispatch();

  const {
    cguChange,
    privacyChange,
    showModal = false,
  } = useMemo(() => {
    const cgu = get(userAdminFile, "cgu", null);
    const cguAdmin = get(adminFile, ["cgu", "id"], null);
    const privacy_policy = get(userAdminFile, "privacy_policy", null);
    const privacy_policyAdmin = get(adminFile, ["privacy_policy", "id"], null);
    const cguChange = cgu !== cguAdmin;
    const privacyChange = privacy_policy !== privacy_policyAdmin;
    return {
      cguChange,
      privacyChange,
      showModal: cguChange || privacyChange || renewPwd,
    };
  }, [userAdminFile, adminFile, renewPwd]);

  useEffect(() => {
    form.resetFields();
    return () => {
      form.resetFields();
    };
  });

  const handleOk = () => {
    form.submit();
  };

  const handleSubmit = (values) => {
    let filter = pick(values, ["password"]);
    if ("cgu" in values) {
      filter = { ...filter, cgu: get(adminFile, ["cgu", "id"], null) };
    }
    if ("privacy_policy" in values) {
      filter = {
        ...filter,
        privacy_policy: get(adminFile, ["privacy_policy", "id"], null),
      };
    }
    if (validateModalFirstConnection) validateModalFirstConnection(filter);
  };

  const requiredRules = ({ required, ...item }) => {
    let ret = {
      message: strings("required_field"),
      required,
    };
    if (!item.component) ret = { ...ret, whitespace: true };
    return ret;
  };

  const onFilePreview = (event, key) => {
    event.preventDefault();
    const location = adminFile[key]?.filename;
    dispatch(
      AuthActions.getAdminFileSignedUrl({
        location,
        callbacks: {
          onSuccess: (signedUrl) => {
            window.open(signedUrl, "_blank");
          },
          onError: () => {},
        },
      }),
    );
  };

  //eslint-disable-next-line
  const fields = [
    {
      key: "password",
      component: "password",
      required: true,
      error: strings("password_complex"),
      validator: (item) => {
        return !item
          ? true
          : item.match(
              /^(?=.*?[A-Z])(?=(.*[a-z])+)(?=(.*\d)+)(?=(.*\W)+)(?!.*\s).{10,}$/g,
            );
      },
    },
    {
      key: "re_password",
      component: "password",
      required: true,
      error: strings("password_identical"),
      validator: (item) => {
        const re = form.getFieldValue("password");
        return re === item;
      },
    },
    {
      key: "cgu",
      requiredMark: true,
      label: false,
      component: "checkbox",
      labelText: strings("cgu_accept"),
      error: strings("cgu_required"),
      validator: (item) => {
        return item === true;
      },
    },
    {
      key: "privacy_policy",
      requiredMark: true,
      label: false,
      component: "checkbox",
      labelText: strings("privacy_policy_accept"),
      error: strings("privacy_policy_required"),
      validator: (item) => {
        return item === true;
      },
    },
  ];

  const filterFields = useMemo(() => {
    return fields.filter(({ key }) => {
      if (!cguChange && key === "cgu") return false;
      if (!privacyChange && key === "privacy_policy") return false;
      return !(!renewPwd && ["password", "re_password"].includes(key));
    });
  }, [fields, cguChange, privacyChange, renewPwd]);

  const components = {
    checkbox: (item) => (
      <Checkbox
        id={`checkbox-${item.key}`}
        onChange={(e) => form.setFieldsValue({ [item.key]: e.target.checked })}
      >
        {item.labelText}
        <span
          className={"link-button"}
          onClick={(e) => onFilePreview(e, item.key)}
        >
          {strings(item.key)}
        </span>
      </Checkbox>
    ),
    password: ({ key }) => <Input placeholder={strings(key)} type="password" />,
  };

  return (
    <div id={"modal-first-connection"}>
      <Modal
        title={strings(!renewPwd ? "file_admin_change" : "password_modal")}
        open={showModal}
        closable={false}
        footer={
          <>
            <Button
              id={"validate-modal-FC"}
              type={"primary"}
              onClick={handleOk}
            >
              {strings("save")}
            </Button>
          </>
        }
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          className="modal-first-co"
        >
          {filterFields.map(
            (
              {
                key,
                label = true,
                validator,
                error,
                component,
                required,
                requiredMark,
                labelText,
              },
              index,
            ) => (
              <Form.Item
                name={key}
                label={label ? strings(key) : null}
                required={required || requiredMark || false}
                rules={[
                  requiredRules(filterFields[index]),
                  () => ({
                    validator(_, value) {
                      if (validator && !validator(value))
                        return Promise.reject(
                          error || `${strings(key)} incorrect`,
                        );
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                {components[component || "input"]({ key, labelText })}
              </Form.Item>
            ),
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default FirstConnectionModal;
