import { BasicComponents } from "../basic";
import { useMemo } from "react";

const VolumeComponent = ({ volumes = {}, type, strings, id }) => {
  const { last = {}, all = {}, current = null } = volumes;
  const array = ["theoretical", "log", "real"];
  const title = useMemo(
    () =>
      array
        .map(
          (i) =>
            `${strings(`volume_type_${i}`)}: ${
              all[i] ? all[i][type] : strings("no_data")
            }`,
        )
        .join("\n"),
    //eslint-disable-next-line
    [last],
  );
  const color = useMemo(
    () => (last?.error?.includes(type) ? "orange" : ""),
    //eslint-disable-next-line
    [last],
  );

  return (
    <BasicComponents.Tooltip
      overlayStyle={{ whiteSpace: "pre-line" }}
      title={title}
      color="black"
    >
      <span id={id} style={{ color }}>
        {(current || last)[type]}
      </span>
    </BasicComponents.Tooltip>
  );
};

export default VolumeComponent;
