import { Button, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import "./style.css";

const EditButton = ({ id, onClick = () => {}, tooltipTitle, icon = null }) => {
  return (
    <Tooltip title={tooltipTitle}>
      <Button
        id={id}
        ghost
        onClick={onClick}
        className={"edit-button"}
        shape={"circle"}
        type={"primary"}
        icon={icon || <EditOutlined />}
      />
    </Tooltip>
  );
};

export default EditButton;
