import { getTranslate } from "react-localize-redux/lib/index";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Error } from "./Error";

const mapStateToProps = (state) => ({
  strings: getTranslate(state.locale),
});

const mapDispatchToProps = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Error));
