import {
  authenticatedMutation,
  authenticatedQuery,
  authenticatedService,
} from "./middleware";
import statusQueries from "./GraphQLRequest/statusQueries";
import { methods } from "../utils";
import blQueries from "./GraphQLRequest/blQueries";
import blMutations from "./GraphQLRequest/blMutations";
import { orderParams } from "./hookHelpers/bl";

const { POST, GET } = methods;

const routes = {
  exportClassic: process.env.REACT_APP_MATRIX_API_URL + "/tt/excel-dashboard",
  exportSK: process.env.REACT_APP_MATRIX_API_URL + "/tt/excel-dashboard-sk",
  bls: process.env.REACT_APP_SYNC_URL + "/bls",
  bl: (id) => process.env.REACT_APP_SYNC_URL + "/bl/" + id,
  bextSync: process.env.REACT_APP_MATRIX_API_URL + "/bext/sync",
  teliaSync: process.env.REACT_APP_MATRIX_API_URL + "/telia/sync",
  charterSync:
    process.env.REACT_APP_MATRIX_API_URL + "/charter/import_charter_file",
  manualOrderSync:
    process.env.REACT_APP_MATRIX_API_URL + "/optimea/file_for_order",
  getEdiFile: process.env.REACT_APP_MATRIX_API_URL + "/tt/get-edi-file",
  uploadImage: process.env.REACT_APP_STORAGE_URL + "/codex",
  uploadPOD: process.env.REACT_APP_MATRIX_API_URL + "/tt/upload_pod",
  getSignedUrl: process.env.REACT_APP_MATRIX_API_URL + "/codex",
};

const services = {
  exportClassic: ({ filters, sorter }) =>
    authenticatedService(
      POST,
      routes.exportClassic,
      { input: { filters, sorter } },
      {},
      {},
      "blob",
    ),
  exportSK: ({ filters, sorter }) =>
    authenticatedService(
      POST,
      routes.exportSK,
      { input: { filters, sorter } },
      {},
      {},
      "blob",
    ),
  bls: ({ offset, limit, filters, sorter, whereRemitter, userId }) => {
    const { where, variables, params, orderBy } = orderParams(filters, sorter);
    return authenticatedQuery(
      blQueries.testBls(where, params, whereRemitter),
      {},
      {
        ...variables,
        user_id: userId,
        order_by: orderBy,
        offset,
        limit,
      },
    );
  },
  bl: (id) =>
    authenticatedQuery(
      blQueries.testBl,
      {},
      {
        id: id,
      },
    ),
  getFilesRemitterByTypeId: ({ typeId, remitterId }) =>
    authenticatedQuery(
      blQueries.getFilesRemitterByTypeId,
      {},
      {
        file_type_id: typeId,
        remitter_id: remitterId,
      },
    ),
  getFilesRemitterByOrderId: ({ orderId, remitterId }) =>
    authenticatedQuery(
      blQueries.getFilesRemitterByOrderId,
      {},
      {
        order_id: orderId,
        remitter_id: remitterId,
      },
    ),
  getFileType: () => authenticatedQuery(blQueries.getFileType),
  getNumberFileType: (remitter_id) =>
    authenticatedQuery(blQueries.getNumberFileType, {}, { remitter_id }),
  updateBl: (id, data) =>
    authenticatedQuery(blMutations.update, {}, { id, data }),
  updateOrderDataBcu: (id, data) =>
    authenticatedMutation(blMutations.updateOrderDataBcu, {}, { id, data }),
  updateOrderDataSealogis: (id, data) =>
    authenticatedMutation(
      blMutations.updateOrderDataSealogis,
      {},
      { id, data },
    ),
  updateOrderDataSk: (id, data) =>
    authenticatedMutation(blMutations.updateOrderDataSk, {}, { id, data }),
  updateVolume: (id, data, where) =>
    authenticatedMutation(blMutations.updateVolume, {}, { id, data, where }),
  //export: ({filters, sorter}) => authenticatedQuery(blQueries.export(), {}, {filters, sorter}),
  //authenticatedService(GET, routes.bl(id), {}, {}),
  status: () => authenticatedQuery(statusQueries.status()),
  importBextFile: (file) => authenticatedService(POST, routes.bextSync, file),
  importTeliaFile: (file) => authenticatedService(POST, routes.teliaSync, file),
  importCharterFile: (file) =>
    authenticatedService(POST, routes.charterSync, file),
  importManualOrderFile: (file) =>
    authenticatedService(POST, routes.manualOrderSync, file),
  importPODFile: (file) => authenticatedService(POST, routes.uploadPOD, file),
  getEdiFile: ({ type, file_name, remitter_name, remitter_code, wms_code }) =>
    authenticatedService(
      POST,
      routes.getEdiFile,
      { type, file_name, remitter_name, remitter_code, wms_code },
      {},
      {},
      "blob",
    ),
  createImageOvh: (file) =>
    authenticatedService(POST, routes.uploadImage, file),
  getImageOvh: (location) =>
    authenticatedService(GET, routes.getSignedUrl, {}, { location }),
  createArticleFile: (articleFilePayload) =>
    authenticatedMutation(
      blMutations.createArticleFile(),
      {},
      { articleFilePayload },
    ),
  createFileRemitter: (fileRemitterPayload) =>
    authenticatedMutation(
      blMutations.createFileRemitter(),
      {},
      { fileRemitterPayload },
    ),
  deleteArticleFile: (id) =>
    authenticatedMutation(blMutations.deleteArticleFile(), {}, { id }),
  deleteFileRemitter: (id) =>
    authenticatedMutation(blMutations.deleteFileRemitter(), {}, { id }),
  searchForClients: (value) =>
    authenticatedQuery(blQueries.searchForClients, {}, { value }),
  searchForOrders: ({ query, show_on_dashboard }) =>
    authenticatedQuery(
      blQueries.searchForOrders,
      {},
      { query, show_on_dashboard },
    ),
};

export default services;
