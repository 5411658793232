import { createSelector } from "reselect";

export const selectTotalBls = createSelector(
  (state) => state.bl.total,
  (total) => total,
);

export const selectHasNewOrder = createSelector(
  (state) => state.bl.hasNewOrder,
  (hasNewOrder) => hasNewOrder,
);

export const selectStates = createSelector(
  (state) => state.bl.states,
  (states) => states,
);

export const selectClients = createSelector(
  (state) => state.bl.clients,
  (clients) => clients,
);

export const selectFileType = createSelector(
  (state) => state.bl.fileType,
  (fileType) => fileType,
);

export const selectFilesRemitter = createSelector(
  (state) => state.bl.filesRemitter,
  (filesRemitter) => filesRemitter,
);

export const selectClientsFormatted = createSelector(
  (state) => state.bl.clients,
  (clients) => {
    return clients.map((el) => ({
      text: el.name,
      value: el.code,
    }));
  },
);

export const selectTypes = createSelector(
  (state) => state.bl.types,
  (types) => types,
);

export const selectCities = createSelector(
  (state) => state.bl.cities,
  (cities) => cities,
);

export const selectNbFiles = createSelector(
  (state) => state.bl.nbFiles,
  (nbFiles) => nbFiles,
);

export const selectOrderClients = createSelector(
  (state) => state.bl,
  ({ searchedClients, nbSearchedClients }) => [
    searchedClients,
    nbSearchedClients,
  ],
);

export const selectClientsForOrders = createSelector(
  (state) => state.bl,
  ({ searchedClients, nbSearchedClients }) => [
    searchedClients,
    nbSearchedClients,
  ],
);

export const selectSearchedOrders = createSelector(
    (state) => state.bl,
    ({ searchedOrders }) => searchedOrders,
);

export const signedUrl = createSelector(
  (state) => state.bl.signedUrl,
  (signedUrl) => signedUrl,
);

export const sendFileSuccess = createSelector(
  (state) => state.bl.sendFileSuccess,
  (sendFileSuccess) => sendFileSuccess,
);

export const selectedIndex = createSelector(
  (state) => state.bl.selectedIndex,
  (selectedIndex) => selectedIndex,
);

export const selectNumberFilesTypes = createSelector(
  (state) => state.bl.filesNumberRemitter,
  (filesNumberRemitter) => filesNumberRemitter,
);

export const selectRemitterInFilters = createSelector(
  (state) => state.bl.filters,
  (filters) => filters?.client,
);
