import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { Router, store } from "./core";
import { GlobalLoader, FirstConnectionModal } from "./ui/components";
import client from "./utils/apolloClient";
import "./assets/style.css";
import { ApolloProvider } from "@apollo/client";
import { AbilityContext } from "./utils/casl/v2/can";
import ability from "./utils/casl/v2/ability";

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <AbilityContext.Provider value={ability}>
        <Fragment>
          <FirstConnectionModal />
          <Router />
          <GlobalLoader />
        </Fragment>
      </AbilityContext.Provider>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
