/* Service utils */
import axios from "axios";
import client from "./apolloClient";
/** GRAPHQL */

/** GRAPHQL - MUTATION */
export const mutation = ({ queryString, headers = {}, variables }) =>
  client
    .mutate({
      mutation: queryString,
      variables,
      context: {
        headers: headers,
      },
    })
    .then((result) => {
      // eslint-disable-next-line no-console
      console.log({ result });
      return Promise.all([result.errors, result.data]);
    })
    .catch((error) => {
      return Promise.resolve([error]);
    });

/** GRAPHQL - QUERY */
export const query = ({ queryString, headers = {}, variables }) =>
  client
    .query({
      query: queryString,
      variables,
      context: {
        headers: headers,
      },
      // ... other options
    })
    .then((result) => {
      // console.log({ result })
      return Promise.all([result.errors, result.data]);
    })
    .catch((error) => {
      return Promise.resolve([error]);
    });

/** Method types */
export const methods = {
  GET: "get",
  POST: "post",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete",
};

export const fetch = ({
  method,
  url,
  data = {},
  params = {},
  headers = {},
  responseType,
  uploadCallBack = (e) => {},
}) => {
  let instance = axios.create();
  /*  instance.interceptors.request.use(AxiosLogger.requestLogger);

      instance.interceptors.response.use(AxiosLogger.responseLogger, (err) =>{
        // write down your error intercept.
        return AxiosLogger.errorLogger(err);
    });*/

  instance.defaults.headers.common = {};
  const { cancelToken } = params;
  delete params.cancelToken;

  return instance(
    {
      baseURL: process.env.REACT_APP_BASE_API_URL,
      method: method,
      url: url,
      data: data,
      params: params,
      cancelToken: cancelToken,
      responseType,
      headers: {
        ...headers,
        "Access-Control-Allow-Origin": "*",
        Accept: "*/*",
      },
      onUploadProgress: (progressEvent) => uploadCallBack(progressEvent),
    },
    { crossdomain: true },
  )
    .then((response) => {
      return Promise.all([null, response]);
    })
    .catch((error) => {
      // Fix proposed by TNU when error.response = undefined (for NetworkError)
      if (error.response) {
        return Promise.resolve([error]);
      }
      return Promise.resolve([{ response: { status: 500 } }]);
    });
};
