import { createAction } from "../../utils";

export const types = {
  BCU_DATA_REQUEST: "BCU_DATA_REQUEST",
  BCU_DATA_SUCCESS: "BCU_DATA_SUCCESS",

  TYPE_FLUX_REQUEST: "TYPE_FLUX_REQUEST",
  TYPE_FLUX_SUCCESS: "TYPE_FLUX_SUCCESS",

  XC_STATUS_REQUEST: "XC_STATUS_REQUEST",
  XC_STATUS_SUCCESS: "XC_STATUS_SUCCESS",

  PARTNER_CARRIER_REQUEST: "PARTNER_CARRIER_REQUEST",
  PARTNER_CARRIER_SUCCESS: "PARTNER_CARRIER_SUCCESS",
};

const actions = {
  bcuDataRequest: () => createAction(types.BCU_DATA_REQUEST),
  bcuDataSuccess: (bcuData) =>
    createAction(types.BCU_DATA_SUCCESS, { bcuData }),
  typeFluxRequest: () => createAction(types.TYPE_FLUX_REQUEST),
  typeFluxSuccess: (typeFlux) =>
    createAction(types.TYPE_FLUX_SUCCESS, { typeFlux }),
  xcStatusRequest: () => createAction(types.XC_STATUS_REQUEST),
  xcStatusSuccess: (xcStatus) =>
    createAction(types.XC_STATUS_SUCCESS, { xcStatus }),
  partnerCarrierRequest: () => createAction(types.PARTNER_CARRIER_REQUEST),
  partnerCarrierSuccess: (partners) =>
    createAction(types.PARTNER_CARRIER_SUCCESS, { partners }),
};

export default actions;
