import React from "react";
import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/fr_FR";
import "./style.css";

const InputDate = ({
  onFilterChange = () => {},
  strings = () => {},
  name,
  placeHolder,
  ...props
}) => {
  const onChange = (val) => {
    onFilterChange({ [name]: val });
  };
  const dateFormat = strings("date_format");

  return (
    /*<div className={"input-date-wrapper"}>*/
    <DatePicker
      {...props}
      format={dateFormat}
      /*style={{ width: "100%" }}*/
      placeholder={strings(placeHolder)}
      locale={locale}
      onChange={onChange}
    />
    /*</div>*/
  );
};

export default InputDate;
