import { gql } from "@apollo/client";

const queries = {
  /*getOrderPallets: gql`
    query getOrderPallets($id: Int!) {
      article_orders(where: { order_id: { _eq: $id } }) {
        id
        article {
          code
        }
        article_order_pallets {
          id
          number_uvc
          pallet {
            id
            number
            net_weight
            equivalent
            is_picking
          }
        }
      }
    }
  `,*/
  getOrderPallets: gql`
    query getOrderPallets($id: Int!) {
      pallets(
        where: {
          article_order_pallets: { article_order: { order_id: { _eq: $id } } }
        }
      ) {
        id
        number
        net_weight
        equivalent
        is_picking
        article_order_pallets {
          id
          number_uvc
          article_order {
            article {
              code
              internal_code
            }
          }
        }
      }
      article_orders(where: { order_id: { _eq: $id } }) {
        id
        asked_quantity
        article {
          code
          conversion_handling_unit_um
          internal_code
          uvc_by_palet
          lost_packaging {
            family
            value_80_per_120
          }
        }
      }
    }
  `,
};

export default queries;
