import React from "react";
import { Space, Table } from "antd";
import { useSelector } from "react-redux";

export const ArticlesTable = ({
  id,
  selectArticle,
  selectTotal,
  loading = false,
  strings = (_) => {},
}) => {
  const articles = useSelector(selectArticle);
  const totals = useSelector(selectTotal);

  const columns = [
    {
      title: strings("article_table_code"),
      dataIndex: "code",
      align: "center",
      width: 170,
    },
    {
      title: strings("article_table_uvc_by_pallet"),
      dataIndex: "uvc",
      align: "center",
    },
    {
      title: strings("article_table_nb_uvc_ordered"),
      dataIndex: "quantity",
      align: "center",
    },
    {
      title: strings("article_table_nb_all_pallets"),
      dataIndex: "nbPallets",
      align: "center",
    },
    {
      title: strings("article_table_nb_full_pallets"),
      dataIndex: "nbFullPallets",
      align: "center",
    },
    {
      title: strings("article_table_nb_uvc_full"),
      dataIndex: "nbUvcFullPallets",
      align: "center",
    },
    {
      title: strings("article_table_nb_picking_pallets"),
      dataIndex: "equivalentPicking",
      align: "center",
    },
    {
      title: strings("article_table_nb_uvc_picking"),
      dataIndex: "nbUvcPicking",
      align: "center",
    },
    {
      title: strings("article_table_pallet_type"),
      dataIndex: "palletType",
      align: "center",
    },
    {
      title: strings("article_table_equivalent"),
      dataIndex: "equivalentValue",
      align: "center",
    },
  ];
  return (
    <>
      <Table
        id={id}
        bordered
        pagination={false}
        columns={columns}
        dataSource={articles || []}
        loading={loading}
      />
      <br />
      <Space size={20} wrap>
        <p>
          {strings("pallet_modal_80_120_total")} : {totals["1"]}
        </p>
        <p> / </p>
        <p>
          {strings("pallet_modal_100_120_total")} : {totals["1.25"]}
        </p>
        <p> / </p>
        <p>
          {strings("pallet_modal_full_total")} : {totals["full_80"]} /
          {totals["full_80_rounded"]}
        </p>
      </Space>
    </>
  );
};
