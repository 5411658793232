import BlInfo from "./BlInfo";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { getTranslate } from "react-localize-redux";
import { resolveId } from "../../../utils";
import { BlActions } from "../../../redux/bl";

const mapStateToProps = ({ router, locale, bl }) => ({
  id: resolveId(router),
  strings: getTranslate(locale),
  bl: bl.current,
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path, param) => dispatch(push(path, { param })),
  BlRequest: (id) => dispatch(BlActions.blRequest(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlInfo);
