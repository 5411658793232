import { gql } from "@apollo/client";

const mutations = {
  fluxs: () => gql`
    query getFlux {
      flux {
        client
        created_at
        id
        is_final
        steps_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  `,
  update: gql`
    mutation updateOneBl($id: Int!, $data: orders_set_input!) {
      update_orders_by_pk(pk_columns: { id: $id }, _set: $data) {
        id
      }
    }
  `,

  updateOrderDataBcu: gql`
    mutation updateOrderDataBCU($id: Int!, $data: order_data_bcu_set_input!) {
      update_order_data_bcu(
        where: { orders: { id: { _eq: $id } } }
        _set: $data
      ) {
        returning {
          id
        }
      }
    }
  `,

  updateOrderDataSealogis: gql`
    mutation updateOrderDataSealogis(
      $id: Int!
      $data: order_data_sealogis_set_input!
    ) {
      update_order_data_sealogis(
        where: { orders: { id: { _eq: $id } } }
        _set: $data
      ) {
        returning {
          id
        }
      }
    }
  `,

  updateOrderDataSk: gql`
    mutation updateOrderDataSk($id: Int!, $data: order_data_sk_set_input!) {
      update_order_data_sk(
        where: { orders: { id: { _eq: $id } } }
        _set: $data
      ) {
        returning {
          id
        }
      }
    }
  `,

  updateVolume: gql`
    mutation updateOrderVolumeForBcu(
      $id: Int!
      $data: order_volumes_set_input!
      $where: String!
    ) {
      update_order_volumes(
        where: { order: { id: { _eq: $id } }, data_type: { _eq: $where } }
        _set: $data
      ) {
        returning {
          id
        }
      }
    }
  `,

  createFileRemitter: () => gql`
    mutation createFileRemitter(
      $fileRemitterPayload: [files_remitter_insert_input!]!
    ) {
      insert_files_remitter(objects: $fileRemitterPayload) {
        returning {
          name
          created_at
          location
          remitter_id
          file_type_id
          order_id
          id
        }
      }
    }
  `,
  createArticleFile: () => gql`
    mutation createArticleFile(
      $articleFilePayload: [article_order_files_insert_input!]!
    ) {
      insert_article_order_files(objects: $articleFilePayload) {
        returning {
          name
          u_name
          created_at
          id
        }
      }
    }
  `,
  deleteArticleFile: () => gql`
    mutation deleteArticleFile($id: Int!) {
      update_article_order_files(
        where: { id: { _eq: $id } }
        _set: { archived: true }
      ) {
        returning {
          article_orders_id
          id
        }
      }
    }
  `,
  deleteFileRemitter: () => gql`
    mutation deleteFileRemitter($id: Int!) {
      update_files_remitter(
        where: { id: { _eq: $id } }
        _set: { archived: true }
      ) {
        returning {
          id
        }
      }
    }
  `,
};

export default mutations;
