import { Button, Popconfirm, Tooltip } from "antd";
import React from "react";
import "./style.css";

const ConfirmButton = ({
  title,
  confirmTitle,
  confirm,
  loading,
  type,
  shape,
  icon,
  onClick,
  onCancel,
  disabled,
  ghost,
  tooltipTitle,
  danger,
  style,
}) => {
  const handleClick = () => {
    if (onClick) onClick();
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };
  return (
    <div /*className="button-container"*/>
      {disabled && (
        <Tooltip title={tooltipTitle || "Suppression impossible"}>
          <Button
            style={style}
            id={"delete-button"}
            disabled={disabled}
            ghost={ghost}
            type={type}
            shape={shape}
            icon={icon}
            loading={loading}
            danger={danger}
          >
            {title}
          </Button>
        </Tooltip>
      )}
      {!disabled && confirm && (
        <Popconfirm
          title={confirmTitle || "Êtes-vous sûr ?"}
          okText="Oui"
          cancelText="Non"
          onConfirm={handleClick}
          onCancel={handleCancel}
        >
          <Button
            id={"delete-button"}
            disabled={disabled}
            style={style}
            ghost={ghost}
            type={type}
            shape={shape}
            icon={icon}
            loading={loading}
            danger={danger}
          >
            {title}
          </Button>
        </Popconfirm>
      )}
      {!disabled && !confirm && (
        <Tooltip title={tooltipTitle}>
          <Button
            id={"delete-button"}
            disabled={disabled}
            ghost={ghost}
            type={type}
            style={style}
            shape={shape}
            icon={icon}
            loading={loading}
            onClick={handleClick}
            danger={danger}
          >
            {title}
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

export default ConfirmButton;
