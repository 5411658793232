import { Select } from "antd";

const { Option } = Select;

const SelectBooleanEdit = ({ hideOptions, strings, ...props }) => {
  const options = [
    { value: true, label: strings("yes") },
    { value: false, label: strings("no") },
  ];
  return (
    <Select {...props}>
      {options
        ?.filter(({ value }) =>
          hideOptions?.length > 0 ? !hideOptions.includes(value) : true,
        )
        ?.map(({ label, value }, i) => (
          <Option value={value} key={`${i}-${value}`}>
            {label}
          </Option>
        ))}
    </Select>
  );
};

export default SelectBooleanEdit;
