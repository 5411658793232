import Login from './Login';
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import {AuthActions} from "../../../redux/auth";
import {getTranslate} from "react-localize-redux";

const mapStateToProps = ({locale, auth}) => ({
  strings: getTranslate(locale),
  user_activated: auth.user_activated
})

const mapDispatchToProps = dispatch => ({
  navigateTo: (path, param) => dispatch(push(path, {param})),
  loginRequest: (username, password) => dispatch(AuthActions.loginRequest(username, password)),
  closeModale: () => dispatch(AuthActions.closeModaleUserDesactivated())
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);
