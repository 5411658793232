import { createReducer } from "reduxsauce";
import { omit } from "lodash";
import { types } from "./actions";
import { wrappers } from "../../utils/wrappers";
import { dateFormat, toMoment } from "../../utils/wrappers/dateWrapper";
import { get } from "lodash";

const initialState = {
  bls: null,
  sk_bls: [],
  initialOrderId: -1,
  hasNewOrder: false,
  current: null,
  bls_export: [],
  filesRemitter: [],
  filesNumberRemitter: [],
  clients: [],
  cities: [],
  types: [],
  states: [],
  fileType: [],
  filters: {
    client: "",
    asked_shipping_date: {
      firstDate: "",
      secondDate: "",
    },
    state: "",
    delivered: true,
    /*delivery_state: "",*/
    warehouse_status: "",
    /*delivery_status: "",*/
    edi_date: [],
    formatted_shipping_date: [],
    pickup_dates: [],
    dde_prep_dates: [],
    carrier_code: [],
    product_code: [],
    clients: [],
    formatted_asked_delivery_date: [],
    delivery_state: [],
    delivery_status: [],
    charter_references: [],
    asked_appointment_dates: [],
    appointment_dates: [],
  },
  sorter: {},
  pagination: {
    showSizeChanger: true,
    pageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
    current: 1,
  },
  total: 0,
  blSearch: "",
  importReports: [],
  nbFiles: 0,
  searchedOrders: [],
  searchedClients: [],
  nbSearchedClients: 0,
  signedUrl: "",
  sendFileSuccess: false,
  selectedIndex: -1,
  dataType: "", // optimea / SK
};

const RESPONSIBILITIES = {
  SEALOGIS: "SEALOGIS",
  OPTIMEA: "OPTIMEA",
  TRANSPORTEUR: "SEALOGIS",
  DESTINATAIRE: "OPTIMEA",
  XPLOG: "SEALOGIS",
  FOURNISSEUR: "OPTIMEA",
  "XPLOG / TRANSPORTEUR": "SEALOGIS",
  "XP LOG / OPTIMEA": "SEALOGIS",
  "CAS DE FORCE MAJEURE": "FORCE MAJEURE",
  "": null,
};

const formatOrderVolumes = (volumes) => {
  const { theoretical, log, real } = volumes.reduce((result, current) => {
    result[current.data_type] = current;
    return result;
  }, {});

  let last = real || log || theoretical;

  if ((real || log) && theoretical) {
    last.error = Object.keys(omit(last, "data_type")).filter((i) =>
      last[i] !== theoretical[i] && real && real[i]
        ? last[i] !== real[i]
        : false,
    );
  }

  return { all: { theoretical, log, real }, last };
};

const formatOrderClients = (clientOrders) => {
  const clients = {};
  clientOrders.forEach((co) => {
    const { client, data_type, client_code } = co;
    switch (true) {
      case client.party_function_code_qualifier === "DP":
        if (!clients[data_type])
          clients[data_type] = { ...client, client_code };
        break;
      case client.party_function_code_qualifier === "UC":
        clients[data_type] = { ...client, client_code };
        break;
      default:
        break;
    }
  });
  return clients;
};

const SetFilters = (state = initialState, filters) => {
  const newFilters = filters.filters || {};

  return { ...state, filters: newFilters };
};

const ResetFilters = (state = initialState) => ({
  ...state,
  filters: {
    client: "",
    asked_shipping_date: {
      firstDate: "",
      secondDate: "",
    },
    state: "",
    delivered: true,
    delivery_state: "",
    warehouse_status: "",
    delivery_status: "",
  },
});

const SetPagination = (state = initialState, action) => {
  const pagination = action.pagination || {};

  return { ...state, pagination };
};

const SetSorter = (state = initialState, action) => {
  const sorter = action.sorter || {};

  return { ...state, sorter };
};

const SetBlSearch = (state = initialState, action) => {
  const { blSearch } = action;

  return { ...state, blSearch };
};

const BlUpdateFailure = (state = initialState) => {
  return state;
};

const BlsSuccess = (state = initialState, action) => {
  const { values } = action;
  return {
    ...state,
    bls_export: values.bls_export,
    total: values.orders_aggregate.aggregate.count,
    clients: values.sealogis_remitters,
  };
};

const SetBls = (state = initialState, action) => {
  const { data } = action;
  const { bls: initialBls, initialOrderId } = state;
  let actualInitialOrderId = initialOrderId;
  let hasNewOrder = false;
  if (!initialBls?.length && data.length) {
    actualInitialOrderId = data[0].id;
  } else if (initialOrderId && data.length) {
    if (data[0].id > initialOrderId) {
      actualInitialOrderId = data[0].id;
      hasNewOrder = true;
    }
  }
  const bls = (data || []).map((order) => {
    let warehouse_status,
      carrier_status = "",
      tmp_carrier_status = "",
      order_state = order.order_state;
    const isCharter =
      order.charter_tracking !== null && !order.is_charter_canceled;
    let currentStep = 0;
    order.order_steps.forEach((orderStep) => {
      const round = orderStep.round + 1;
      if (round > currentStep) currentStep = round;
      const stepsFiltered =
        round > 1
          ? orderStep?.order_steps_partners || []
          : (orderStep?.order_steps_partners || []).filter((el) => {
              return el.flux_step_partner !== null;
            });
      const stepsLength = stepsFiltered.length;
      if (!stepsLength) return;
      const orderStepPartner = stepsFiltered[stepsLength - 1];
      if (orderStepPartner) {
        const fluxStepPartner =
          orderStepPartner.flux_step_partner ||
          orderStepPartner.partner ||
          null;
        if (fluxStepPartner) {
          const underStepsFiltered = orderStepPartner.under_order_steps.filter(
            (el) => {
              if (round > 1) return true;
              return !el.under_order_step_status?.sealogis_value.includes(
                "AFFRET",
              );
            },
          );
          const lastStep = underStepsFiltered[underStepsFiltered.length - 1];
          const partner = fluxStepPartner.partner_type
            ? fluxStepPartner
            : fluxStepPartner.partner;
          const status = lastStep?.under_order_step_status || {};
          if (order.order_state?.code === "IN_PROGRESS") {
            order_state = {
              ...order.order_state,
              sealogis_value: `${order.order_state?.sealogis_value} ${partner.partner_type?.code}`,
            };
          }
          switch (partner.partner_type?.code) {
            case "TPT":
              carrier_status = status;
              break;
            case "LOG":
              if (isCharter) {
                const charterPartner = (
                  orderStep?.order_steps_partners || []
                ).find((el) => {
                  return el.partner !== null;
                });
                if (charterPartner) {
                  const lastCharterUnderStep =
                    charterPartner.under_order_steps[
                      charterPartner.under_order_steps.length - 1
                    ];
                  tmp_carrier_status =
                    lastCharterUnderStep.under_order_step_status;
                }
              }
              warehouse_status = status;
              break;
            default:
              break;
          }
        }
      }
    });

    const length = currentStep;
    const realTotalStepsNumber =
      order.flux?.flux_steps_aggregate?.aggregate?.count || 1;
    const endedTotalStepsNumber = order.finishedSteps?.aggregate?.count ?? 0;
    const {
      carrier_name,
      carrier_code: bext_carrier_code,
      telia_carrier_code,
      date_shipping,
      product_code: bext_product_code,
    } = order.bext_trackings.length ? order.bext_trackings[0] : {};
    const {
      tracking_url,
      carrier_code,
      receipt_url,
      shipping_date,
      product_code,
    } = order.telia_trackings.length ? order.telia_trackings[0] : {};
    const {
      carrier_code: charter_carrier_code,
      product_code: charter_product_code,
      reference: charter_reference_tracking,
    } = order.charter_tracking || {};

    /*const [dp, uc] = order.client_orders.reduce(
      (result, { client }) => {
        switch (client.party_function_code_qualifier) {
          case "DP":
            result[0] = client;
            break;
          case "UC":
            result[1] = client;
            break;
          default:
            break;
        }
        return result;
      },
      [null, null],
    );*/
    const clients = formatOrderClients(
      order.original_order
        ? order.original_order.client_orders
        : order.client_orders,
    );
    const charterReferences = {
      sealogis: order.charter_reference,
      charter: charter_reference_tracking,
    };
    const commentaries = {
      sealogis_commentary: {
        value: order.sealogis_commentary,
        color: "blue",
      },
      all: {
        sealogis: {
          value: order.sealogis_commentary,
          color: "blue",
        },
        edi_two: {
          value: order.delivery_instruction_two,
          color: "purple",
        },
        edi_one: {
          value: order.delivery_instruction,
          color: "turquoise",
        },
      },
    };
    switch (true) {
      case order.sealogis_commentary?.length > 0:
        commentaries.commentary = commentaries.all.sealogis;
        break;
      case order.delivery_instruction_two?.length > 0:
        commentaries.commentary = commentaries.all.edi_two;
        break;
      case order.delivery_instruction?.length > 0:
        commentaries.commentary = commentaries.all.edi_one;
        break;
      default:
        break;
    }
    const ediFile = order.original_order?.edi_trackings.length
      ? order.original_order?.edi_trackings[0]
      : order.edi_trackings.length
      ? order.edi_trackings[0]
      : null;
    const ediDate = order.sorting_view
      ? dateFormat(order.sorting_view.receipt_date)
      : ediFile
      ? dateFormat(ediFile.receipt_date)
      : "-";

    const [appointmentDates, realDeliveryDates, askedAppointmentDates] = [
      "appointment_date",
      "real_delivery_date",
      "asked_appointment_date",
    ].map((key) => {
      const tracking = isCharter
        ? order.charter_tracking
        : order.telia_trackings?.length
        ? order.telia_trackings[0]
        : {};
      let trackingKey = key;
      if (tracking && isCharter && key === "real_delivery_date") {
        trackingKey = "delivery_date";
      }
      const ret = {
        teliae: {
          value: wrappers.dateFormat(tracking[trackingKey]),
          color: isCharter ? "turquoise" : "black",
          charterText: isCharter,
        },
        sealogis: {
          value: wrappers.dateFormat(get(order, key)),
          color: "blue",
        },
        current: { value: "", color: "black" },
      };
      ret.current = order[key] ? ret.sealogis : ret.teliae;
      return ret;
    });

    const delivery_status = {
      theoretical: {
        value: order["theoretical_delivery_status"],
        color: "black",
      },
      sealogis: {
        value: order["delivery_status"],
        color: "blue",
      },
      current: { value: "", color: "black" },
    };
    delivery_status.current = order["delivery_status"]
      ? delivery_status.sealogis
      : delivery_status.theoretical;

    const bl_num = order.original_order
      ? `${order.original_bl_num} / ${order.bl_num}`
      : order.bl_num;
    const teliaTracking = order.telia_trackings?.length
      ? order.telia_trackings[0]
      : null;

    const pickupDates = {
      sealogis: wrappers.dateFormat(order.pickup_date),
      charter:
        order.charter_tracking?.pickup_date && isCharter
          ? wrappers.dateFormat(order.charter_tracking?.pickup_date)
          : null,
    };
    const ddePrepDates = {
      sealogis: wrappers.dateFormat(order.dde_prep_date),
      charter:
        order.charter_tracking?.dde_prep_date && isCharter
          ? wrappers.dateFormat(order.charter_tracking?.dde_prep_date)
          : null,
    };
    return {
      ...order,
      bl_num,
      clients,
      order_state,
      carrier_take_over: wrappers.dateFormat(shipping_date),
      product_code: isCharter
        ? charter_product_code
        : product_code || bext_product_code || carrier_name,
      global_responsibility: RESPONSIBILITIES[order.responsibility?.code],
      edi_date: ediDate,
      appointment_date_charter: order.charter_tracking?.appointment_date
        ? wrappers.dateFormat(order.charter_tracking.appointment_date)
        : null,
      appointment_date_transport: teliaTracking?.appointment_date
        ? wrappers.dateFormat(teliaTracking.appointment_date)
        : null,
      appointment_date_sealogis: wrappers.dateFormat(order.appointment_date),
      real_delivery_date_charter: order.charter_tracking?.delivery_date
        ? wrappers.dateFormat(order.charter_tracking?.delivery_date)
        : null,
      real_delivery_date_transport: teliaTracking?.real_delivery_date
        ? wrappers.dateFormat(teliaTracking.real_delivery_date)
        : null,
      real_delivery_date_sealogis: wrappers.dateFormat(
        order.real_delivery_date,
      ),
      real_delivery_dates: realDeliveryDates,
      delivery_status: delivery_status,
      asked_appointment_date_charter: order.charter_tracking
        ?.asked_appointment_date
        ? wrappers.dateFormat(order.charter_tracking.asked_appointment_date)
        : null,
      asked_appointment_date_sealogis: wrappers.dateFormat(
        order.asked_appointment_date,
      ),
      formatted_shipping_date: wrappers.dateFormat(date_shipping),
      formatted_pickup_date: wrappers.dateFormat(order.pickup_date),
      asked_appointment_dates: askedAppointmentDates,
      pickup_dates: pickupDates,
      dde_prep_dates: ddePrepDates,
      appointment_dates: appointmentDates,
      charter_references: charterReferences,
      formatted_asked_delivery_date: wrappers.dateFormat(
        order.asked_delivery_date,
      ),
      warehouse_status: warehouse_status,
      carrier_status:
        carrier_status.length || carrier_status?.id
          ? carrier_status
          : tmp_carrier_status,
      formatted_dde_prep_date: wrappers.dateFormat(order.dde_prep_date),
      carrier_code: isCharter
        ? charter_carrier_code
        : carrier_code || telia_carrier_code || bext_carrier_code,
      carrier_tracking_url: tracking_url,
      volumes: formatOrderVolumes(order.order_volumes),
      receipt_url,
      commentaries,
      steps_length: length,
      steps_progress: {
        total_steps: realTotalStepsNumber,
        progress: endedTotalStepsNumber,
        percent: (endedTotalStepsNumber / realTotalStepsNumber) * 100,
      },
      status_code: order.order_state,
    };
  });

  return {
    ...state,
    bls,
    sk_bls: [],
    initialOrderId: actualInitialOrderId,
    hasNewOrder,
    dataType: "optimea",
  };
};

const edi_types = {
  entry: [
    "BEXT",
    "HANMOV",
    "TELIA",
    "COLPREP",
    "LIGPREP",
    "BEXT_STATUS",
    "MANUAL_ORDER_FILE",
    "CHARTER_FILE",
  ],
  exit: ["DESADV", "CCENT", "CCLIG"],
};

const SetSKBls = (state = initialState, action) => {
  const { data } = action;
  const bls = data.map((d) => {
    const { order_data_bcu, order_data_sealogis } = d;
    d.volumes = formatOrderVolumes(
      d.order_volumes.map((el) => {
        const { data_type, ...rest } = el;
        let dataTypeKey = data_type;
        if (data_type === "bcu") {
          dataTypeKey = "real";
        } else if (data_type === "sealogis") {
          dataTypeKey = "log";
        }
        return {
          ...rest,
          data_type: dataTypeKey,
        };
      }),
    );
    d.delivery_codes = {
      classical: d.delivery_code,
      sealogis: order_data_sealogis?.delivery_code,
    };
    d.bl_nums = { bl_num: d.bl_num, sealogis: order_data_bcu?.shipment_number };
    if (d.client_orders?.length) {
      const clientOrder = d.client_orders[0];
      const { client } = clientOrder;
      const { address } = client;
      d.client_remitter = {
        code: clientOrder.client_code,
        name: client.name,
        delivery_mode: client.delivery_mode,
        addr1: address?.addr1,
        city: address?.city,
        country_code: address?.country_code,
        zip_code: address?.zip_code,
      };
    }
    d.removal_dates = {
      bcu: dateFormat(order_data_bcu?.removal_date, "dd/MM/yy"),
      bcuRaw: dateFormat(order_data_bcu?.removal_date, "dd/MM/yy HH:mm"),
      sealogis: dateFormat(order_data_sealogis?.removal_date, "dd/MM/yy"),
      sealogisRaw: dateFormat(
        order_data_sealogis?.removal_date,
        "dd/MM/yy HH:mm",
      ),
    };
    d.removal_date_hours = {
      bcu: dateFormat(order_data_bcu?.removal_date_hours, "HH:mm"),
      bcuRaw: order_data_bcu?.removal_date_hours
        ? toMoment(order_data_bcu.removal_date_hours)
        : null,
      sealogis: dateFormat(order_data_sealogis?.removal_date_hours, "HH:mm"),
      sealogisRaw: order_data_sealogis?.removal_date_hours
        ? toMoment(order_data_sealogis.removal_date_hours)
        : null,
    };
    d.formatted_asked_delivery_date = dateFormat(d.asked_delivery_date);
    d.formatted_bcu_asked_delivery_date = dateFormat(
      order_data_bcu?.asked_delivery_date,
    );
    d.formatted_real_delivery_date = dateFormat(d.real_delivery_date);
    d.bcu_removal_location_city =
      order_data_bcu?.bcu_removal_location_city || {};
    return d;
  });
  return { ...state, bls: null, sk_bls: bls, dataType: "SK" };
};

const BlSuccess = (state = initialState, action) => {
  const { values, edi_calcul } = action;

  let order = values.orders[0];
  const { telia_carrier_code } = order.bext_trackings.length
    ? order.bext_trackings[0]
    : {};
  const { carrier_code, appointment_date } = order.telia_trackings.length
    ? order.telia_trackings[0]
    : {};
  const { product_code: charter_carrier_code } = order.charter_tracking || {};
  const volumes = formatOrderVolumes(order.order_volumes);
  const { date_edi, edi_delay } = edi_calcul;
  //eslint-disable-next-line
  let carrier_status = "",
    warehouse_status = "";
  const isCharter =
    order.charter_tracking !== null && !order.is_charter_canceled;
  order.order_steps = order.order_steps.map((os) => {
    const orderStepsPartners = os.order_steps_partners;
    if (orderStepsPartners.length) {
      const orderStepPartner = orderStepsPartners[0];
      const fluxStepPartner = orderStepPartner.flux_step_partner || null;
      const partnerBypass = orderStepPartner.partner || null;
      const partner = partnerBypass || fluxStepPartner.partner;
      if (fluxStepPartner || partnerBypass) {
        const lastStep =
          orderStepPartner.under_order_steps[
            orderStepPartner.under_order_steps.length - 1
          ];
        const status = lastStep?.under_order_step_status || {};
        switch (partner.partner_type?.code) {
          case "TPT":
            carrier_status = status;
            break;
          case "LOG":
            warehouse_status = status;
            break;
          default:
            break;
        }
      }
      const partnerType = partner?.partner_type?.code;
      switch (partnerType) {
        case "TPT":
          os.order_steps_partners[0].volumes = {
            ...volumes,
            current: volumes.all.real,
          };
          const tracking = order.telia_trackings;
          if (tracking.length) {
            os.order_steps_partners[0].carrier_tracking_url =
              tracking[0].tracking_url;
            os.order_steps_partners[0].receipt_url = tracking[0].receipt_url;
            os.order_steps_partners[0].real_delivery_date = wrappers.dateFormat(
              tracking[0].real_delivery_date,
            );
            os.order_steps_partners[0].appointment_date = wrappers.dateFormat(
              tracking[0].appointment_date,
            );
          } else if (isCharter && order.charter_tracking) {
            os.order_steps_partners[0].real_delivery_date = wrappers.dateFormat(
              order.charter_tracking.delivery_date,
            );
            os.order_steps_partners[0].appointment_date = wrappers.dateFormat(
              order.charter_tracking.appointment_date,
            );
          }
          break;
        case "LOG":
          os.order_steps_partners[0].volumes = {
            ...volumes,
            current: volumes.all.edi,
          };
          break;
        default:
          break;
      }
    }
    return os;
  });
  /*order.percent =  (order.order_steps_aggregate.aggregate.count / order.client.flux.flux_steps_aggregate.aggregate.count) * 100*/
  const percent =
    (order.order_steps.filter((el) => el.order_step_statut.name === "Livre")
      .length /
      order.client.flux.flux_steps_aggregate.aggregate.count) *
    100;
  order.percent = percent;
  order.progress = {
    progressSteps: order.order_steps_aggregate.aggregate.count,
    totalSteps: order.client.flux.flux_steps_aggregate.aggregate.count,
    percent,
  };
  order.hasNoSteps = order.order_state?.code === "RECEIVED" && percent === 0;

  const clients = formatOrderClients(
    order.original_order?.client_orders || order.client_orders || [],
  );
  /*const order_parties = order?.order_parties;
  const client_orders = order?.client_orders;*/
  /*const SU = order_parties.find(
      (op) => op.party_function_code_qualifier === "SU",
    ),
    DPs = client_orders.filter(
      (op) => op.client.party_function_code_qualifier === "DP",
    ),
    UCs = client_orders.filter(
      (op) => op.client.party_function_code_qualifier === "UC",
    );*/
  /*const DP =
    DPs.find((op) => op.data_type === "real") ||
    DPs.find((op) => op.data_type === "edi");
  const UC =
    UCs.find((op) => op.data_type === "real") ||
    DPs.find((op) => op.data_type === "edi");
  const client = UC?.client || DP?.client;
  order.expediteur = {
    s_shipper_code: SU?.sealogis_remitter?.code,
    s_shipper_name: SU?.sealogis_remitter?.name,
  };
  order.destinataire = {
    s_shipper_code: SU?.sealogis_remitter?.code,
    s_shipper_name: SU?.sealogis_remitter?.name,
    r_consignee_name: client?.name,
    r_consignee_addr1: client?.address?.addr1,
    r_consignee_addr2: client?.address?.addr2,
    r_consignee_zip: client?.address?.zip_code,
    r_consignee_city: client?.address?.city,
  };
  order.expedition = {
    s_shipper_code: SU?.sealogis_remitter?.code,
    r_consignee_name: client?.name,
    r_consignee_addr1: client?.address?.addr1,
    r_consignee_addr2: client?.address?.addr2,
    r_consignee_zip: client?.address?.zip_code,
    r_consignee_city: client?.address?.city,
    delivery_date: order?.asked_delivery_date,
  };
  order.commande = {
    o_bl_number: order.bl_num,
    delivery_date: order?.asked_delivery_date,
    theoretical_weight: order?.theoretical_weight,
    mesured_weight: order?.mesured_weight,
  };*/

  order.warehouse_status = warehouse_status;
  order.formatted_asked_delivery_date = wrappers.dateFormat(
    order.asked_delivery_date,
  );
  order.carrier_code = isCharter
    ? charter_carrier_code
    : carrier_code || telia_carrier_code;
  order.date_edi = wrappers.dateFormat(date_edi);
  order.edi_delay = edi_delay;
  order.formatted_appointment_date = wrappers.dateFormat(appointment_date);
  order.volumes = volumes;
  order.clients = clients;
  const ediFile = order.edi_trackings.find((el) => el.type === "HANMOV");
  order.edi_date = order.sorting_view
    ? dateFormat(order.sorting_view.receipt_date)
    : ediFile
    ? dateFormat(ediFile.receipt_date)
    : "-";
  order.bl_num = !order.original_order
    ? order.bl_num
    : `${order.original_bl_num} / ${order.bl_num}`;
  order.edi_trackings = [
    ...(order.original_order?.edi_trackings || []),
    ...order.edi_trackings,
  ].map(({ type: oldType, created_at: date, ...rest }) => {
    let type = "";
    const disabled = wrappers.daysFromNow(date) > 30;
    const created_at = wrappers.dateFormat(date, "dd/MM/yy HH:mm");
    Object.keys(edi_types).forEach((item) => {
      if (edi_types[item].includes(oldType)) type = item;
    });
    return { ...rest, created_at, type, oldType, disabled };
  });
  let nbFiles = 0;
  order.article_orders = order.article_orders?.map(({ files, ...rest }) => ({
    ...rest,
    files: files?.map(({ created_at, ...rest }) => {
      nbFiles += 1;
      return {
        ...rest,
        created_at: wrappers.dateFormat(created_at, "dd/MM/yy HH:mm"),
      };
    }),
  }));

  return {
    ...state,
    nbFiles,
    current: order,
    types: values.partner_type,
  };
};

const BlUpdateSuccess = (state = initialState, action) => {
  const { values } = action;
  const { id: blId, ...rest } = values;
  const bls = state.bls || state.sk_bls;
  const index = bls.findIndex(({ id }) => id === blId);
  if (index > -1) {
    const bl = bls[index];
    if (values.sealogis_commentary) {
      const commentaries = {
        sealogis_commentary: {
          value: values.sealogis_commentary,
          color: "blue",
        },
        commentary: { value: values.sealogis_commentary, color: "blue" },
        all: {
          sealogis: {
            value: values.sealogis_commentary,
            color: "blue",
          },
          edi_two: {
            value: bl.delivery_instruction_two,
            color: "purple",
          },
          edi_one: {
            value: bl.delivery_instruction,
            color: "turquoise",
          },
        },
      };
      rest.commentaries = commentaries;
    }
    const newBl = { ...bls[index], ...rest };
    bls.splice(index, 1, newBl);
    return { ...state, bls: [...bls] };
  }
  return state;
};

const BlClear = (state = initialState, action) => {
  return { ...state, current: null, nbFiles: 0 };
};

const SetImportReports = (state = initialState, action) => {
  return { ...state, importReports: action.reports };
};

const createFileRemitterSuccess = (state = initialState, action) => {
  const {
    toAdd: { created_at, ...toAdd },
  } = action;
  const file = {
    ...toAdd,
    created_at: dateFormat(created_at, "dd/MM/yy HH:mm"),
  };
  return { ...state, filesRemitter: [...state.filesRemitter, file] };
};

const deleteFileRemitterSuccess = (state = initialState, action) => {
  const { fileId } = action;
  return {
    ...state,
    filesRemitter: state.filesRemitter?.filter((file) => file.id !== fileId),
  };
};

const getFilesRemitterSuccess = (state = initialState, action) => {
  const { values } = action;
  return {
    ...state,
    filesRemitter: [
      ...values?.map(({ created_at, ...rest }) => {
        return {
          ...rest,
          created_at: wrappers.dateFormat(created_at, "dd/MM/yy HH:mm"),
        };
      }),
    ],
  };
};

const createArticleFileSuccess = (state = initialState, action) => {
  const {
    id,
    toAdd: { created_at, ...toAdd },
  } = action;
  const { current } = state;
  const old = current?.article_orders;
  const file = {
    ...toAdd,
    deletable: true,
    created_at: dateFormat(created_at, "dd/MM/yy HH:mm"),
  };
  const articles = old.map((item) => {
    const { files, ...article } = item;
    return article.id === id ? { ...article, files: [file, ...files] } : item;
  });
  const newCurrent = { ...current, article_orders: articles };
  return { ...state, current: newCurrent };
};

const deleteArticleFileSuccess = (state = initialState, action) => {
  const { fileId, articleId } = action;
  const { current } = state;
  const old = current?.article_orders;
  const articles = old.map((item) => {
    const { files, ...article } = item;
    return article.id === articleId
      ? { ...article, files: files.filter(({ id }) => id !== fileId) }
      : item;
  });
  const newCurrent = { ...current, article_orders: articles };
  return { ...state, current: newCurrent };
};

const searchForClientSuccess = (state = initialState, action) => {
  const { clients, nbTotalClients } = action;
  return {
    ...state,
    searchedClients: clients,
    nbSearchedClients: nbTotalClients,
  };
};

const searchForOrdersSuccess = (state = initialState, action) => {
  const { orders } = action;
  return {
    ...state,
    searchedOrders: orders,
  };
};

const searchForClientReset = (state = initialState) => {
  return {
    ...state,
    searchedClients: [],
    nbSearchedClients: 0,
  };
};

const getSignedURLSuccess = (state = initialState, action) => {
  const { url } = action;
  return { ...state, signedUrl: url };
};

const resetSignedURL = (state = initialState, action) => {
  return { ...state, signedUrl: "" };
};

const uploadPODSuccess = (state = initialState, action) => {
  const { pod_file, id } = action;
  let newBl = {};
  const orders = [...get(state, ["bl", "bls"], [])].map((bl) => {
    if (get(bl, "id", -1) === id) {
      newBl = { ...bl, pod_file: pod_file };
      return newBl;
    }
    return bl;
  });
  return { ...state, bls: orders, sendFileSuccess: true };
};

const resetSendFileSuccess = (state = initialState, action) => {
  return { ...state, sendFileSuccess: false };
};

const setSelectedIndex = (state = initialState, action) => {
  const { index } = action;
  return {
    ...state,
    selectedIndex: index,
  };
};

const getFileTypeSuccess = (state = initialState, action) => {
  const { values } = action;
  return { ...state, fileType: values };
};

const getNumberFilesRemitterSuccess = (state = initialState, action) => {
  const { values } = action;
  const val = {
    arrivage: values[0] ? values[0].arrivage.aggregate.count : 0,
    kpi: values[0] ? values[0].kpi.aggregate.count : 0,
    stock: values[0] ? values[0].stock.aggregate.count : 0,
    pricelist: values[0] ? values[0].pricelist.aggregate.count : 0,
  };
  return { ...state, filesNumberRemitter: val };
};
export default createReducer(initialState, {
  [types.BLS_SUCCESS]: BlsSuccess,
  [types.SET_BLS]: SetBls,
  [types.SET_SKBLS]: SetSKBls,
  [types.BL_SUCCESS]: BlSuccess,
  [types.SET_FILTERS]: SetFilters,
  [types.RESET_FILTERS]: ResetFilters,
  [types.SET_PAGINATION]: SetPagination,
  [types.BL_UPDATE_SUCCESS]: BlUpdateSuccess,
  [types.BL_UPDATE_SUCCESS]: BlUpdateFailure,
  [types.SET_BL_SEARCH]: SetBlSearch,
  [types.BL_CLEAR]: BlClear,
  [types.SET_SORTER]: SetSorter,
  [types.SET_IMPORT_REPORTS]: SetImportReports,
  [types.CREATE_FILE_REMITTER_SUCCESS]: createFileRemitterSuccess,
  [types.DELETE_FILE_REMITTER_SUCCESS]: deleteFileRemitterSuccess,
  [types.CREATE_ARTICLE_FILE_SUCCESS]: createArticleFileSuccess,
  [types.DELETE_ARTICLE_FILE_SUCCESS]: deleteArticleFileSuccess,
  [types.SEARCH_FOR_ORDERS_SUCCESS]: searchForOrdersSuccess,
  [types.SEARCH_FOR_CLIENT_SUCCESS]: searchForClientSuccess,
  [types.SEARCH_FOR_CLIENT_RESET]: searchForClientReset,
  [types.GET_SIGNED_URL_SUCCESS]: getSignedURLSuccess,
  [types.RESET_SIGNED_URL]: resetSignedURL,
  [types.UPLOAD_POD_SUCCESS]: uploadPODSuccess,
  [types.RESET_POD_SEND_FILE]: resetSendFileSuccess,
  [types.SET_SELECTED_INDEX]: setSelectedIndex,
  [types.GET_FILE_TYPE_SUCCESS]: getFileTypeSuccess,
  [types.GET_FILES_REMITTER_SUCCESS]: getFilesRemitterSuccess,
  [types.GET_NUMBER_FILE_TYPE_SUCCESS]: getNumberFilesRemitterSuccess,
});
