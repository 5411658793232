import { createSelector } from "reselect";

export const selectOrderStates = createSelector(
  ({ status }) => status.order_states,
  (status) => {
    const statusWithoutAffret = status.filter(
      (el) => el?.code !== "AFFRET_EMPTY",
    );
    return statusWithoutAffret;
    /*const indexInProgress = statusWithoutAffret?.findIndex((e) => e.code === "IN_PROGRESS");
      const statusReformat = [
        ...statusWithoutAffret?.slice(0, indexInProgress),
        {
            id: 30,
            name: "En cours LOG",
            sealogis_value: "En cours LOG",
            code: "IN_PROGRESS_LOG",
        },
        {
            id: 31,
            name: "En cours TPT",
            sealogis_value: "En cours TPT",
            code: "IN_PROGRESS_TPT",
        },
        ...statusWithoutAffret?.slice(indexInProgress + 1, statusWithoutAffret?.length - 1),
      ]
      return statusReformat;*/
  },
);

export const selectDeliveryStatus = createSelector(
  ({ status }) => status.delivery_status,
  (status) => status,
);

export const selectDeliveryStatusForFilter = createSelector(
  ({ status }) => status.delivery_status,
  (status) =>
    [...status, { code: "Vide", id: -1 }].map((el) => ({
      text: el.code,
      value: el.id,
    })),
);

export const selectDeliveryStates = createSelector(
  ({ status }) => status.delivery_states,
  (status) => status,
);

export const selectDeliveryStatesForFilters = createSelector(
  ({ status }) => status.delivery_states,
  (status) =>
    [...status, { code: "Vide", id: -1 }].map((el) => ({
      text: el.code,
      value: el.id,
    })),
);

export const selectDisputeReason = createSelector(
  ({ status }) => status.dispute_reasons,
  (status) => status,
);

export const selectDelayReason = createSelector(
  ({ status }) => status.delay_reasons,
  (status) => status,
);

export const selectResponsibility = createSelector(
  ({ status }) => status.responsibilities,
  (status) => status,
);

export const selectWarehouseStatus = createSelector(
  ({ status }) => status.warehouseStatus,
  (status) => status,
);

export const selectOffsetPatternPickup = createSelector(
  ({ status }) => status.sk.offset_pattern_pickup,
  (offsetPatternPickup) =>
    offsetPatternPickup.map((el) => ({ ...el, code: el.sealogis_code })),
);

export const selectShipmentStatus = createSelector(
  ({ status }) => status.sk.shipment_status,
  (shipment_status) =>
    shipment_status.map((el) => ({ ...el, code: el.sealogis_code })),
);

export const selectExtraCostReasonStart = createSelector(
  ({ status }) => status.sk.extra_cost_reason_start,
  (extraCostReasonStart) =>
    extraCostReasonStart.map((el) => ({ ...el, code: el.sealogis_code })),
);

export const selectExtraCostReasonDestination = createSelector(
  ({ status }) => status.sk.extra_cost_reason_destination,
  (extraCostReasonDestination) =>
    extraCostReasonDestination.map((el) => ({
      ...el,
      code: el.sealogis_code,
    })),
);

export const selectSKResponsibilities = createSelector(
  ({ status }) => status.sk.responsibilities_sk,
  (responsibilities) =>
    responsibilities.map((el) => ({
      ...el,
      code: el.sealogis_code,
    })),
);
