import React from "react";
import { Progress } from "antd";

const ProgressFlux = ({ progress, strings }) => {
  const { totalSteps, progressSteps, percent, state } = progress;

  const status = state === "toVerify" ? "exception" : "normal";

  return (
    <div>
      <Progress
        type="circle"
        percent={percent}
        style={{ marginRight: 15 }}
        status={percent >= 100 ? "success" : status}
        width={30}
      />
      {`${strings("step")} - ${progressSteps}/${totalSteps}`}
    </div>
  );
};

export default ProgressFlux;
