import { Form, Modal, Popover } from "antd";
import SelectEdit from "../EditionComponent/SelectEdit";
import {
  selectDelayReason,
  selectDisputeReason,
  selectResponsibility,
} from "../../../../redux/status/selector";
import "./style.css";
import TextEdit from "../EditionComponent/TextEdit";
import SimpleText from "../EditionComponent/SimpleText";
import { useContext, useEffect, useMemo, useState } from "react";
import { BlActions } from "../../../../redux/bl";
import { useDispatch } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import { pick } from "lodash";
import { AbilityContext } from "../../../../utils/casl/v2/can";

const types = {
  SELECT: "select",
  TEXT: "text",
};

const components = {
  [types.SELECT]: (props) => <SelectEdit allowClear {...props} />,
  [types.TEXT]: (props) => <TextEdit {...props} />,
};

const Component = ({
  type,
  isBlockedByMultiEdition,
  strings,
  onEditField = () => {},
  name,
  ...childrenProps
}) => {
  const Comp = components[type];

  const handleClick = (e) => {
    onEditField && onEditField(name);
  };

  /*const handleBlur = (e) => {
    if (type === types.TEXT) {
      onEditField && onEditField(name, -1);
    }
  };*/

  return (
    <Popover
      title={isBlockedByMultiEdition ? strings("edit_blocked_title") : false}
      content={
        isBlockedByMultiEdition ? strings("edit_blocked_multi_content") : false
      }
    >
      <Comp
        {...childrenProps}
        disabled={isBlockedByMultiEdition}
        onClick={handleClick}
      />
    </Popover>
  );
};

const EditModal = ({
  strings,
  values,
  onClose = () => {},
  onEditField = () => {},
  isEdit,
}) => {
  const [isFooter, setIsFooter] = useState({ footer: null });
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [inEditFields, setInEditFields] = useState([]);
  const dispatch = useDispatch();
  const canCtx = useContext(AbilityContext);
  const canWrite = canCtx.can("write_edit_modal", "all_columns");

  const preventClose = useMemo(
    () => !isFooter.hasOwnProperty("footer"),
    [isFooter],
  );

  useEffect(() => {
    setIsFooter({ footer: null });
  }, [values?.id]);

  useEffect(() => {
    if (!values && inEditFields.length) setInEditFields([]);
    else if (values) {
      const formValues = form.getFieldsValue();
      const {
        dispute_reason: formDisputeReason,
        delay_reason: formDelayReason,
        responsibility: formResponsibility,
        nc_details: formNcDetails,
      } = formValues || {};
      const {
        dispute_reason,
        delay_reason,
        responsibility,
        nc_details,
        ...rest
      } = values || {};
      const disputeValue = inEditFields.includes("dispute_reason")
        ? formDisputeReason
        : dispute_reason?.code;
      const delayValue = inEditFields.includes("delay_reason")
        ? formDelayReason
        : delay_reason?.code;
      const responsibilityValue = inEditFields.includes("responsibility")
        ? formResponsibility
        : responsibility?.code;
      const ncDetailsValue = inEditFields.includes("nc_details")
        ? formNcDetails
        : nc_details;
      form.setFieldsValue({
        dispute_reason: disputeValue,
        delay_reason: delayValue,
        responsibility: responsibilityValue,
        nc_details: ncDetailsValue,
        ...rest,
      });
    }
    //eslint-disable-next-line
  }, [values]);

  useEffect(() => {
    if (isEdit.index === -1 && inEditFields.length) {
      if (values?.id) {
        form.setFieldsValue({
          ...values,
          dispute_reason: values.dispute_reason?.code,
          delay_reason: values.delay_reason?.code,
          responsibility: values.responsibility?.code,
        });
      }
      setInEditFields([]);
    }
    //eslint-disable-next-line
  }, [isEdit]);

  const requestCallback = () => {
    setLoading(false);
    onEditField(inEditFields, -1);
    onClose();
  };

  const handleClose = (e) => {
    if (e.currentTarget.id !== "discardButton" && preventClose) {
      return;
    }
    onEditField(inEditFields, -1);
    onClose();
  };

  const handleFinish = async () => {
    const data = await form.getFieldsValue();

    if (dispatch) {
      setLoading(true);
      dispatch(
        BlActions.blUpdate(
          {
            id: values.id,
            ...pick(data, [
              "dispute_reason",
              "delay_reason",
              "nc_details",
              "responsibility",
            ]),
          },
          {
            onSuccess: requestCallback,
            onFailure: requestCallback,
          },
        ),
      );
    }
  };

  const handleChange = () => {
    setIsFooter({});
  };

  const handleOnEdit = (name, index) => {
    /*onEditField(name, index === -1 ? index : undefined)*/

    const edited = [...inEditFields];
    if (!edited.includes(name)) {
      edited.push(name);
      setInEditFields(edited);
      onEditField(edited, index === -1 ? index : undefined);
    }
  };

  const items = [
    {
      name: "dispute_reason",
      label: "dispute_reason",
      type: types.SELECT,
      childrenProps: {
        strings,
        selector: selectDisputeReason,
        disabled: !canWrite,
        onClear: (e) => {
          if (!inEditFields.includes("dispute_reason"))
            handleOnEdit("dispute_reason");
        },
        isBlockedByMultiEdition:
          (values?.fields_in_edition || []).includes("dispute_reason") &&
          !inEditFields.includes("dispute_reason"),
      },
    },
    {
      name: "delay_reason",
      label: "delay_reason",
      type: types.SELECT,
      childrenProps: {
        strings,
        selector: selectDelayReason,
        disabled: !canWrite,
        onClear: (e) => {
          if (!inEditFields.includes("delay_reason"))
            handleOnEdit("delay_reason");
        },
        isBlockedByMultiEdition:
          (values?.fields_in_edition || []).includes("delay_reason") &&
          !inEditFields.includes("delay_reason"),
      },
    },
    {
      name: "nc_details",
      label: "nc_details",
      type: types.TEXT,
      childrenProps: {
        disabled: !canWrite,
        onClear: (e) => {
          if (!inEditFields.includes("nc_details")) handleOnEdit("nc_details");
        },
        isBlockedByMultiEdition:
          (values?.fields_in_edition || []).includes("nc_details") &&
          !inEditFields.includes("nc_details"),
      },
    },
    {
      name: "responsibility",
      label: "responsibility",
      type: types.SELECT,
      childrenProps: {
        strings,
        selector: selectResponsibility,
        disabled: !canWrite,
        onClear: (e) => {
          if (!inEditFields.includes("responsibility"))
            handleOnEdit("responsibility");
        },
        isBlockedByMultiEdition:
          (values?.fields_in_edition || []).includes("responsibility") &&
          !inEditFields.includes("responsibility"),
      },
    },
    {
      name: "global_responsibility",
      label: "global_responsibility",
      type: types.TEXT,
      childrenProps: {
        disabled: true,
        className: "hide-input",
      },
    },
    {
      label: "client_cde",
      name: "br_num",
      type: types.TEXT,
      childrenProps: {
        disabled: true,
        className: "hide-input",
      },
    },
    {
      label: "preparation_delay",
      name: "del_prep",
      type: types.TEXT,
      childrenProps: {
        disabled: true,
        className: "hide-input",
      },
    },
    {
      label: "delivery_delay",
      name: "del_liv",
      type: types.TEXT,
      childrenProps: {
        disabled: true,
        className: "hide-input",
      },
    },
    {
      label: "delivery_delay_jo",
      name: "del_liv_jo",
      type: types.TEXT,
      childrenProps: {
        disabled: true,
        className: "hide-input",
      },
    },
    {
      label: "delivery_eca",
      name: "eca_liv",
      type: types.TEXT,
      childrenProps: {
        disabled: true,
        className: "hide-input",
      },
    },
    {
      label: "delivery_asked_eca",
      name: "asked_eca_liv",
      type: types.TEXT,
      childrenProps: {
        disabled: true,
        className: "hide-input",
      },
    },
    {
      label: "carrier_take_over",
      name: "carrier_take_over",
      type: types.TEXT,
      childrenProps: {
        disabled: true,
        className: "hide-input",
      },
    },
  ];

  return (
    <Modal
      open={!!values}
      closable
      title={strings("sup_info_m")}
      onCancel={handleClose}
      {...isFooter}
      okButtonProps={{ loading }}
      cancelButtonProps={{ id: "discardButton" }}
      closeIcon={
        <Popover
          title={preventClose ? strings("warning") : false}
          content={preventClose ? strings("save_your_data") : false}
        >
          <CloseOutlined id="close-button" onClick={() => {}} />
        </Popover>
      }
      okText={strings("save")}
      maskClosable={false}
      bodyStyle={{ padding: 0 }}
      onOk={handleFinish}
      cancelText={strings("cancel")}
    >
      <div className="form-container">
        <Form layout="vertical" form={form} onValuesChange={handleChange}>
          {items.map(({ label, name, type, childrenProps }, index) => (
            <Form.Item
              label={`${strings(label)}:`}
              name={name}
              key={`edit-modal-form-item-${index}`}
            >
              {!childrenProps?.disabled ? (
                <Component
                  type={type}
                  name={name}
                  strings={strings}
                  onEditField={handleOnEdit}
                  {...childrenProps}
                />
              ) : (
                <SimpleText />
              )}
            </Form.Item>
          ))}
        </Form>
      </div>
    </Modal>
  );
};

export default EditModal;
