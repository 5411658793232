import { push } from "connected-react-router";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";

import { AuthActions } from "../../../redux/auth";
import AuthLayout from "./AuthLayout";

const mapStateToProps = ({ locale, bl }) => ({
  strings: getTranslate(locale),
  bl: bl,
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path) => dispatch(push(path)),
  logout: () => dispatch(AuthActions.logout()),
  getCurrentUserRequest: (id) => dispatch(AuthActions.getCurrentUserRequest({id}))
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);
