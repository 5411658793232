import { gql } from "@apollo/client";

const queries = {
  user: () => gql`
    users {
      renew_pwd
    }
  `,
  userByEmail: () => gql`
    query ($email: String!) {
      users(where: { email: { _eq: $email } }) {
        id
        email
        user_role {
          role
        }
        role_sealogis_remitters_users {
          role_sealogis_remitter {
            sealogis_remitter {
              id
              code
              name
            }
            user_role {
              id
              role
            }
          }
        }
        cgu
        privacy_policy
        renew_pwd
        activated
      }
    }
  `,
  userById: () => gql`
    query ($id: Int!) {
      users(where: { id: { _eq: $id } }) {
        id
        email
        role
        cgu
        privacy_policy
        renew_pwd
        activated
        first_name
        user_role {
          role
        }
        role_sealogis_remitters_users {
          role_sealogis_remitter {
            sealogis_remitter {
              id
              code
              name
            }
            user_role {
              id
              role
            }
          }
        }
        role_sealogis_remitters_users {
          role_sealogis_remitter {
            user_role {
              role
            }
          }
        }
      }
    }
  `,
};

export default queries;
