import { call, put, select, takeLatest } from "redux-saga/effects";
import { types } from "./actions";
import { getTranslate } from "react-localize-redux";
import { showError } from "../../utils/notifications-helper";
import { DataActions } from "./index";
import { DataService } from "../../services";

function* bcuDataRequest() {
  const [error, response] = yield call(DataService.bcuData);
  if (error) {
    const locale = yield select((state) => state.locale);
    const strings = getTranslate(locale);
    showError("bcu_data_failed", strings);
  } else if (response) {
    yield put(DataActions.bcuDataSuccess(response.bcu_removal_location_cities));
  }
}

function* typeFluxRequest() {
  const [error, response] = yield call(DataService.typeFlux);
  if (error) {
    const locale = yield select((state) => state.locale);
    const strings = getTranslate(locale);
    showError("type_flux_failed", strings);
  } else if (response) {
    yield put(DataActions.typeFluxSuccess(response.type_flux_sk));
  }
}

function* xcStatusRequest() {
  const [error, response] = yield call(DataService.xcStatus);
  if (error) {
    const locale = yield select((state) => state.locale);
    const strings = getTranslate(locale);
    showError("xc_status_failed", strings);
  } else if (response) {
    yield put(DataActions.xcStatusSuccess(response.xc_status));
  }
}

function* partnerCarrierRequest() {
  const [error, response] = yield call(DataService.partnerCarrierRequest);
  if (error) {
    const locale = yield select((state) => state.locale);
    const strings = getTranslate(locale);
    showError("partner_carrier_failed", strings);
  } else if (response) {
    yield put(DataActions.partnerCarrierSuccess(response.partners));
  }
}

const sagas = [
  takeLatest(types.BCU_DATA_REQUEST, bcuDataRequest),
  takeLatest(types.PARTNER_CARRIER_REQUEST, partnerCarrierRequest),
  takeLatest(types.TYPE_FLUX_REQUEST, typeFluxRequest),
  takeLatest(types.XC_STATUS_REQUEST, xcStatusRequest),
];

export default sagas;
