import { useSelector } from "react-redux";
import {
  selectDeliveryStatesForFilters,
  selectDeliveryStatusForFilter,
} from "../../../redux/status";
import { selectOrderClients } from "../../../redux/bl";

export const useHomeValues = () => {
  const deliveryStates = useSelector(selectDeliveryStatesForFilters);
  const deliveryStatus = useSelector(selectDeliveryStatusForFilter);
  const deliveryClients = useSelector(selectOrderClients);

  return { deliveryStates, deliveryStatus, deliveryClients };
};
