import React, { useState } from "react";
import "./style.scss";
import { Badge, Button, Col, Row, Space } from "antd";
import { FileSearchOutlined } from "@ant-design/icons";
import { ExportXLS } from "../index";
import { useSelector } from "react-redux";
import EdiFileModal from "./EdiFileModal";
import ArticleFileModal from "./ArticleFileModal";
import { selectNbFiles } from "../../../redux/bl/selector";

const BlFilter = ({ strings, bl, btnCloseStep }) => {
  const [ediVisible, setEdiVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const nbFiles = useSelector(selectNbFiles);

  return (
    <Row justify={"space-between"} className={"row-bl-filter"}>
      <EdiFileModal
        data={bl?.edi_trackings}
        visible={ediVisible}
        onClose={() => setEdiVisible(false)}
        strings={strings}
      />
      <ArticleFileModal
        articles={bl?.article_orders || []} // A CHANGER QUAND ARTICLE DONE
        visible={isVisible}
        strings={strings}
        onClose={() => setIsVisible(false)}
      />
      <Col flex={1} className={"align-self-center bottom-margin-col"}>
        <Space size={8} wrap>
          {btnCloseStep}
          {/*<DropdownFilter
            width={150}
            id={"filter-remitter"}
            array={states}
            clientKey={"state"}
            valueKey={"code"}
            nameKey={"name"}
            values={filters.state}
            handleFilterClick={handleFilterClick}
            strings={strings}
            isString
          />
          <DropdownFilter
            width={150}
            array={types}
            clientKey={"types"}
            valueKey={"id"}
            nameKey={"name"}
            values={filters.types}
            handleFilterClick={handleFilterClick}
            strings={strings}
          />
          <DropdownFilter
            width={150}
            array={status}
            valueKey={"code"}
            nameKey={"name"}
            clientKey={"status"}
            values={filters.status}
            handleFilterClick={handleFilterClick}
            strings={strings}
            isString
          />*/}
        </Space>
      </Col>
      <Col
        style={{ marginRight: 10 }}
        className={"align-self-center bottom-margin-col"}
      >
        <Space size={8} wrap>
          {/*<Button
            style={{width: 100}}
            icon={<InfoCircleOutlined />}
            onClick={openNewTab}
            type="primary"
          >
            {strings("bl_info")}
          </Button> DO NOT REMOVE NOW */}
          <ExportXLS
            style={{ width: 100 }}
            strings={strings}
            filters={bl ? { id: bl.id } : {}}
          />
          <Button
            style={{ width: 100 }}
            icon={<FileSearchOutlined />}
            onClick={() => setEdiVisible(true)}
            type="primary"
          >
            {strings("edi_file")}
          </Button>
          <Badge offset={[0, 0]} count={nbFiles || 0}>
            <Button
              style={{ width: 100 }}
              icon={<FileSearchOutlined />}
              onClick={() => setIsVisible(true)}
              type="primary"
            >
              {strings("doc")}
            </Button>
          </Badge>
        </Space>
      </Col>
    </Row>
  );
};

export default BlFilter;
