import React, {useMemo} from "react";
import "./style.css";
import { orderStatesColor, underOrderStepsStatusColors } from "./colors";

export const OrderStepStatusType = {
    STATE: "state",
    STEP: "step"
}

export const OrderStepStatus = ({type, status, id}) => {
    const colors = useMemo(() => {
        if (type === OrderStepStatusType.STATE) return orderStatesColor;
        return underOrderStepsStatusColors;
    }, [type]);

    return (
        <p className={"order-step-status"} id={id} style={{color: colors[status?.code || ""]}}>
            {status?.sealogis_value || "-"}
        </p>
    )
}
