/* eslint-disable import/no-anonymous-default-export */
import { Select } from "antd";
import { useSelector } from "react-redux";
import "./style.css";
import { useEffect } from "react";
import { selectRemitter } from "../../../../../redux/app";

const { Option } = Select;

export default ({
  id,
  name,
  selector,
  onFilterChange,
  schema = { value: "code" },
  filters = {},
  strings,
  selectStyle,
  multiple = false,
}) => {
  const options = useSelector(selector);
  const value = useSelector(selectRemitter);

  useEffect(() => {
    if (value?.length && !filters[name]) {
      onFilterChange({ [name]: value });
    }
    //eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (options && options?.[0] && !filters[name]) {
      onFilterChange({ [name]: options[0][schema.value] });
    }
    //eslint-disable-next-line
  }, [options]);

  const renderOption = (string) =>
    schema.isString ? strings(string) : strings("remitter") + " " + string;

  const handleOnChange = (value) => {
    let toSet = value;
    if (multiple && !value?.length) toSet = null;
    onFilterChange({ [name]: toSet });
  };

  const onlyOneOption = options?.length === 1;

  return (
    <Select
      id={id}
      value={value || []}
      onChange={handleOnChange}
      style={selectStyle || {}}
      disabled={onlyOneOption}
      className={"remitter-select"}
      allowClear={false}
      mode={multiple ? "multiple" : undefined}
    >
      {options?.map(({ id, ...option }) => (
        <Option value={option[schema.value]} key={`select-${name}-${id}`}>
          {renderOption(option[schema.name] || option[schema.value])}
        </Option>
      ))}
    </Select>
  );
};
