import React from "react";
import { Typography } from "antd";
import EditionComponent from "./";

export const SealogisVsTeliae = ({
  value,
  strings,
  dataKey,
  record,
  index,
  isEdit,
  canWrite,
  handleEditionFieldClick = () => {},
  editComponentType = "date",
}) => {
  const { current, teliae, sealogis } = value;
  const isEditingCell =
    index === isEdit.index && `formatted_${dataKey}` === isEdit.key;
  return (
    <EditionComponent
      type={editComponentType}
      value={(isEditingCell ? sealogis : current)?.value}
      style={{ color: current?.color, maxWidth: 250 }}
      strings={strings}
      isBlocked={isEdit.index !== -1}
      isEdit={isEditingCell}
      isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
        `formatted_${dataKey}`,
      )}
      setIsEdit={() =>
        handleEditionFieldClick({
          index,
          key: `formatted_${dataKey}`,
          record,
        })
      }
      onCancel={() =>
        handleEditionFieldClick({
          index: -1,
          key: `formatted_${dataKey}`,
          record,
        })
      }
      required={false}
      canWrite={canWrite}
      updateInfos={{ key: dataKey, id: record.id }}
      showTooltip
      tooltipTitle={
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography style={{ color: teliae?.color }}>
            {strings(teliae.charterText ? "charter" : "volume_type_real")}:{" "}
            {teliae?.value}
          </Typography>
          <Typography style={{ color: sealogis?.color }}>
            {strings("volume_type_sealogis")}: {sealogis?.value}
          </Typography>
        </div>
      }
    />
  );
};
