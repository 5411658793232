import { Select } from "antd";
import { useSelector } from "react-redux";

const { Option } = Select;

const SelectEdit = ({
  selector,
  hideOptions,
  enableFields,
  optionsStyle,
  strings,
  showSearch,
  ...props
}) => {
  const options = useSelector(selector);
  return (
    <Select
      {...props}
      dropdownMatchSelectWidth={
        optionsStyle ? optionsStyle.dropdownMatchSelectWidth : true
      }
      showSearch={showSearch}
    >
      {options
        ?.filter(({ code }) =>
          hideOptions?.length > 0 ? !hideOptions.includes(code) : true,
        )
        ?.map(({ id, code, label }) => (
          <Option
            disabled={
              enableFields?.length > 0 ? !enableFields.includes(code) : false
            }
            value={code}
            key={id || code}
          >
            {label || code}
          </Option>
        ))}
    </Select>
  );
};

export default SelectEdit;
