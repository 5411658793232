import { createAction } from "../../utils";

export const types = {
  ADMIN_FILE_REQUEST: "ADMIN_FILE_REQUEST",
  ADMIN_FILE_SUCCESS: "ADMIN_FILE_SUCCESS",
  ADMIN_FILE_FAILURE: "ADMIN_FILE_FAILURE",

  SET_SELECTED_REMITTER: "SET_SELECTED_REMITTER",
};

const actions = {
  adminFileRequest: () => createAction(types.ADMIN_FILE_REQUEST),
  adminFileSuccess: (data) => createAction(types.ADMIN_FILE_SUCCESS, { data }),
  adminFileFailure: () => createAction(types.ADMIN_FILE_FAILURE),
  setSelectedRemitter: (remitter) =>
    createAction(types.SET_SELECTED_REMITTER, { remitter }),
};

export default actions;
