import { Modal, Collapse } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PalletsActions,
  selectPalletsLoading,
} from "../../../../redux/pallets";
import { PalletsTable } from "./PalletsTable";
import { ArticlesTable } from "./ArticlesTable";
import "./style.scss";

const { Panel } = Collapse;

export const PalletsModal = ({
  values = { id: -1, blNum: "" },
  selectArticle,
  selectTotal,
  onClose = () => {},
  strings = (_) => {},
}) => {
  const { id, blNum } = values;
  const dispatch = useDispatch();
  const loading = useSelector(selectPalletsLoading);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const handleResize = () => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (id !== -1 && dispatch) dispatch(PalletsActions.getOrderPallets(id));
    //eslint-disable-next-line
  }, [id]);

  const handleOnClose = () => {
    if (dispatch) dispatch(PalletsActions.resetOrderPallets());
    onClose && onClose();
  };

  return (
    <Modal
      id={"pallets-modal"}
      open={id !== -1}
      closable
      onCancel={handleOnClose}
      onOk={handleOnClose}
      width={dimensions.width - 100}
      style={{ maxWidth: "1500px" }}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ id: "pallets-modal-ok" }}
      title={`${strings("order_pallet_modal_title")} - ${blNum}`}
    >
      <Collapse
        className={"remove-padding-collapse"}
        defaultActiveKey={["1", "2"]}
      >
        <Panel header="Table articles" key="1">
          <ArticlesTable
            id={"articles-table-volumetrie"}
            selectArticle={selectArticle}
            selectTotal={selectTotal}
            loading={loading}
            strings={strings}
          />
        </Panel>
        <Panel header="Table palettes" key="2">
          <PalletsTable
            id={"pallets-table-volumetrie"}
            loading={loading}
            strings={strings}
          />
        </Panel>
      </Collapse>
    </Modal>
  );
};
