import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";

const { Option } = Select;

const SearchFilterComponent = (
  {
    searchAction,
    resetSearchAction,
    selector,
    strings,
    schema = {},
    name,
    onFilterChange = () => {},
    onChangeFilter = () => {},
    onReset = null,
    ...props
  },
  ref,
) => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const [options, nbOptions] = useSelector(selector);

  useImperativeHandle(
    ref,
    () => {
      return {
        clear: () => {
          setSelected([]);
          if (resetSearchAction) {
            dispatch(resetSearchAction());
          }
        },
      };
    },
    //eslint-disable-next-line
    [],
  );

  const renderOption = (string) => (schema.isString ? strings(string) : string);

  const onSearch = (value) => {
    if (dispatch && searchAction) {
      setLoading(true);
      dispatch(
        searchAction(value, {
          onSuccess: () => setLoading(false),
          onError: () => {},
        }),
      );
    }
  };

  const handleOnChange = (value) => {
    onFilterChange({ [name]: value });
    if (onChangeFilter) {
      const val = Array.isArray(value) ? value : [value];
      onChangeFilter(val);
    }
  };

  const handleSelect = (value) => {
    const state = selected;
    state.push(value);
    setSelected(state);
  };

  const handleDeselect = (value) => {
    const state = selected;
    const indexOf = state.indexOf(value);
    if (indexOf !== -1) {
      state.splice(indexOf, 1);
      setSelected(state);
    }
  };

  const onClear = () => {
    if (onReset) {
      onReset();
    } else {
      if (dispatch && resetSearchAction) dispatch(resetSearchAction());
      setSelected([]);
    }
  };

  return (
    <>
      <Select
        placeholder={props.placeHolder}
        onChange={handleOnChange}
        style={props.selectStyle || {}}
        onSelect={handleSelect}
        onDeselect={handleDeselect}
        value={selected}
        showSearch
        onSearch={onSearch}
        notFoundContent={loading ? <Spin /> : strings("no_values")}
        allowClear
        onClear={onClear}
        dropdownStyle={{ minWidth: "250px", maxWidth: "250px" }}
        mode={"multiple"}
        filterOption={(input, option) => {
          const { children } = option;
          return children && typeof children === "string"
            ? children.toLowerCase().includes("nombre total:") ||
                children.toLowerCase().includes(input.toLowerCase())
            : false;
        }}
      >
        <>
          {options?.length && nbOptions > options.length ? (
            <Option key={0} value={"UNUSED"} disabled>{`${strings(
              "nb_clients_total",
            )} ${nbOptions}${strings("precise_research")}`}</Option>
          ) : null}
          {options?.map((option, index) => (
            <Option
              value={option[schema.value]}
              key={`seach-option-${option.id}-${index}`}
            >
              {renderOption(option[schema.name] || option[schema.value])}
            </Option>
          ))}
        </>
      </Select>
    </>
  );
};

export const SearchFilter = forwardRef(SearchFilterComponent);
