import { resettableReducer } from "reduxsauce";
import { CommonTypes } from "./common";
import { AuthReducers, AuthSagas } from "./auth";
import { LoaderReducers } from "./loader";
import { BlReducers, BlSagas } from "./bl";
import { AppReducers, AppSagas } from "./app";
import { StatusReducers, StatusSagas } from "./status";
import { PalletsSagas, PalletsReducers } from "./pallets";
import { DataReducers, DataSagas } from "./data";

// Specify reset action type rule
const resettable = resettableReducer(CommonTypes.RESET_REDUCERS);

// Merge our redux together
const reducers = {
  auth: resettable(AuthReducers),
  loader: resettable(LoaderReducers),
  bl: resettable(BlReducers),
  app: resettable(AppReducers),
  status: resettable(StatusReducers),
  pallets: resettable(PalletsReducers),
  data: resettable(DataReducers),
};

export const rootSaga = [
  ...AuthSagas,
  ...BlSagas,
  ...AppSagas,
  ...StatusSagas,
  ...PalletsSagas,
  ...DataSagas,
];

export { languages, strings } from "./translations";

export default reducers;
