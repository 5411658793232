export const underOrderStepsStatusColors = {
  // yellow
  WAITING: "rgb(252, 200, 110)",
  AFFRET_WAITING: "rgb(252, 200, 110)",
  AFFRET_LF_CARRIER: "rgb(252, 200, 110)",
  AFFRET_FOUND_CARRIER: "rgb(252, 200, 110)",

  // orange
  DEFAULT: "rgb(252, 150, 110)",
  CANCELED: "rgb(252, 150, 110)",
  PARTIAL_MISSING_PACKAGE: "rgb(252, 150, 110)",
  MISSING_PACKAGE: "rgb(252, 150, 110)",
  PERMANENTLY_MISSING: "rgb(252, 150, 110)",
  AVARIED: "rgb(252, 150, 110)",
  RETURNED: "rgb(252, 150, 110)",
  DENIED: "rgb(252, 150, 110)",
  DENIED_AVARIED: "rgb(252, 150, 110)",
  NOT_LAUNCHABLE: "rgb(252, 150, 110)",
  AFFRET_CANCELED_CARRIER: "rgb(252, 150, 110)",
  // light green
  IN_PROGRESS: "rgb(111,218,6)",
  PREPARABLE: "rgb(111,218,6)",
  LAUNCHED: "rgb(111,218,6)",
  PREPARED: "rgb(111,218,6)",
  CONTROLED: "rgb(111,218,6)",
  AFFECTED: "rgb(111,218,6)",
  PRE_INFORMED: "rgb(111,218,6)",
  HANDLED: "rgb(111,218,6)",
  // green
  SHIPPED: "rgb(17,112,5)",
  DELIVERED: "rgb(17,112,5)",
  AFFRET_DELIVERY_OK: "rgb(17,112,5)",
  AFFRET_DELIVERY_NC: "rgb(17,112,5)",
  // light blue

  AFFRET_SHIPMENT_OK: "rgb(5,73,208)",
  AFFRET_SHIPMENT_NC: "rgb(5,73,208)",
  // red
  LOG_ERROR_1: "rgb(225,22,7)",
  TPT_ERROR_1: "rgb(225,22,7)",
  TPT_ERROR_2: "rgb(225,22,7)",

  // black, undefined
  "": "black",
  undefined: "black",
};

export const orderStatesColor = {
  // light blue
  WALLET: "rgb(5,73,208)",
  IN_PROGRESS: "rgb(5,73,208)",

  // light green
  SHIPPED: "rgb(99,185,12)",
  DELIVERED: "rgb(99,185,12)",

  // orange
  OUT_OF_STOCK: "rgb(189,92,17)",
  CANCELED: "rgb(189,92,17)",
  BLOCKED: "rgb(189,92,17)",

  //red
  TO_VERIFY: "rgb(225,22,7)",
};
