import { createAction } from "../../utils/index";

// Types
export const types = {
  RESET_REDUCERS: "RESET_REDUCERS",
};

// Actions
const actions = {
  resetReducers: () => createAction(types.RESET_REDUCERS),
};

export default actions;
