import { Switch } from "antd";
import "./style.css";

const SwitchFilter = ({
  name,
  placeHolder,
  onFilterChange,
  filters = {},
  strings,
  style = {},
}) => {
  const value = filters[name];

  const handleOnChange = (value) => {
    onFilterChange({ [name]: value });
  };

  return (
    <div className={"switch_wrapper"} style={{ minHeight: "32", ...style }}>
      <span style={{ marginRight: "10px" }}>{strings(placeHolder)}</span>
      <Switch checked={value} onChange={handleOnChange} />
    </div>
  );
};

export default SwitchFilter;
