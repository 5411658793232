/* eslint-disable import/no-anonymous-default-export */
import { Select } from "antd";
import { useSelector } from "react-redux";
import "./style.css";

const { Option } = Select;

export default ({
  id,
  name,
  placeHolder,
  selector,
  onFilterChange,
  schema = { value: "code" },
  filters = {},
  strings,
  selectStyle,
  multiple = false,
}) => {
  const value = filters[name];
  const options = useSelector(selector);
  const renderOption = (string) => (schema.isString ? strings(string) : string);

  const handleOnChange = (value) => {
    let toSet = value;
    if (multiple && !value?.length) toSet = null;
    onFilterChange({ [name]: toSet });
  };

  const filterOption = (input, option) =>
    (option?.children ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div id={id}>
      <Select
        value={value || []}
        onChange={handleOnChange}
        placeholder={strings(placeHolder)}
        style={selectStyle || {}}
        filterOption={filterOption}
        /*className={"dropdown_wrapper"}*/
        allowClear
        showSearch
        mode={multiple ? "multiple" : undefined}
      >
        {options?.map(({ id, ...option }) => (
          <Option value={option[schema.value]} key={`select-${name}-${id}`}>
            {renderOption(option[schema.name] || option[schema.value])}
          </Option>
        ))}
      </Select>
    </div>
  );
};
