const userReadRules = require("./rules/user.json");
const userWriteRules = require("./rules/userwrite.json");
const bcuRules = require("./rules/bcu.json");
const sealogis5PLRules = require("./rules/sealogis5PL.json");
const superAdminSKRules = require("./rules/superAdminSK.json");
const serviceSKRules = require("./rules/serviceSK.json");

export const userRules = {
  USER: userReadRules,
  USERWRITE: userWriteRules,
  BCU: bcuRules,
  "Sealogis 5PL": sealogis5PLRules,
  "Super Admin SK": superAdminSKRules,
  "Service SK": serviceSKRules,
};
