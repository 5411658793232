import React from "react";
import "./style.css";
import {
  OrderStepStatus,
  OrderStepStatusType,
} from "../../../components/orderStepStatus/OrderStepStatus";
import VolumeComponent from "../../../components/VolumeComponent";
import MultipleValueDisplay from "../../../components/multipleValueDisplay";
import ProgressBl from "../../../components/progressBL";
import { Card, Descriptions, Row, Space } from "antd";
import { BasicComponents } from "../../../components/basic";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ bl, strings }) => {
  const infos = [
    {
      key: strings("customer_name"),
      value: (
        <MultipleValueDisplay
          id={"customer_name"}
          keyValueOne={"remitter"}
          value1={bl?.clients?.remitter?.name}
          value2={bl?.clients?.log?.name}
          value3={bl?.clients?.tpt?.name}
          strings={strings}
        />
      ),
    },
    {
      id: "edi_date_remitter",
      key: strings("edi_date_remitter"),
      value: bl?.edi_date || "",
    },
    {
      key: strings("cde_gross_weight"),
      value: bl && (
        <VolumeComponent
          id={"cde_gross_weight"}
          volumes={bl?.volumes}
          strings={strings}
          type="weight"
        />
      ),
    },
    {
      key: strings("address"),
      value: (
        <MultipleValueDisplay
          id={"address"}
          keyValueOne={"remitter"}
          value1={bl?.clients?.remitter?.address?.addr1}
          value2={bl?.clients?.log?.address?.addr1}
          value3={bl?.clients?.tpt?.address?.addr1}
          strings={strings}
        />
      ),
    },
    {
      id: "edi_delay",
      key: strings("edi_delay"),
      value: bl?.edi_delay,
    },
    {
      key: strings("nb_packages"),
      value: bl && (
        <VolumeComponent
            id="nb_packages"
          volumes={bl?.volumes}
          strings={strings}
          type="nb_total_packages"
        />
      ),
    },
    {
      key: strings("code_postal"),
      value: (
        <MultipleValueDisplay
          id={"code_postal"}
          keyValueOne={"remitter"}
          value1={bl?.clients?.remitter?.address?.zip_code}
          value2={bl?.clients?.log?.address?.zip_code}
          value3={bl?.clients?.tpt?.address?.zip_code}
          strings={strings}
        />
      ),
    },
    {
      id: "asked_delivery_date",
      key: strings("asked_delivery_date"),
      value: bl?.formatted_asked_delivery_date,
    },
    {
      key: strings("nb_pal"),
      value: bl && (
        <VolumeComponent
            id={"nb_pal"}
          volumes={bl?.volumes}
          strings={strings}
          type="nb_pallets"
        />
      ),
    },
    {
      key: strings("email"),
      value: (
        <MultipleValueDisplay
          id={"email"}
          keyValueOne={"remitter"}
          value1={bl?.clients?.remitter?.email}
          value2={bl?.clients?.log?.email}
          value3={bl?.clients?.tpt?.email}
          strings={strings}
        />
      ),
    },
    {
      id: "appointment_date",
      key: strings("appointment_date"),
      value: bl?.formatted_appointment_date,
    },
    {
      id: "delivery_instruction",
      key: strings("delivery_instruction"),
      value: bl?.delivery_instruction,
    },
    {
      key: strings("tel"),
      value: (
        <MultipleValueDisplay
          id={"tel"}
          keyValueOne={"remitter"}
          value1={bl?.clients?.remitter?.phone_number}
          value2={bl?.clients?.log?.phone_number}
          value3={bl?.clients?.tpt?.phone_number}
          strings={strings}
        />
      ),
    },
    {
      key: strings("warehouse_status"),
      value: (
        <OrderStepStatus
          id={"warehouse_status"}
          type={OrderStepStatusType.STEP}
          status={bl?.warehouse_status}
        />
      ),
    },
    {
      id: "delivery_instruction_two",
      key: strings("delivery_instruction_two"),
      value: bl?.delivery_instruction_two,
    },
    {
      key: "",
      value: " ",
    },
    {
      id: "carrier_code",
      key: strings("carrier_code"),
      value: bl?.carrier_code || "",
    },
    {
      id: "sealogis_commentary",
      key: strings("sealogis_commentary"),
      value: bl?.sealogis_commentary,
    },
    ...(bl?.edi_tracking_failures?.length > 0 ? [{
      key: strings("edi_tracking_failure", {count: bl?.edi_tracking_failures?.length}),
      value: <Space>
        {bl?.edi_tracking_failures?.map((etf, index) =>
          <Space>
            <BasicComponents.Tooltip
              key={`error_technical_${index}`}
              title={etf.error}
            >
              <p style={{color: "red"}}>{etf.type === "TELIA" ? "TELIAE" : etf.type}</p>
            </BasicComponents.Tooltip>
            {index + 1 !== bl?.edi_tracking_failures?.length && <p>/</p>}
          </Space>)}
      </Space>
    }] : [])
  ];

  return (
    <Card style={{ width: "100%" }}>
      <Row justify={"space-between"}>
        <Space>
          <Descriptions
            size={"small"}
            title={`${strings("bl_number")}: ${bl?.bl_num || "-"} / ${strings("cde_code")}: ${bl?.br_num || "-"}`}
          >
            {infos.map(({ key, value, id}) => (
              <Descriptions.Item label={`${key}`}>
                <span id={id ? id : ""}>{value ? value : "-"}</span>
              </Descriptions.Item>
            ))}
          </Descriptions>
          <div
            style={{
              width: 200,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ProgressBl status="active" type="circle" percent={bl?.percent} />
            <p style={{ fontWeight: "bold", margin: 0 }}>
              {strings("progression_maj_m")}
            </p>
          </div>
        </Space>
      </Row>
    </Card>
  );
};
