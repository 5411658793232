import { createSelector } from "reselect";

export const selectBcuData = createSelector(
  ({ data }) => data.bcu_data,
  (bcuData) => bcuData.map((el) => ({ ...el, code: el.loading_zone })),
);

export const selectXCStatus = createSelector(
  ({ data }) => data.xc_status,
  (xcData) => xcData,
);

export const selectPartnerCarrier = createSelector(
  ({ data }) => data.partner_carriers,
  (partners) => partners.map((el) => ({ ...el, code: el.sealogis_code })),
);
