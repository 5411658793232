import React, { useEffect, useMemo } from "react";
import { Button, Modal, Popconfirm, Space, Table, Upload } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { AuthActions } from "../../../../redux/auth";
import { BlActions } from "../../../../redux/bl";
import { showError } from "../../../../utils/notifications-helper";
import {
  selectFilesRemitter,
  selectFileType,
} from "../../../../redux/bl/selector";
import "./style.css";
import { CanWithChildren } from "../../../components/can";

const FilesModal = ({
  type,
  orderId,
  visible,
  strings,
  onClose = () => {},
  selectedClient,
}) => {
  const dispatch = useDispatch();
  const [{ loadingId, loadingEvent }, setIsLoading] = useState({});
  const fileTypes = useSelector(selectFileType);
  const filesRemitter = useSelector(selectFilesRemitter);

  const selectedTypeId = useMemo(() => {
    return fileTypes?.find((fileType) => fileType.name === type)?.id;
  }, [fileTypes, type]);

  useEffect(() => {
    if ((selectedTypeId || orderId) && selectedClient?.id && dispatch) {
      dispatch(
        BlActions.getFilesRemitterRequest({
          typeId: selectedTypeId ?? null,
          remitterId: selectedClient.id,
          orderId: orderId ?? null,
        }),
      );
    }
    //eslint-disable-next-line
  }, [selectedTypeId, orderId, selectedClient, visible]);

  const handleFile = (file) => {
    const { location, id } = file || {};
    if (location) {
      setIsLoading({ loadingId: id, loadingEvent: "download" });
      dispatch(
        AuthActions.getAdminFileSignedUrl({
          location: location,
          callbacks: {
            onError: () => {},
            onSuccess: (url) => {
              url && window.open(url, "_blank");
              setIsLoading({});
            },
          },
        }),
      );
    }
  };

  const beforeUpload = (file) => {
    if (!file) return;
    if (file.size / 1024 / 1024 > 4) {
      showError("file_size_error", strings);
      return;
    }
    setIsLoading({
      loadingEvent: "upload",
      loadingId: `${selectedTypeId ?? orderId}${selectedClient?.id || ""}`,
    });
    dispatch(
      BlActions.createFileRemitterRequest({
        file,
        remitterId: selectedClient?.id,
        typeId: selectedTypeId ?? null,
        orderId: orderId ?? null,
        callback: () => {
          setIsLoading({});
          dispatch(BlActions.getNumberFileTypeRequest(selectedClient.id));
        },
      }),
    );
    return false;
  };

  const handleDelete = (fileId) => {
    setIsLoading({ loadingId: fileId, loadingEvent: "delete" });
    dispatch(
      BlActions.deleteFileRemitterRequest(fileId, () => {
        dispatch(BlActions.getNumberFileTypeRequest(selectedClient.id));
        setIsLoading({});
      }),
    );
  };

  const columns = [
    {
      title: strings("name"),
      dataIndex: "name",
      align: "center",
    },
    {
      title: strings("create_date"),
      dataIndex: "created_at",
      align: "center",
    },
    {
      title: strings("action"),
      fixed: "right",
      width: "100px",
      align: "center",
      render: (record) => (
        <Space>
          <Button
            id={`download-button-${record.id}`}
            shape={"circle"}
            icon={<DownloadOutlined />}
            onClick={() => handleFile(record)}
            disabled={
              loadingEvent &&
              !(loadingId === record.id && loadingEvent === "download")
            }
            loading={loadingId === record.id && loadingEvent === "download"}
          />
          <CanWithChildren action={"display"} subject={"btn_delete_file"}>
            <Popconfirm
              title={strings("confirm_action")}
              okText={strings("yes")}
              cancelText={strings("no")}
              onConfirm={() => handleDelete(record.id)}
            >
              <Button
                id={`delete-button-${record.id}`}
                shape={"circle"}
                danger
                icon={<DeleteOutlined />}
                disabled={
                  loadingEvent &&
                  !(loadingId === record.id && loadingEvent === "delete")
                }
                loading={loadingId === record.id && loadingEvent === "delete"}
              />
            </Popconfirm>
          </CanWithChildren>
        </Space>
      ),
    },
  ];

  return (
    <Modal
      title={
        orderId
          ? strings("title_files_order")
          : strings(`title_files_${type}`) + ` ${selectedClient?.name || ""}`
      }
      open={visible}
      onOk={onClose}
      onCancel={onClose}
      width={1000}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <CanWithChildren
        action={"display"}
        subject={orderId ? "btn_upload_file_by_order" : "btn_upload_file"}
      >
        <div className={"flex-end"}>
          <Upload
            id={`upload-component-files`}
            beforeUpload={(file) => beforeUpload(file)}
            maxCount={1}
            showUploadList={false}
            customRequest={() => 1}
            onPreview={() => {}}
          >
            <Button
              id={`upload-button-files`}
              type="primary"
              icon={<UploadOutlined />}
              disabled={loadingEvent}
              loading={
                loadingId ===
                  `${selectedTypeId || orderId}${selectedClient?.id || ""}` &&
                loadingEvent === "upload"
              }
            >
              {strings("upload")}
            </Button>
          </Upload>
        </div>
      </CanWithChildren>
      <Table
        size={"small"}
        bordered
        scr
        dataSource={filesRemitter || []}
        rowKey={(record) => record.id}
        pagination={false}
        columns={columns}
      />
    </Modal>
  );
};

export default FilesModal;
