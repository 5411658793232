import { ArrowLeftOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Col, Layout, Popconfirm, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { BackButton } from "../../components/button";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { currentUser } from "../../../redux/auth/selector";
import RemitterSelectFilter from "../../screens/home/fluxFilter/filters/RemitterSelectFilter";
import { selectClients } from "../../../redux/bl";
import { AppActions } from "../../../redux/app";

const { Header, Content } = Layout;

const AuthLayout = ({
  logout,
  navigateTo,
  strings,
  children,
  isBack,
  getCurrentUserRequest,

  isHome = false,
  filters,
  onFilterChange,
}) => {
  const user = useSelector(currentUser);
  const dispatch = useDispatch();
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const handleResize = () => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    if (getCurrentUserRequest) getCurrentUserRequest();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    //eslint-disable-next-line
  }, []);

  const onLogout = () => {
    if (logout) logout();
  };

  const goHome = () => {
    if (navigateTo) navigateTo("/home");
  };

  const handleFilterChange = (toAdd) => {
    dispatch(AppActions.setSelectedRemitter(toAdd.client));
    onFilterChange(toAdd);
  };

  return (
    <Layout className="layout">
      <Header
        className="custom-layout-header"
        style={{
          background: "#F6F7F8",
          height: 48,
          padding: 0,
          zIndex: 2,
          margin: "16px 0",
        }}
      >
        <Row className={"header-container"}>
          <Col span={6} className={"header-start-container"}>
            {isHome && (
              <RemitterSelectFilter
                id={"bl-remitter"}
                name={"client"}
                selector={selectClients}
                onFilterChange={handleFilterChange}
                schema={{ value: "code", name: "name" }}
                filters={filters}
                strings={strings}
                selectStyle={{ width: "200px", marginLeft: "10px" }}
              />
            )}
            {isBack && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <BackButton
                  modify={goHome}
                  icon={<ArrowLeftOutlined />}
                  shape={"circle"}
                  style={{ marginRight: 10, marginLeft: 10 }}
                />
              </div>
            )}
          </Col>
          <Col span={12} className="header-container">
            <h1 style={{ fontSize: 25, Height: "100%", margin: 0 }}>
              {strings("title")}
            </h1>
          </Col>
          <Col
            span={6}
            type="flex"
            className={"header-end-container"}
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className={"user-name"}>{user?.first_name}</Typography>
            <Popconfirm
              title={strings("confirm_logout")}
              okText="Oui"
              cancelText="Non"
              placement="bottomRight"
              onConfirm={onLogout}
            >
              <Button
                id={"logout"}
                icon={<UserOutlined />}
                style={{
                  marginRight: 18,
                  color: "#0480FC",
                  borderColor: "#0480FC",
                  backgroundColor: "#F6F7F8",
                }}
                shape="circle"
              />
            </Popconfirm>
          </Col>
        </Row>
      </Header>
      <Content
        id={"content"}
        style={{
          height: dimensions.height - 80,
          overflow: "auto",
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        <div style={{ height: "100%", position: "relative" }}>{children}</div>
      </Content>
    </Layout>
  );
};

export default AuthLayout;
