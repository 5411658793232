import "./style.css";
import { Button, Tooltip } from "antd";

const TrackingUrlButton = ({ url, icon, className, tooltipTitle, onClick }) => {
  return (
    <Tooltip title={tooltipTitle}>
      <Button
        ghost
        className={`shipping-button ${className}`}
        type={"primary"}
        icon={icon}
        shape={"circle"}
        disabled={!url}
        onClick={() => {
          if (onClick) onClick();
          else window.open(url);
        }}
      />
    </Tooltip>
  );
};

export default TrackingUrlButton;
