const strings_fr = {
  authentication_failed: "Erreur de connexion",
  empty_username: "Entrez un nom d'utilisateur s'il vous plait!",
  empty_password: "Entrez un mot de passe s'il vous plait!",
  login: "Connexion",
  username: "Nom d'utilisateur",
  password: "Mot de passe",
  title: "Digital Global Tracking",
  state: "État",
  customer_name: "Nom du client",
  ship_date: "Date d’expédition",
  current_step: "Étape en cours",
  progress: "Progression",
  action: "Action",
  delete: "Supprimer",
  search_bl: "Recherche BL",
  client: "Client",
  filters: "Filtres",
  filter: "Filtrer",
  cancel: "Annuler",
  add: "Ajouter",
  bl_failed: "Les bl n'ont pas pu être chargés",
  bl_update_success: "BL mis à jours avec succés",
  bl_update_failed: "Erreur lors de la mise à jour du BL",
  onTime: "À l'heure",
  AWAITING_PREPARATION: "En cours",
  toVerify: "À vérifier",
  delivered: "Livré",
  inProgress: "En cours",
  step: "Étape",
  date_format: "DD/MM/YYYY",
  partner: "Prestataire",
  city: "Ville",
  status: "Statut",
  maj_status: "MAJ Statut",
  maj_status_date: "Date MAJ Statut",
  maj_status_time: "Heure MAJ Statut",
  loading_date: "Date chargement",
  arriving_date: "Date départ",
  start_date: "Date liv. souhaitée début",
  end_date: "Date liv. souhaitée fin",
  export: "Export",
  import: "Import CSV",
  ged: "GED",
  add_cmd_bcu: "Ajout CDE",
  add_cmd_bcu_title: "Ajout d'une commande",
  add_cmd_delivery_num: "Numéro de commande",
  order_visible_success: "Commande ajoutée avec succès",
  order_visible_error: "Un problème est survenu lors de l'ajout de la commande",
  delivery_num: "Numéro de commande",
  no_cde_contact_support:
    "Aucune commande trouvée avec ce numéro de livraison, veuillez contacter le support.",
  stock_import: "Stock",
  arrival_import: "Arrivage",
  kpi_import: "KPI",
  pricelist_import: "Grille tarifaire",
  bext_import: "Fichier BEXT",
  telia_import: "Fichier TELIAE",
  charter_import: "Fichier AFFRET",
  manual_order_import: "Fichier CDE",
  ref_client: "Référence client",
  id: "id flux",
  id_unique: "ID unique",
  ETA_date: "ETA date",
  ETA_time: "ETA time",
  from_date: "FROM a date",
  from_time: "FROM a time",
  types: "Type",
  cities: "Villes",
  s_shipper_code_5: "Code remettant sur 5",
  s_shipper_code_10: "Code remettant sur 10",
  s_rem_code: "Code site remettant",
  s_shipper_name: "Nom de l'expéditeur réel",
  s_shipper_code: "Code expéditeur réel",
  s_shiper_addr1: "Adresse1 expéditeur réel",
  s_shipper_addr2: "Adresse2 expéditeur réel",
  s_shipper_addr3: "Adresse3 expéditeur réel",
  s_shipper_zip: "Code postal expéditeur réel",
  s_shipper_city: "Ville expéditeur réel",
  s_shipper_country_code: "Pays INSEE expéditeur réel",
  s_shipper_country_name: "Pays nom expéditeur reel",
  s_shipper_contact_name: "Nom du contact expéditeur réel",
  s_shipper_tel: "Téléphone expéditeur réel",
  s_shipper_area: "Région expéditeur réel",
  s_shipper_mail: "Email expéditeur réel",
  s_removal_place_name: "Nom lieu d'enlèvement",
  s_removal_place_code: "Code lieu d'enlèvement",
  s_removal_place_addr1: "Adresse1 lieu d'enlèvement",
  s_removal_place_addr2: "Adresse2 lieu d'enlèvement",
  s_removal_place_addr3: "Adresse3 lieu d'enlèvement",
  s_removal_place_zip: "Code postal lieu d'enlèvement",
  s_removal_place_city: "Ville lieu d'enlèvement",
  s_removal_place_country_code: "Pays INSEE lieu d'enlèvement",
  s_removal_place_country_name: "Pays Nom lieu d'enlévement",
  s_removal_place_contact_name: "Nom du contact lieu d'enlèvement",
  s_removal_place_contact_tel: "Téléphone lieu d'enlèvement",
  s_removal_place_mail: "Email  lieu d'enlèvement",
  s_removal_flag: "Flag Enlèvement",
  expediteur: "EXPEDITEUR",
  destinataire: "DESTINATAIRE",
  delivery_date: "Date de livraison",
  theoretical: "Théorique",
  theoretical_weight: "Poid théorique",
  mesured_weight: "Poid mesuré",
  r_consignee_code: "Code destinataire",
  r_consignee_name: "Nom destinataire",
  r_consignee_addr1: "Adresse1 destinataire",
  r_consignee_addr2: "Adresse2 destinataire",
  r_consignee_addr3: "Adresse destinataire 3",
  r_consignee_addr4: "Adresse destinataire 4",
  r_consignee_zip: "Code postal",
  r_consignee_city: "Ville",
  r_consignee_contact_name: "Nom du contact",
  r_consignee_tel: "Téléphone",
  r_consignee_ref: "Reference destinataire",
  r_consignee_mail: "Email destinataire",
  r_consignee_tel2: "Téléphone 2",
  r_consignee_insee_country: "Pays INSEE",
  r_consignee_country_name: "Nom destinataire pays",
  r_consignee_type: "Type destinataire",
  e_total_packages: "Colis totaux",
  e_package_number: "Nombre de colis vrac",
  e_pallett_number: "Nombre de palettes",
  e_consigned_pallett_number: "Nb palettes consignées",
  e_weight: "Poids de l'expédition (Kg)",
  e_nature: "Nature marchandise",
  e_tax_units: "Unités taxables",
  e_total_packages_nb: "Nb colis totaux",
  e_url_BL: "Url BL joint",
  e_UM_number: "Nombre UM au sol",
  e_dangerous_goods: "Matières dangereuses",
  e_lenght: "Longueur",
  e_Volume: "Volume",
  e_linear_floor: "Mètre plancher linéaire",
  e_value: "Valeur déclarée",
  e_counter_refund: "Contre remboursement",
  e_commodity_value: "Valeur marchandise",
  o_declare_value_currency: "Devise valeur déclaré",
  o_reimburs_currency: "Devise contre remboursement",
  o_commodity_value_currency: "Devise valeur marchandise",
  e_customer_account: "Compte client",
  e_preparator_code: "Code préparateur",
  e_Incoterm: "Incoterm",
  e_area: "Région",
  e_transport_amount: "Montant manuel du transport",
  e_width: "Largeur",
  e_iata_code: "Code iata",
  e_contract_number: "Numéro de contrat",
  e_nb_palet1: "Nombre palette 1",
  e_typ_palet1: "Type de palette 1",
  e_nb_palet2: "Nombre palette 2",
  e_typ_palet2: "Type palette 2",
  e_nb_palet3: "Nombre palette 3",
  e_typ_palet3: "Type palette 3",
  e_height: "Hauteur",
  e_vol_alco: "Volume d’alcool",
  e_vol_alco_pur: "Volume d’alcool pur",
  e_ref_alco_doc: "Référence du document alcool",
  e_cat_prod: "Catégorie produit",
  e_temp_min: "Température souhaitée minimum",
  e_temp_max: "Température souhaitée maximum",
  e_temp_unit: "Température unité",
  e_consi_lang: "Langue parlée par le destinataire",
  e_adress_type: "Type adresse complémentaire",
  e_invoice_name: "Nom facture démartérialisée",
  o_bl_number: "Numéro de BL",
  o_order_date: "Date expédition",
  o_delivery_instructions: "Instructions de livraison",
  o_delivery_remarks: "Remarques de livraison",
  o_delivery_date: "Date de livraison",
  o_delivery_time: "Heure de livraison",
  o_delivery_orderdate_earlier: "Date de livraison au plus tôt",
  o_delivery_orderdate_later: "Date de livraison au plus tard",
  o_delivery_time_earlier: "Heure de livraison au plus tôt",
  o_delivery_time_later: "Heure de livraison au plus tard",
  o_point_relais: "Point relais",
  o_order: "Rendez-vous à prendre",
  o_removal_date: "Date d’enlèvement",
  o_removal_instruction: "Instruction enlèvement",
  o_removal_date_start: "date début enleèvement",
  o_removal_date_end: "date fin  enlèvement",
  o_removal_time_ealier: "Heure d’enlèvement au plus tôt",
  o_removal_time_later: "Heure d’enlèvement au plus",
  o_time_wish_start: "heure début  souhaitée",
  o_time_wish_end: "heure fin  souhaitée",
  o_flag: "Drapeau",
  o_receipt_number: "Numéro de récépissé",
  o_product_code: "Code produit",
  o_road_code: "Code tournée",
  o_carrier_info: "Infos transport",
  o_action_code: "Code Action",
  o_nbr_days_max: "Nombre de jours maximum",
  c_carrier_id: "Code transporteur",
  c_site_carrier: "Code site transporteur",
  c_site_product: "Code site produit",
  c_site_trafic: "Code site trafic",
  bl_number: "Numéro de BL",
  expedition: "EXPEDITION",
  commande: "COMMANDE",
  transporteur: "TRANSPORTEUR",
  articles: "ARTICLES",
  article: "Articles",
  do_ref_code: "Référence code 1",
  do_ref_lib: "Référence libellé",
  do_ref_quant: "quantité",
  do_ref_um: "Réfrence UM",
  do_ean13: "ean 13",
  etat_livraison: "Etat livraison",
  arrival_date: "Date d'arrivée",
  arrival_time: "Heure d'arrivée",
  carrier_tracking_url: "url tracking transporteur",
  departure_date: "Date de départ",
  departure_time: "Heure de départ",
  load_date: "Date de chargement",
  load_time: "Heure de chargement",
  confirm_logout:
    "Vous allez être déconnecté de l'interface, voulez-vous continuer ?",
  ref_id_unique: "Ref ID Unique",
  address: "Adresse",
  code_postal: "Code Postal",
  country: "Pays",
  country_code: "Code Pays",
  contact_name: "Nom du contact",
  tel: "Tel",
  email: "Email",
  prepare: "Préparation",
  request: "Demande",
  ship: "Expédition",
  print: "Impression",
  Expédiée: "Expédiée",
  status_failed: "les status n'ont pas pu être chargés",
  bcu_data_failed: "Les informations des bcu n'ont pas pu être chargées",
  type_flux_failed:
    "Les informations des types de flux n'ont pas pu être chargées",
  xc_status_failed:
    "Les informations des statuts des extras costs n'ont pas pu être chargées",
  partner_carrier_failed:
    "Les informations des partenaires n'ont pas pu être chargées",
  sk_related_status_failed:
    "Les informations relatives aux status SK n'ont pas pu être chargées",
  bl_info: "BL Info",
  progression_maj: "PROGRESSION",
  progression_maj_m: "Progression",
  flux: "flux",
  statut: "statut",
  name: "Nom",
  type: "Type",
  load: "chargement",
  depart: "depart",
  arrival: "arrivé",
  typeName: "typeName",
  contactMail: "mail du contact",
  contactName: "nom du contact",
  contactTel1: "tel 1 du contact",
  contactTel2: "tel 2 du contact",
  address1: "addresse 1",
  address2: "addresse 2",
  zip: "zip",
  is_planned: "prévu",
  missing_date: "Date manquante",
  teliae_data: "Données Teliae:",
  "En anomalie": "En anomalie",
  Livre: "Livré",
  "En cours": "En cours",
  Terminé: "Terminé",
  Contrôlée: "Contrôlée",
  Attente: "Attente",
  Préparée: "Préparée",
  unplanned: "Non prévu",
  delay: "Délai",
  open_days: "Jour ouvrés",
  yes: "Oui",
  no: "Non",
  ok: "OK",
  noConform: "Donnée non conforme",
  password_modal: "Première connexion, veuillez créer votre mot de passe",
  your_password: "votre mot de passe",
  re_password: "Répéter votre mot de passe",
  save: "Enregistrer",
  required_field: `Champ requis`,
  bl_export_failed: `Une erreur est survenu lors de l'export des données`,
  password_failed: "Erreur durant le changement de mot de passe",
  password_success: "Mot de passe modifié avec succés",
  password_complex:
    "Le mot de passe doit contenir 10 caractères, 1 chiffre, 1 minuscule, 1 majuscule, et 1 caractère spécial",
  password_identical: "Les champs mot de passe doivent être identiques",
  user_desactivated: "Compte désactivé",
  modale_desactivated_content: `Si besoin contacter le support à l'adresse suivante : ${process.env.REACT_APP_ADDRESS_SUPPORT}`,
  cgu: "CGU",
  privacy_policy: "Politique de confidentialité",
  cgu_accept: "J'accepte les ",
  privacy_policy_accept: "J'accepte la ",
  cgu_required: "CGU obligatoire",
  privacy_policy_required: "Politique de confidentialité obligatoire",
  file_admin_change: "Mise à jour des CGU / Politique de confidentialité",
  admin_file_failed: "Un problème est survenu lors de la sauvegarde",
  admin_file_success: "Données sauvegardées",
  required: "Obligatoire",
  partners: "Partenaires",
  no_partners: "Aucun Prestataire",
  no_status: "Aucun Statut",
  all_status: "Statuts",
  all_expand: "Tout ouvrir",
  all_not_expand: "Tout fermer",
  bl_export_start:
    "Export des BL, cela peut prendre plusieurs minutes, merci de patienter",
  get_current_user_failed:
    "Erreur lors de la récupération de vos informations utilisateur",
  bext_bl_import_failed: "Erreur lors de l'import du fichier",
  bext_bl_import_success: "Fichier importé avec succès",
  bext_bl_errors_in_import:
    "Attention ! Erreur(s) sur commande(s), non traitée(s) veuillez vérifier le rapport.",
  error: "Erreur",
  remitter_name: "Nom du remettant",
  edi_date_remitter: "Date EDI remettant",
  remitter: "Remettant",
  exp_date: "Date EXP",
  pickup_date: "Date ramassage",
  pickup_date_sealogis: "Date ramassage sealogis",
  pickup_date_charter: "Date ramassage affret",
  dde_prep: "DDE prep",
  dde_prep_sealogis: "DDE prep sealogis",
  dde_prep_charter: "DDE prep affret",
  order_state: "Etat commande",
  warehouse_status: "Statut entrepot",
  carrier_status: "Statut transport",
  customer_department: "CP client",
  asked_delivery_date: "Date liv. souhaitée",
  asked_appointment_date_charter: "DDE RDV affret",
  asked_appointment_date_sealogis: "DDE RDV sealogis",
  asked_appointment_date: "Date demande de RDV",
  appointment_date_charter: "Date RDV obtenu affret",
  appointment_date_transport: "Date RDV obtenu transport",
  appointment_date_sealogis: "Date RDV obtenu sealogis",
  appointment_date_messaging: "Date RDV obtenu",
  appointment_date: "Date RDV obtenu",
  real_delivery_date_charter: "Date liv. effective affret",
  real_delivery_date_transport: "Date liv. effective transport",
  real_delivery_date_sealogis: "Date liv. effective sealogis",
  real_delivery_date: "Date liv. effective",
  delivery_state: "Etat livraison",
  delivery_status: "Statut livraison",
  cde_gross_weight: "Poids brut CDE",
  nb_packages: "NB Colis",
  nb_bulk_packages: "Nb colis vrac",
  nb_pal: "NB Pal",
  carrier_code: "Code TPT",
  charter_reference: "Ref Affretement",
  commentary: "Commentaires",
  volume_type_remitter: "Remettant",
  volume_type_original: "Original",
  volume_type_theoretical: "Théorique",
  volume_type_real: "Transport",
  volume_type_edi: "Entrepot",
  volume_type_sealogis: "Sealogis",
  sealogis: "Sealogis",
  charter: "Affrètement",
  volume_type_log: "Entrepot",
  no_data: "Donnée manquante",
  no_values: "Aucune donnée",
  edit_blocked_title: "Edition bloquée",
  edit_blocked_content: "Un champ est en cours d'édition",
  edit_blocked_multi_content:
    "Le champs est en cours d'édition par un collaborateur",
  edit_blocked_by_rights_content: "Votre role ne vous permet pas l'édition",
  dispute_reason: "Motif litige",
  delay_reason: "Motif délais",
  responsibility: "Responsabilité",
  nc_details: "Détails NC",
  global_responsibility: "Responsabilité globale",
  client_cde: "Code Client",
  preparation_delay: "Délai de préparation",
  delivery_delay: "Délai de livraison",
  delivery_delay_jo: "Délai de livraison jour ouvrés",
  delivery_eca: "Ecarts RDV OBTENU",
  delivery_asked_eca: "Ecarts LIV SOUHAITEE",
  carrier_take_over: "Date de prise en charge transporteur",
  warning: "Attention",
  save_your_data: "Sauvegardez vos données",
  order_only_received:
    "Commande reçue, pas encore traitée par le(s) prestataire(s) de la première étape du flux.",
  tracking: "Tracking",
  POD: "POD",
  order_detail: "Détail CMD",
  edi_date: "Date EDI",
  edi_delay: "Délai EDI",
  sealogis_commentary: "Com Sealogis",
  delivery_instruction: "Com EDI 1",
  delivery_instruction_two: "Com EDI 2",
  cde_code: "Num CDE client destinataire",
  client_number: "Num cde client",
  client_name: "Nom client",
  delivered_client: "Client livré",
  signed_url_error: "Erreur lors de la récupération de votre document",
  create_date: "Date de création",
  edi_file: "EDI",
  edi_file_order: "Fichiers EDI de la commande",
  file_name: "Nom du fichier",
  entry: "Entrée",
  exit: "Sortie",
  unavailable_edi_file:
    "Fichier plus disponible, veuillez contacter le support.",
  upload: "Ajouter un fichier",
  get_files_remitter_error: "Erreur lors de la récupération des fichiers",
  get_file_type_error: "Erreur lors de la récupération des types de fichier",
  create_article_file_error: "Erreur durant l'ajout du fichier",
  create_file_remitter_error: "Erreur durant l'ajout du fichier",
  create_file_remitter_success: "Fichier ajouté avec succès",
  file_size_error: "Erreur, le fichier doit être inferieur a 4 Mo",
  delete_article_success: "Fichier Supprimé avec succès",
  delete_file_remitter_success: "Fichier Supprimé avec succès",
  delete_article_error: "Erreur durant la suppression du fichier",
  delete_file_remitter_error: "Erreur durant la suppression du fichier",
  confirm_action: "Êtes-vous sûr ?",
  doc: "Docs",
  title_doc: "Article(s) de la commande et document(s) joint(s)",
  calc_validated: "Calculs volumétriques validés",
  import_ged_order_tooltip: "GED de la commande",
  title_files_order: "Documents de la commande",
  title_files_STOCK: "Documents de type STOCK pour le remettant",
  title_files_ARRIVAL: "Documents de type ARRIVAGE pour le remettant",
  title_files_KPI: "Documents de type KPI pour le remettant",
  "title_files_PRICE LIST":
    "Documents de type GRILLE TARIFAIRE pour le remettant",
  sup_info: "Champs supp.",
  sup_info_m: "Champs supplémentaires",
  client_search_failed: "Erreur lors de la recherche d'un client",
  order_search_failed: "Erreur lors de la recherche d'une commande",
  nb_clients_total: "Nombre total:",
  precise_research: ", affinez votre recherche",
  new_order_notification: "Nouvelle(s) commande(s) intégrée(s)",
  new_order_notification_description:
    "Attention, vérifiez votre ligne/commande en édition",
  pod_file_view_or_upload: "Voir ou uploader un fichier POD ?",
  view_pod: "Voir la POD",
  upload_pod: "Uploader une POD",
  pod_modale_title: "POD",
  close: "Fermer",
  reset: "Reset",
  search: "Rechercher",

  order_pallet_get_error:
    "Erreur lors du chargement de la volumétrie de la commande",
  order_pallet_volumes: "Volumes",
  order_pallet_modal_title: "Volumétrie",
  article_table_code: "Ref",
  article_table_uvc_by_pallet: "Nb UVC pal homo",
  article_table_nb_uvc_ordered: "Nb UVC cde",
  article_table_nb_all_pallets: "Nb pal théorique",
  article_table_nb_full_pallets: "Equi pal homogène",
  article_table_nb_uvc_full: "Nb UVC homogène",
  article_table_nb_picking_pallets: "Equi pal picking",
  article_table_nb_uvc_picking: "Nb UVC picking",
  article_table_pallet_type: "Type palette",
  article_table_equivalent: "Equivalent 80x120",
  pallet_modal_no_data:
    "Pas de calcul de palette à cause d'un manque de données article(s)",
  pallet_modal_code: "Code EDI article / UVC",
  pallet_modal_nb_pallet: "Nombre palette",
  pallet_modal_weight: "Poids brut palette",
  pallet_modal_type: "Type palette",
  pallet_modal_equivalent: "Equivalent",
  pallet_modal_80_120_total: "Total 80x120",
  pallet_modal_100_120_total: "Total 100x120",
  pallet_modal_full_total: "Total (en 80x120)",

  edi_tracking_failure: "Erreur technique",
  edi_file_error: "Erreur de téléchargement du fichier",

  flux_type: "Type de flux",
  bcu_removal_location: "Lieux de chargement",
  bcu_removal_location_city: "Instruction lieux de chargement hors circuit",
  multi_removals: "Multi enlèvement",
  delivery_number: "Numéro de livraison",
  shipment_number: "Numéro d'expédition",
  order_num: "Numéro de commande",
  order_client_remitter_code: "N° Commande client",
  order_client_ship_to: "N° ship to",
  order_client_remitter_name: "Client",
  order_client_remitter_address: "Adresse",
  order_client_remitter_city: "Ville",
  order_client_remitter_zip_code: "CP",
  order_client_remitter_country: "Pays livraison",
  order_client_remitter_delivery_mode: "Mode de livraison",
  asked_initial_delivery_date: "Date de livraison souhaitée initiale",
  raw_weight: "Poids brut",
  removal_date: "Date d'enlèvement",
  removal_date_hour: "Heure d'enlèvement",
  bcu: "BCU",
  real_removal_date: "Date d'enlevement effective",
  transport_gap_reason: "Motif décallage ramasse",
  gap_responsibility: "Responsabilité décallage",
  free_commentary: "Commentaires libres BCU",
  free_commentary_sk: "Commentaires libres SK",
  free_commentary_sealogis: "Commentaires libres 5pl",
  gap_asked_delivery: "Ecart liv souhaitée",
  estimated_delivery: "Date liv estimative",
  gap_estimated_delivery: "Ecart estimation liv.",
  gap_removal_date: "Ecart enlèvement effectif",
  big_bag: "BIG BAG",
  stackable_bulk_box: "Octabin gerbable",
  bulk_box: "Octabin non gerbable",
  pallets: "Palette",
  total_unit_handling: "Total unité manutention",
  floor_meter: "Mètre plancher",
  gap_asked_real_delivery_date: "Ecarts livraison souhaitée et effective",
  shipment_status: "Statut shipment",
  transport_mode: "Mode transport",
  carrier_name: "Nom du transporteur",
  delivery_cost: "Coût du transport",
  delivery_cost_tax: "Coût du transport taxe",
  update_status_sap: "Mise à jour SAP statut",
  update_rate_sap: "Mise à jour SAP tarif",
  tgo_applied_price: "PRIX TGO appliqué",
  total_delivery_cost: "Tota coûts transport",
  extra_cost_start: "Coût extra cost au départ",
  extra_cost_start_reason: "Motif extra cost au départ",
  extra_cost_start_responsibility: "Responsabilité extra cost au départ",
  extra_cost_destination: "Coût extra cost à destination",
  extra_cost_destination_reason: "Motif extra cost à destination",
  extra_cost_destination_responsibility:
    "Responsabilité extra cost à destination",
  extra_cost_commentary: "Commentaire extra cost",
  is_carrier_assigned: "Transporteur affecté",
  is_volume_accepted: "Acceptation volumétrie",
  xc_status: "Validation extra cost",
  pdf: "PDF",
  xc_pending: "Extra cost en attente de validation",
  xc_validated: "Extra cost validé",
  xc_rejected: "Extra cost rejeté",
  update_disabled: "Modification impossible",
};

export default strings_fr;
