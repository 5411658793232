import { Typography } from "antd";

const { Text } = Typography;

const SimpleText = ({ value }) => {
  const isNeg = () => {
    if (value && typeof value === "number" && value < 0) {
      return "danger";
    }
    return "default";
  };
  return <Text type={isNeg()}>{value || "-"}</Text>;
};

export default SimpleText;
