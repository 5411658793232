import React from "react";
import { Button, Form, Input, Modal, Typography, Image } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import "./style.css";
import LogoSealogis from "../../../assets/images/logo-sealogis.png";

const Login = ({ loginRequest, strings, user_activated, closeModale }) => {
  const { Text } = Typography;

  const onFinish = (values) => {
    const { username, password } = values;
    loginRequest(username, password);
  };

  const handleOk = () => {
    closeModale();
  };

  return (
    <section id="allForm">
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Image preview={false} src={LogoSealogis} />
        <br />
        <br />
        <h1>{strings("title")}</h1>
        <Form.Item
          name="username"
          rules={[{ required: true, message: strings("empty_username") }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder={strings("username")}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: strings("empty_password") }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={strings("password")}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {strings("login")}
          </Button>
        </Form.Item>
      </Form>
      <Modal
        id="modalUserDesactivated"
        visible={user_activated !== true}
        title={strings("user_desactivated")}
        cancelButtonProps={{ style: { display: "none" } }}
        okText={strings("ok")}
        closable={true}
        onOk={handleOk}
      >
        <Text>{strings("modale_desactivated_content")}</Text>
      </Modal>
    </section>
  );
};

export default Login;
