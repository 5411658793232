import { call, put, takeLatest, select } from "redux-saga/effects";
import { types, default as PalletsActions } from "./actions";
import { PalletsService } from "../../services";
import { getTranslate } from "react-localize-redux";
import { showError } from "../../utils/notifications-helper";

function* getOrderPallets({ id }) {
  const [error, response] = yield call(PalletsService.getOrderPallets, id);
  if (!error && response) {
    yield put(
      PalletsActions.getOrderPalletsSuccess(
        response.pallets || [],
        response.article_orders || [],
      ),
    );
  } else {
    const locale = yield select((state) => state.locale);
    const strings = getTranslate(locale);
    showError("order_pallet_get_error", strings);
    yield put(PalletsActions.getOrderPalletsLoading(false));
  }
}

const sagas = [takeLatest(types.GET_ORDERS_PALLETS, getOrderPallets)];

export default sagas;
