import _ from "lodash";

export const resolveId = (router) => {
  const id = router.location.pathname.split("/").pop();
  if (!isNaN(id)) return id;
  return null;
};

export const resolveObjectFromStore = (router, storage, key) => {
  const id = router.location.pathname.split("/").pop();
  let found;
  if (_.isObject(storage) && id in storage) {
    found = _.get(storage, id);
  } else {
    const list = Array.isArray(storage) ? storage : Object.values(storage);
    const search = (p) => {
      return p[key] === id;
    };
    found = list.find(search);
  }
  return found;
};
