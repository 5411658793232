import { Button, notification } from "antd";
import React from "react";

export const showSuccess = (str, strings) => {
  notification.success({ message: strings(str) });
};
export const showError = (str, strings, description) => {
  notification.error({
    message: strings(str),
    description: description,
  });
};

export const showInfo = (str, strings, description, key) => {
  const btn = (
    <Button type="primary" size="small" onClick={() => notification.close(key)}>
      Ok
    </Button>
  );
  notification.config({ duration: 0 });
  notification.info({
    message: strings(str),
    description: description,
    btn,
    key,
  });
  notification.config({ duration: 6 });
};
export const showInfoBis = (str, description, key) => {
  const btn = (
    <Button type="primary" size="small" onClick={() => notification.close(key)}>
      Ok
    </Button>
  );
  notification.config({ duration: 0 });
  notification.info({
    message: str,
    description: description,
    btn,
    key,
  });
  notification.config({ duration: 6 });
};

export const hideInfo = (key) => {
  notification.close(key);
};
