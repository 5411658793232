import { ArrowLeftOutlined } from "@ant-design/icons";
import React from "react";

import ConfirmButton from "./ConfirmButton";

const BackButton = ({ loading, modify, onCancel, style }) => {
  return (
    <ConfirmButton
      loading={loading}
      ghost
      shape={"circle"}
      type={"primary"}
      icon={<ArrowLeftOutlined />}
      onClick={modify}
      onCancel={onCancel}
      style={style}
    />
  );
};

export default BackButton;
