import { BasicComponents } from "../basic";

const MultipleValueDisplay = ({
  value1,
  value2,
  value3,
  strings,
  id,
  keyValueOne = "original",
}) => {
  const disabled = value3 === value2 && value2 === value1;
  const title = `${strings(`volume_type_${keyValueOne}`)}: ${
    value1 || strings("no_data")
  }\n${strings("volume_type_edi")}: ${value2 || strings("no_data")}\n${strings(
    "volume_type_real",
  )}: ${value3 || strings("no_data")}`;
  const color = disabled ? "" : "orange";

  return (
    <BasicComponents.Tooltip
      overlayStyle={{ whiteSpace: "pre-line" }}
      title={!disabled && title}
      color="black"
    >
      <span id={id} style={{ color }}>
        {value3 || value2 || value1}
      </span>
    </BasicComponents.Tooltip>
  );
};

export default MultipleValueDisplay;
