import { React, useContext, useEffect, useMemo, useState } from "react";
import { DataTable } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import EditionComponent from "../../EditionComponent";
import { Button, Typography, Space, Badge } from "antd";
import { AbilityContext } from "../../../../../utils/casl/v2/can";
import { getFields } from "../../../../../utils/casl/v2/ability";
import {
  selectExtraCostReasonDestination,
  selectExtraCostReasonStart,
  selectOffsetPatternPickup,
  selectShipmentStatus,
  selectSKResponsibilities,
  StatusActions,
} from "../../../../../redux/status";
import { selectBcuData, selectPartnerCarrier } from "../../../../../redux/data";
import { DataActions } from "../../../../../redux/data";
import TrackinUrlButton from "../../../../components/TrackinUrlButton";
import { ShippingBox } from "../../../../../assets/svg";
import { AuditOutlined } from "@ant-design/icons";
import { selectXCStatus } from "../../../../../redux/data/selector";
import ImportGED from "../../fluxFilter/ImportGED";
import { selectClients } from "../../../../../redux/bl";

const DATE_COLUMN_WIDTH = 125;

export const SKBoard = ({
  strings = () => {},
  subLoading = false,
  isEdit = { index: -1, key: "" },
  handleEditionFieldClick = () => {},
  pagination = {},
  totalBls = 0,
  userRole,
  filters,
  setCurrentBL = () => {},
  setIsModalePODVisible = () => {},
  setCurrentPodFile = () => {},
  setCurrentReceiptUrl = () => {},
  onClickTracking = () => {},
}) => {
  const [valueList, setValueList] = useState([]);
  const dispatch = useDispatch();
  const list = useSelector((state) => state.bl.sk_bls);
  const canCtx = useContext(AbilityContext);
  const clients = useSelector(selectClients);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (dispatch) {
      const boardFields = getFields("board", "sk") || [];
      setFields(boardFields);
      dispatch(DataActions.xcStatusRequest());
      dispatch(DataActions.typeFluxRequest());
      dispatch(DataActions.bcuDataRequest());
      dispatch(DataActions.partnerCarrierRequest());
      dispatch(StatusActions.skRelatedStatusRequest());
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (list && isEdit.index === -1) {
      setValueList(list);
    }
  }, [list, isEdit.index]);

  const selectedClient = useMemo(() => {
    return clients?.find((client) => client?.code === filters?.client);
  }, [filters, clients]);

  const columns = [
    {
      title: strings("flux_type"),
      width: 100,
      dataIndex: "order_data_sk",
      align: "center",
      render: (order_data_sk) => (
        <span>{order_data_sk?.type_flux_sk?.label}</span>
      ),
    },
    {
      title: strings("bcu_removal_location"),
      width: 120,
      dataIndex: "bcu_removal_location",
      align: "center",
      render: (removal) => {
        const { code } = removal || {};
        return <span>{code}</span>;
      },
      csvExport: true,
      csvRender: (text) => text,
    },
    {
      title: strings("bcu_removal_location_city"),
      width: 200,
      align: "center",
      dataIndex: "bcu_removal_location_city",
      render: (bcu_removal_location_city, record, index) => {
        const key = "data_order_bcu.bcu_removal_location_city_id";
        return (
          <EditionComponent
            type="select"
            value={bcu_removal_location_city?.loading_zone}
            strings={strings}
            selector={selectBcuData}
            isBlocked={isEdit.index !== -1}
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            isEdit={index === isEdit.index && key === isEdit.key}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            updateInfos={{ key, id: record.id }}
          />
        );
      },
    },
    {
      title: strings("multi_removals"),
      width: 200,
      dataIndex: "multi_removals",
      align: "center",
      render: (multi_removals, record, index) => {
        const key = "multi_removals";
        const isEditingCell = index === isEdit.index && key === isEdit.key;
        return (
          <EditionComponent
            id={"edition-multi-removals"}
            type="text"
            value={multi_removals}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            isEdit={isEditingCell}
            setIsEdit={() =>
              handleEditionFieldClick({
                index,
                key,
                record,
              })
            }
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
          />
        );
      },
      csvExport: true,
      csvRender: (text) => text,
    },
    {
      title: strings("shipment_number"),
      width: 150,
      dataIndex: "bl_nums",
      align: "center",
      render: (values, record, index) => {
        const key = "data_order_bcu.shipment_number";
        const { sealogis, bl_num } = values;
        const isEditingCell = index === isEdit.index && key === isEdit.key;
        return (
          <EditionComponent
            id={"edition-sealogis-bl-num"}
            type="text"
            value={isEditingCell ? sealogis : sealogis || bl_num}
            style={{ color: sealogis ? "turquoise" : undefined, maxWidth: 120 }}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            isEdit={isEditingCell}
            setIsEdit={() =>
              handleEditionFieldClick({
                index,
                key,
                record,
              })
            }
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
            showTooltip={sealogis !== null}
            showTooltipReadOnly={true}
            tooltipTitle={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography>
                  {strings("shipment_number")}: {bl_num}
                </Typography>
                <Typography style={{ color: "turquoise" }}>
                  {strings("bcu")}: {sealogis}
                </Typography>
              </div>
            }
          />
        );
      },
      csvExport: true,
      csvRender: (text) => text,
    },
    {
      title: strings("delivery_number"),
      width: 120,
      dataIndex: "delivery_num",
      align: "center",
      render: (text) => <span>{text}</span>,
    },
    {
      title: strings("order_num"),
      width: 120,
      dataIndex: "order_num",
      align: "center",
      render: (text) => <span>{text}</span>,
    },
    {
      title: strings("order_client_remitter_code"),
      width: 120,
      dataIndex: "client_remitter",
      align: "center",
      render: (client) => <span>{client?.code}</span>,
    },
    {
      title: strings("order_client_ship_to"),
      width: 120,
      dataIndex: "delivery_codes",
      render: (data, record, index) => {
        const key = "data_order_sealogis.delivery_code";
        const { classical, sealogis } = data || {};
        const isEditingCell = index === isEdit.index && key === isEdit.key;
        return (
          <EditionComponent
            id={"edition-ship-to"}
            type="text"
            value={isEditingCell ? sealogis : sealogis || classical}
            style={{ color: sealogis ? "blue" : "", maxWidth: 120 }}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            isEdit={isEditingCell}
            setIsEdit={() =>
              handleEditionFieldClick({
                index,
                key,
                record,
              })
            }
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            showTooltip={sealogis !== null}
            showTooltipReadOnly={true}
            tooltipTitle={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ color: "" }}>
                  {strings("pdf")}: {classical}
                </Typography>
                <Typography style={{ color: "blue" }}>
                  {strings("sealogis")}: {sealogis}
                </Typography>
              </div>
            }
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
          />
        );
      },
    },
    {
      title: strings("order_client_remitter_name"),
      width: 120,
      dataIndex: "client_remitter",
      align: "center",
      render: (client) => <span>{client?.name}</span>,
    },
    {
      title: strings("order_client_remitter_address"),
      width: 120,
      dataIndex: "client_remitter",
      align: "center",
      render: (client) => <span>{client?.addr1}</span>,
    },
    {
      title: strings("order_client_remitter_city"),
      width: 120,
      dataIndex: "client_remitter",
      align: "center",
      render: (client) => <span>{client?.city}</span>,
    },
    {
      title: strings("order_client_remitter_zip_code"),
      width: 100,
      dataIndex: "client_remitter",
      align: "center",
      render: (client) => <span>{client?.zip_code}</span>,
    },
    {
      title: strings("order_client_remitter_country"),
      width: 120,
      dataIndex: "client_remitter",
      align: "center",
      render: (client) => <span>{client?.country_code}</span>,
    },
    {
      title: strings("order_client_remitter_delivery_mode"),
      width: 100,
      dataIndex: "client_remitter",
      align: "center",
      render: (client) => <span>{client?.delivery_mode}</span>,
    },
    {
      title: strings("removal_date"),
      width: 120,
      dataIndex: "removal_dates",
      align: "center",
      render: (dates, record, index) => {
        const isBcu = userRole === "BCU";
        const key = `data_order_${isBcu ? "bcu" : "sealogis"}.removal_date`;
        const { bcu, bcuRaw, sealogis, sealogisRaw } = dates;
        const editValue = isBcu ? bcuRaw : sealogisRaw;
        const isEditingCell = index === isEdit.index && key === isEdit.key;
        return (
          <EditionComponent
            type="date"
            value={isEditingCell ? editValue : sealogis || bcu}
            format={"dd/MM/yy HH:mm"}
            style={{
              color: sealogis ? "blue" : "turquoise",
              maxWidth: 170,
              alignItems: "start",
            }}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            isEdit={isEditingCell}
            setIsEdit={() =>
              handleEditionFieldClick({
                index,
                key,
                record,
              })
            }
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
            showTooltip={bcu !== null}
            tooltipTitle={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ color: "turquoise" }}>
                  {strings("bcu")}: {bcu}
                </Typography>
                <Typography style={{ color: "blue" }}>
                  {strings("sealogis")}: {sealogis}
                </Typography>
              </div>
            }
          />
        );
      },
    },
    {
      title: strings("removal_date_hour"),
      width: 120,
      dataIndex: "removal_date_hours",
      align: "center",
      render: (dates, record, index) => {
        const isBcu = userRole === "BCU";
        const key = `data_order_${
          isBcu ? "bcu" : "sealogis"
        }.removal_date_hour`;
        const { bcu, bcuRaw, sealogis, sealogisRaw } = dates;
        const editValue = isBcu ? bcuRaw : sealogisRaw;
        const isEditingCell = index === isEdit.index && key === isEdit.key;
        return (
          <EditionComponent
            type="time"
            format={"HH:mm"}
            value={isEditingCell ? editValue : sealogis || bcu}
            style={{
              color: sealogis ? "blue" : "turquoise",
              maxWidth: 170,
              alignItems: "start",
            }}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            isEdit={isEditingCell}
            setIsEdit={() =>
              handleEditionFieldClick({
                index,
                key,
                record,
              })
            }
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
            showTooltip={bcu !== null}
            tooltipTitle={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ color: "turquoise" }}>
                  {strings("bcu")}: {bcu}
                </Typography>
                <Typography style={{ color: "blue" }}>
                  {strings("sealogis")}: {sealogis}
                </Typography>
              </div>
            }
          />
        );
      },
    },
    {
      title: strings("real_removal_date"),
      width: DATE_COLUMN_WIDTH,
      dataIndex: "real_removal_dates",
      align: "center",
      render: (dates, record, index) => {
        const key = `data_order_sealogis.real_removal_date`;
        const { sealogis, telia } = dates || {};
        const isEditingCell = index === isEdit.index && key === isEdit.key;
        return (
          <EditionComponent
            type="date"
            value={isEditingCell ? sealogis : sealogis || telia}
            style={{
              color: sealogis ? "blue" : "turquoise",
              maxWidth: 170,
              alignItems: "start",
            }}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            isEdit={isEditingCell}
            setIsEdit={() =>
              handleEditionFieldClick({
                index,
                key,
                record,
              })
            }
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
            showTooltip={telia !== null}
            tooltipTitle={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ color: "turquoise" }}>
                  {strings("teliae_data")}: {telia}
                </Typography>
                <Typography style={{ color: "blue" }}>
                  {strings("sealogis")}: {sealogis}
                </Typography>
              </div>
            }
          />
        );
      },
    },
    {
      title: strings("transport_gap_reason"),
      width: 250,
      dataIndex: "order_data_sealogis",
      align: "center",
      render: (values, record, index) => {
        const { offset_pattern_pickup } = values || {};
        const key = "data_order_sealogis.offset_pattern_pickup_id";
        return (
          <EditionComponent
            type="select"
            value={offset_pattern_pickup?.sealogis_code}
            strings={strings}
            selector={selectOffsetPatternPickup}
            optionsStyle={{ dropdownMatchSelectWidth: false }}
            isBlocked={isEdit.index !== -1}
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            isEdit={index === isEdit.index && key === isEdit.key}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            updateInfos={{ key, id: record.id }}
          />
        );
      },
    },
    {
      title: strings("gap_responsibility"),
      width: 200,
      dataIndex: "order_data_sealogis",
      align: "center",
      render: (values, record, index) => {
        const { offset_responsibility } = values || {};
        const key = "data_order_sealogis.offset_responsibility_id";
        return (
          <EditionComponent
            type="select"
            value={offset_responsibility?.sealogis_code}
            strings={strings}
            selector={selectSKResponsibilities}
            isBlocked={isEdit.index !== -1}
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            isEdit={index === isEdit.index && key === isEdit.key}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            updateInfos={{ key, id: record.id }}
          />
        );
      },
    },
    {
      title: strings("asked_delivery_date"),
      width: DATE_COLUMN_WIDTH,
      dataIndex: "formatted_bcu_asked_delivery_date",
      align: "center",
      render: (asked_delivery_date, record, index) => {
        const key = "data_order_bcu.asked_delivery_date";
        const isEditingCell = index === isEdit.index && key === isEdit.key;
        return (
          <EditionComponent
            type="date"
            value={asked_delivery_date}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            isEdit={isEditingCell}
            setIsEdit={() =>
              handleEditionFieldClick({
                index,
                key,
                record,
              })
            }
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
          />
        );
      },
    },
    {
      title: strings("free_commentary"),
      width: 200,
      dataIndex: "order_data_bcu",
      align: "center",
      render: (data, record, index) => {
        const key = "data_order_bcu.commentary";
        const { commentary } = data || {};
        const isEditingCell = index === isEdit.index && key === isEdit.key;
        return (
          <EditionComponent
            id={"edition-bcu-free-commentary"}
            type="text"
            value={commentary}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            isEdit={isEditingCell}
            setIsEdit={() =>
              handleEditionFieldClick({
                index,
                key,
                record,
              })
            }
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
          />
        );
      },
      csvExport: true,
      csvRender: (text) => text,
    },
    {
      title: strings("free_commentary_sk"),
      width: 200,
      dataIndex: "order_data_sk",
      align: "center",
      render: (data, record, index) => {
        const key = "data_order_sk.commentary";
        const { commentary } = data || {};
        const isEditingCell = index === isEdit.index && key === isEdit.key;
        return (
          <EditionComponent
            id={"edition-sk-free-commentary"}
            type="text"
            value={commentary}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            isEdit={isEditingCell}
            setIsEdit={() =>
              handleEditionFieldClick({
                index,
                key,
                record,
              })
            }
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
          />
        );
      },
      csvExport: true,
      csvRender: (text) => text,
    },
    {
      title: strings("free_commentary_sealogis"),
      width: 200,
      dataIndex: "order_data_sealogis",
      align: "center",
      render: (data, record, index) => {
        const key = "data_order_sealogis.commentary";
        const { commentary } = data || {};
        const isEditingCell = index === isEdit.index && key === isEdit.key;
        return (
          <EditionComponent
            id={"edition-sealogis-free-commentary"}
            type="text"
            value={commentary}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            isEdit={isEditingCell}
            setIsEdit={() =>
              handleEditionFieldClick({
                index,
                key,
                record,
              })
            }
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
          />
        );
      },
      csvExport: true,
      csvRender: (text) => text,
    },
    {
      title: strings("asked_initial_delivery_date"),
      width: DATE_COLUMN_WIDTH,
      dataIndex: "formatted_asked_delivery_date",
      align: "center",
      render: (date) => <span>{date}</span>,
    },
    {
      title: strings("gap_asked_delivery"),
      width: 120,
      dataIndex: "gap_asked_delivery",
      align: "center",
      render: (data) => <span>{data}</span>,
    }, // @TODO: connect to real data
    {
      title: strings("estimated_delivery"),
      width: 120,
      dataIndex: "estimated_delivery",
      align: "center",
      render: (data) => <span>{data}</span>,
    }, // @TODO: connect to real data
    {
      title: strings("gap_estimated_delivery"),
      width: 120,
      dataIndex: "gap_estimated_delivery",
      align: "center",
      render: (data) => <span>{data}</span>,
    }, // @TODO: connect to real data
    {
      title: strings("gap_removal_date"),
      width: 120,
      dataIndex: "gap_removal_date",
      customFilterKey: "gap_removal_date",
      align: "center",
      render: (data) => <span>{data}</span>,
    }, // @TODO: connect to real data
    {
      title: strings("big_bag"),
      width: 120,
      dataIndex: "volumes",
      align: "center",
      render: (volumes, record, index) => {
        const isBcu = userRole === "BCU";
        const key = `${isBcu ? "bcu" : "sealogis"}_volumes.nb_big_bag`;
        const { theoretical, log, real } = volumes?.all;
        const tData = theoretical?.nb_big_bag;
        const rData = real?.nb_big_bag;
        const lData = log?.nb_big_bag;
        const useData = lData || rData || tData;
        const isEditingCell = index === isEdit.index && key === isEdit.key;
        const isCalcValidated = record?.order_data_sealogis?.is_volume_accepted;
        return (
          <EditionComponent
            type="number"
            value={isEditingCell ? (isBcu ? rData : lData) : useData}
            style={{
              color: lData ? "blue" : rData ? "turquoise" : "#000000A6",
              maxWidth: 170,
              alignItems: "start",
            }}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            disabledBtn={
              isCalcValidated ? strings("calc_validated") : undefined
            }
            isEdit={isEditingCell}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() => handleEditionFieldClick({ index: -1, key, record })}
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
            showTooltip={rData || lData}
            showTooltipReadOnly={true}
            tooltipTitle={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ color: "#000000A6" }}>
                  {strings("theoretical")}: {tData}
                </Typography>
                <Typography style={{ color: "turquoise" }}>
                  {strings("bcu")}: {rData}
                </Typography>
                <Typography style={{ color: "blue" }}>
                  {strings("sealogis")}: {lData}
                </Typography>
              </div>
            }
          />
        );
      },
    },
    {
      title: strings("stackable_bulk_box"),
      width: 120,
      dataIndex: "volumes",
      align: "center",
      render: (volumes, record, index) => {
        const isBcu = userRole === "BCU";
        const key = `${
          isBcu ? "bcu" : "sealogis"
        }_volumes.nb_stackable_bulk_packages`;
        const { theoretical, log, real } = volumes?.all;
        const tData = theoretical?.nb_stackable_bulk_packages;
        const rData = real?.nb_stackable_bulk_packages;
        const lData = log?.nb_stackable_bulk_packages;
        const useData = lData || rData || tData;
        const isEditingCell = index === isEdit.index && key === isEdit.key;
        const isCalcValidated = record?.order_data_sealogis?.is_volume_accepted;
        return (
          <EditionComponent
            type="number"
            value={isEditingCell ? (isBcu ? rData : lData) : useData}
            style={{
              color: lData ? "blue" : rData ? "turquoise" : "#000000A6",
              maxWidth: 170,
              alignItems: "start",
            }}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            disabledBtn={
              isCalcValidated ? strings("calc_validated") : undefined
            }
            isEdit={isEditingCell}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() => handleEditionFieldClick({ index: -1, key, record })}
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
            showTooltip={rData || lData}
            showTooltipReadOnly={true}
            tooltipTitle={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ color: "#000000A6" }}>
                  {strings("theoretical")}: {tData}
                </Typography>
                <Typography style={{ color: "turquoise" }}>
                  {strings("bcu")}: {rData}
                </Typography>
                <Typography style={{ color: "blue" }}>
                  {strings("sealogis")}: {lData}
                </Typography>
              </div>
            }
          />
        );
      },
    },
    {
      title: strings("bulk_box"),
      width: 120,
      dataIndex: "volumes",
      align: "center",
      render: (volumes, record, index) => {
        const isBcu = userRole === "BCU";
        const key = `${isBcu ? "bcu" : "sealogis"}_volumes.nb_bulk_packages`;
        const { theoretical, log, real } = volumes?.all;
        const tData = theoretical?.nb_bulk_packages;
        const rData = real?.nb_bulk_packages;
        const lData = log?.nb_bulk_packages;
        const useData = lData || rData || tData;
        const isEditingCell = index === isEdit.index && key === isEdit.key;
        const isCalcValidated = record?.order_data_sealogis?.is_volume_accepted;
        return (
          <EditionComponent
            type="number"
            value={isEditingCell ? (isBcu ? rData : lData) : useData}
            style={{
              color: lData ? "blue" : rData ? "turquoise" : "#000000A6",
              maxWidth: 170,
              alignItems: "start",
            }}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            disabledBtn={
              isCalcValidated ? strings("calc_validated") : undefined
            }
            isEdit={isEditingCell}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() => handleEditionFieldClick({ index: -1, key, record })}
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
            showTooltip={rData || lData}
            showTooltipReadOnly={true}
            tooltipTitle={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ color: "#000000A6" }}>
                  {strings("theoretical")}: {tData}
                </Typography>
                <Typography style={{ color: "turquoise" }}>
                  {strings("bcu")}: {rData}
                </Typography>
                <Typography style={{ color: "blue" }}>
                  {strings("sealogis")}: {lData}
                </Typography>
              </div>
            }
          />
        );
      },
    },
    {
      title: strings("pallets"),
      width: 120,
      dataIndex: "volumes",
      align: "center",
      render: (volumes, record, index) => {
        const isBcu = userRole === "BCU";
        const key = `${isBcu ? "bcu" : "sealogis"}_volumes.nb_pallets`;
        const { theoretical, log, real } = volumes?.all;
        const tData = theoretical?.nb_pallets;
        const rData = real?.nb_pallets;
        const lData = log?.nb_pallets;
        const useData = lData || rData || tData;
        const isEditingCell = index === isEdit.index && key === isEdit.key;
        const isCalcValidated = record?.order_data_sealogis?.is_volume_accepted;
        return (
          <EditionComponent
            type="number"
            value={isEditingCell ? (isBcu ? rData : lData) : useData}
            style={{
              color: lData ? "blue" : rData ? "turquoise" : "#000000A6",
              maxWidth: 170,
              alignItems: "start",
            }}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            disabledBtn={
              isCalcValidated ? strings("calc_validated") : undefined
            }
            isEdit={isEditingCell}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() => handleEditionFieldClick({ index: -1, key, record })}
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
            showTooltip={rData || lData}
            showTooltipReadOnly={true}
            tooltipTitle={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ color: "#000000A6" }}>
                  {strings("theoretical")}: {tData}
                </Typography>
                <Typography style={{ color: "turquoise" }}>
                  {strings("bcu")}: {rData}
                </Typography>
                <Typography style={{ color: "blue" }}>
                  {strings("sealogis")}: {lData}
                </Typography>
              </div>
            }
          />
        );
      },
    },
    {
      title: strings("total_unit_handling"),
      width: 120,
      dataIndex: "volumes",
      align: "center",
      render: (volumes, record, index) => {
        const isBcu = userRole === "BCU";
        const key = `${isBcu ? "bcu" : "sealogis"}_volumes.nb_total_packages`;
        const { theoretical, log, real } = volumes?.all;
        const tData = theoretical?.nb_total_packages;
        const rData = real?.nb_total_packages;
        const lData = log?.nb_total_packages;
        const useData = lData || rData || tData;
        const isEditingCell = index === isEdit.index && key === isEdit.key;
        const isCalcValidated = record?.order_data_sealogis?.is_volume_accepted;
        return (
          <EditionComponent
            type="number"
            value={isEditingCell ? (isBcu ? rData : lData) : useData}
            style={{
              color: lData ? "blue" : rData ? "turquoise" : "#000000A6",
              maxWidth: 170,
              alignItems: "start",
            }}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            disabledBtn={
              isCalcValidated ? strings("calc_validated") : undefined
            }
            isEdit={isEditingCell}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() => handleEditionFieldClick({ index: -1, key, record })}
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
            showTooltip={rData || lData}
            showTooltipReadOnly={rData || lData}
            tooltipTitle={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ color: "#000000A6" }}>
                  {strings("theoretical")}: {tData}
                </Typography>
                <Typography style={{ color: "turquoise" }}>
                  {strings("bcu")}: {rData}
                </Typography>
                <Typography style={{ color: "blue" }}>
                  {strings("sealogis")}: {lData}
                </Typography>
              </div>
            }
          />
        );
      },
    },
    {
      title: strings("article_table_equivalent"),
      width: 120,
      dataIndex: "volumes",
      align: "center",
      render: (volumes, record, index) => {
        const isBcu = userRole === "BCU";
        const key = `${
          isBcu ? "bcu" : "sealogis"
        }_volumes.equivalent_80_per_120`;
        const { theoretical, log, real } = volumes?.all;
        const tData = theoretical?.equivalent_80_per_120;
        const rData = real?.equivalent_80_per_120;
        const lData = log?.equivalent_80_per_120;
        const useData = lData || rData || tData;
        const isEditingCell = index === isEdit.index && key === isEdit.key;
        const isCalcValidated = record?.order_data_sealogis?.is_volume_accepted;
        return (
          <EditionComponent
            type="number"
            value={isEditingCell ? (isBcu ? rData : lData) : useData}
            style={{
              color: lData ? "blue" : rData ? "turquoise" : "#000000A6",
              maxWidth: 170,
              alignItems: "start",
            }}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            disabledBtn={
              isCalcValidated ? strings("calc_validated") : undefined
            }
            isEdit={isEditingCell}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() => handleEditionFieldClick({ index: -1, key, record })}
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
            showTooltip={rData || lData}
            showTooltipReadOnly={rData || lData}
            tooltipTitle={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ color: "#000000A6" }}>
                  {strings("theoretical")}: {tData}
                </Typography>
                <Typography style={{ color: "turquoise" }}>
                  {strings("bcu")}: {rData}
                </Typography>
                <Typography style={{ color: "blue" }}>
                  {strings("sealogis")}: {lData}
                </Typography>
              </div>
            }
          />
        );
      },
    },
    {
      title: strings("raw_weight"),
      width: 120,
      dataIndex: "volumes",
      align: "center",
      render: (volumes, record, index) => {
        const isBcu = userRole === "BCU";
        const key = `${isBcu ? "bcu" : "sealogis"}_volumes.raw_weight`;
        const { theoretical, log, real } = volumes?.all;
        const tData = theoretical?.raw_weight;
        const rData = real?.raw_weight;
        const lData = log?.raw_weight;
        const isEditingCell = index === isEdit.index && key === isEdit.key;
        const useData = lData || rData || tData;
        const isCalcValidated = record?.order_data_sealogis?.is_volume_accepted;
        return (
          <EditionComponent
            type="number"
            value={isEditingCell ? (isBcu ? rData : lData) : useData}
            style={{
              color: lData ? "blue" : rData ? "turquoise" : "#000000A6",
              maxWidth: 170,
              alignItems: "start",
            }}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            disabledBtn={
              isCalcValidated ? strings("calc_validated") : undefined
            }
            isEdit={isEditingCell}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() => handleEditionFieldClick({ index: -1, key, record })}
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
            showTooltip={rData || lData}
            showTooltipReadOnly={rData || lData}
            tooltipTitle={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ color: "#000000A6" }}>
                  {strings("theoretical")}: {tData}
                </Typography>
                <Typography style={{ color: "turquoise" }}>
                  {strings("bcu")}: {rData}
                </Typography>
                <Typography style={{ color: "blue" }}>
                  {strings("sealogis")}: {lData}
                </Typography>
              </div>
            }
          />
        );
      },
    },
    {
      title: strings("is_volume_accepted"),
      width: 120,
      dataIndex: "order_data_sealogis",
      align: "center",
      render: (value, record, index) => {
        const key = `data_order_sealogis.is_volume_accepted`;
        const val = value?.is_volume_accepted;
        return (
          <EditionComponent
            id={key}
            type="select-boolean"
            value={val ?? false}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            isEdit={index === isEdit.index && key === isEdit.key}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            updateInfos={{ key, id: record.id }}
          />
        );
      },
      csvExport: true,
      csvRender: (text) => <span>{text ? strings("yes") : strings("no")}</span>,
    },
    {
      title: strings("real_delivery_date"),
      width: DATE_COLUMN_WIDTH,
      dataIndex: "formatted_real_delivery_date",
      align: "center",
      customFilterKey: "real_delivery_date",
      render: (date, record, index) => {
        const key = `real_delivery_date`;
        const isEditingCell = index === isEdit.index && key === isEdit.key;
        return (
          <EditionComponent
            type="date"
            value={date}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            isEdit={isEditingCell}
            setIsEdit={() =>
              handleEditionFieldClick({
                index,
                key,
                record,
              })
            }
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
          />
        );
      },
    },
    {
      title: strings("gap_asked_real_delivery_date"),
      dataIndex: "gap_asked_real_delivery_date",
      customFilterKey: "gap_asked_real_delivery_date",
      width: 120,
      align: "center",
      render: (data) => <span>{data}</span>,
    }, // @TODO: connect to real data
    {
      title: strings("shipment_status"),
      dataIndex: "order_data_sealogis",
      align: "center",
      width: 200,
      render: (values, record, index) => {
        const { shipment_status } = values || {};
        const key = "data_order_sealogis.shipment_status_id";
        return (
          <EditionComponent
            type="select"
            value={shipment_status?.sealogis_code}
            strings={strings}
            selector={selectShipmentStatus}
            isBlocked={isEdit.index !== -1}
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            isEdit={index === isEdit.index && key === isEdit.key}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            updateInfos={{ key, id: record.id }}
          />
        );
      },
    },
    {
      title: strings("transport_mode"),
      dataIndex: "transport_mode",
      width: 120,
      align: "center",
      render: (data) => <span>{data}</span>,
    }, // @TODO: connect to real data
    {
      title: strings("carrier_name"),
      dataIndex: "order_data_sealogis",
      width: 200,
      align: "center",
      render: (values, record, index) => {
        const { partner } = values || {};
        const key = "data_order_sealogis.partner_carrier_id";
        return (
          <EditionComponent
            type="select"
            value={partner?.sealogis_code}
            strings={strings}
            selector={selectPartnerCarrier}
            showSearch={true}
            isBlocked={isEdit.index !== -1}
            disabledBtn={
              record.order_data_sealogis?.is_carrier_assigned
                ? strings("is_carrier_assigned")
                : undefined
            }
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            isEdit={index === isEdit.index && key === isEdit.key}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            updateInfos={{ key, id: record.id }}
          />
        );
      },
    },
    {
      title: strings("is_carrier_assigned"),
      width: 120,
      dataIndex: "order_data_sealogis",
      align: "center",
      render: (value, record, index) => {
        const key = `data_order_sealogis.is_carrier_assigned`;
        const val = value?.is_carrier_assigned;
        return (
          <EditionComponent
            id={key}
            type="select-boolean"
            value={val ?? false}
            strings={strings}
            disabled={!record?.order_data_sealogis?.partner}
            disabledBtn={
              record.order_data_sealogis?.is_carrier_assigned
                ? strings("is_carrier_assigned")
                : undefined
            }
            isBlocked={isEdit.index !== -1}
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            isEdit={index === isEdit.index && key === isEdit.key}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            updateInfos={{ key, id: record.id }}
          />
        );
      },
      csvExport: true,
      csvRender: (text) => <span>{text ? strings("yes") : strings("no")}</span>,
    },
    {
      title: strings("update_status_sap"),
      dataIndex: "order_data_sealogis",
      customFilterKey: "update_status_sap",
      width: 120,
      align: "center",
      render: (values, record, index) => {
        const { update_status_SAP } = values || {};
        const key = "data_order_sealogis.update_status_SAP";
        return (
          <EditionComponent
            type="checkbox"
            value={update_status_SAP ? "OK" : false}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            isEdit={index === isEdit.index && key === isEdit.key}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            updateInfos={{ key, id: record.id }}
          />
        );
      },
    },
    {
      title: strings("update_rate_sap"),
      dataIndex: "order_data_sealogis",
      customFilterKey: "update_rate_sap",
      width: 120,
      align: "center",
      render: (values, record, index) => {
        const { update_rate_SAP } = values || {};
        const key = "data_order_sealogis.update_rate_SAP";
        return (
          <EditionComponent
            type="checkbox"
            value={update_rate_SAP ? "OK" : false}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            isEdit={index === isEdit.index && key === isEdit.key}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            updateInfos={{ key, id: record.id }}
          />
        );
      },
    },
    {
      title: strings("appointment_date"),
      width: 120,
      align: "center",
      dataIndex: "a",
      customFilterKey: "appointment_date",
      render: (data) => <span>{data}</span>,
    }, // @TODO: connect to real data
    {
      title: strings("carrier_status"),
      width: 120,
      align: "center",
      dataIndex: "a",
      customFilterKey: "carrier_status",
      render: (data) => <span>{data}</span>,
    }, // @TODO: connect to real data
    {
      title: strings("o_delivery_instructions"),
      width: 170,
      align: "center",
      dataIndex: "a",
      customFilterKey: "delivery_instructions",
      render: (data) => <span>{data}</span>,
    }, // @TODO: connect to real data
    {
      title: strings("delivery_cost"),
      width: 120,
      align: "center",
      dataIndex: "a",
      customFilterKey: "delivery_cost",
      render: (data) => <span>{data}</span>,
    }, // @TODO: connect to real data
    {
      title: strings("delivery_cost_tax"),
      width: 120,
      align: "center",
      dataIndex: "a",
      customFilterKey: "delivery_cost_tax",
      render: (data) => <span>{data}</span>,
    }, // @TODO: connect to real data
    {
      title: strings("tgo_applied_price"),
      width: 120,
      align: "center",
      dataIndex: "a",
      customFilterKey: "tgo_applied_price",
      render: (data) => <span>{data}</span>,
    }, // @TODO: connect to real data
    {
      title: strings("total_delivery_cost"),
      width: 120,
      align: "center",
      dataIndex: "a",
      customFilterKey: "total_delivery_cost",
      render: (data) => <span>{data}</span>,
    }, // @TODO: connect to real data,
    {
      title: strings("charter_reference"),
      dataIndex: "charter_reference",
      align: "center",
      width: 200,
      customFilterKey: "charter_reference",
      render: (charter_reference, record, index) => {
        const key = "charter_reference";
        return (
          <EditionComponent
            id={"edition-charter_reference-sk"}
            type="text"
            value={charter_reference}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            isEdit={index === isEdit.index && key === isEdit.key}
            setIsEdit={() =>
              handleEditionFieldClick({
                index,
                key,
                record,
              })
            }
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
          />
        );
      },
    },
    {
      title: strings("extra_cost_start"),
      width: 200,
      align: "center",
      dataIndex: "order_data_sealogis",
      customFilterKey: "extra_cost_start",
      render: (data, record, index) => {
        const key = "data_order_sealogis.extra_cost_start";
        const { extra_cost_start } = data || {};
        const xcCode = record?.order_data_sealogis?.xc_status?.code;
        return (
          <EditionComponent
            id={"edition-sealogis-free-commentary"}
            type="number"
            value={extra_cost_start}
            disabledBtn={
              xcCode ? strings(`xc_${xcCode?.toLowerCase()}`) : undefined
            }
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            isEdit={index === isEdit.index && key === isEdit.key}
            setIsEdit={() =>
              handleEditionFieldClick({
                index,
                key,
                record,
              })
            }
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
          />
        );
      },
    },
    {
      title: strings("extra_cost_start_reason"),
      width: 200,
      align: "center",
      dataIndex: "order_data_sealogis",
      customFilterKey: "extra_cost_start_reason",
      render: (values, record, index) => {
        const { extra_cost_reason_start } = values || {};
        const key = "data_order_sealogis.extra_cost_reason_start_id";
        const xcCode = record?.order_data_sealogis?.xc_status?.code;
        return (
          <EditionComponent
            type="select"
            value={extra_cost_reason_start?.sealogis_code}
            strings={strings}
            selector={selectExtraCostReasonStart}
            disabledBtn={
              xcCode ? strings(`xc_${xcCode?.toLowerCase()}`) : undefined
            }
            optionsStyle={{ dropdownMatchSelectWidth: false }}
            isBlocked={isEdit.index !== -1}
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            isEdit={index === isEdit.index && key === isEdit.key}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            updateInfos={{ key, id: record.id }}
          />
        );
      },
    },
    {
      title: strings("extra_cost_start_responsibility"),
      width: 200,
      align: "center",
      dataIndex: "order_data_sealogis",
      customFilterKey: "extra_cost_start_responsibility",
      render: (values, record, index) => {
        const { extra_cost_reason_start_responsibility } = values || {};
        const key =
          "data_order_sealogis.extra_cost_reason_start_responsibility_id";
        const xcCode = record?.order_data_sealogis?.xc_status?.code;
        return (
          <EditionComponent
            type="select"
            value={extra_cost_reason_start_responsibility?.sealogis_code}
            strings={strings}
            disabledBtn={
              xcCode ? strings(`xc_${xcCode?.toLowerCase()}`) : undefined
            }
            selector={selectSKResponsibilities}
            isBlocked={isEdit.index !== -1}
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            isEdit={index === isEdit.index && key === isEdit.key}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            updateInfos={{ key, id: record.id }}
          />
        );
      },
    },
    {
      title: strings("extra_cost_destination"),
      width: 200,
      align: "center",
      dataIndex: "order_data_sealogis",
      customFilterKey: "extra_cost_destination",
      render: (data, record, index) => {
        const key = "data_order_sealogis.extra_cost_destination";
        const { extra_cost_destination } = data || {};
        const xcCode = record?.order_data_sealogis?.xc_status?.code;
        return (
          <EditionComponent
            id={"edition-sealogis-free-commentary"}
            type="number"
            value={extra_cost_destination}
            strings={strings}
            disabledBtn={
              xcCode ? strings(`xc_${xcCode?.toLowerCase()}`) : undefined
            }
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            isEdit={index === isEdit.index && key === isEdit.key}
            setIsEdit={() =>
              handleEditionFieldClick({
                index,
                key,
                record,
              })
            }
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
          />
        );
      },
    },
    {
      title: strings("extra_cost_destination_reason"),
      width: 200,
      align: "center",
      dataIndex: "order_data_sealogis",
      customFilterKey: "extra_cost_destination_reason",
      render: (values, record, index) => {
        const { extra_cost_reason_destination } = values || {};
        const key = "data_order_sealogis.extra_cost_reason_destination_id";
        const xcCode = record?.order_data_sealogis?.xc_status?.code;
        return (
          <EditionComponent
            type="select"
            value={extra_cost_reason_destination?.sealogis_code}
            strings={strings}
            disabledBtn={
              xcCode ? strings(`xc_${xcCode?.toLowerCase()}`) : undefined
            }
            selector={selectExtraCostReasonDestination}
            optionsStyle={{ dropdownMatchSelectWidth: false }}
            isBlocked={isEdit.index !== -1}
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            isEdit={index === isEdit.index && key === isEdit.key}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            updateInfos={{ key, id: record.id }}
          />
        );
      },
    },
    {
      title: strings("extra_cost_destination_responsibility"),
      width: 200,
      align: "center",
      dataIndex: "order_data_sealogis",
      customFilterKey: "extra_cost_destination_responsibility",
      render: (values, record, index) => {
        const { extra_cost_reason_destination_responsibility } = values || {};
        const xcCode = record?.order_data_sealogis?.xc_status?.code;
        const key =
          "data_order_sealogis.extra_cost_reason_destination_responsibility_id";
        return (
          <EditionComponent
            type="select"
            value={extra_cost_reason_destination_responsibility?.sealogis_code}
            strings={strings}
            disabledBtn={
              xcCode ? strings(`xc_${xcCode?.toLowerCase()}`) : undefined
            }
            selector={selectSKResponsibilities}
            isBlocked={isEdit.index !== -1}
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            isEdit={index === isEdit.index && key === isEdit.key}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            updateInfos={{ key, id: record.id }}
          />
        );
      },
    },
    {
      title: strings("extra_cost_commentary"),
      align: "center",
      width: 150,
      dataIndex: "order_data_sealogis",
      customFilterKey: "extra_cost_commentary",
      render: (values, record, index) => {
        const key = "data_order_sealogis.extra_cost_commentary";
        const { extra_cost_commentary } = values || {};
        const xcCode = record?.order_data_sealogis?.xc_status?.code;
        return (
          <EditionComponent
            id={"edition-extra-cost-commentary"}
            type="text"
            disabledBtn={
              xcCode ? strings(`xc_${xcCode?.toLowerCase()}`) : undefined
            }
            value={extra_cost_commentary}
            strings={strings}
            isBlocked={isEdit.index !== -1}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            isEdit={index === isEdit.index && key === isEdit.key}
            setIsEdit={() =>
              handleEditionFieldClick({
                index,
                key,
                record,
              })
            }
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            updateInfos={{ key, id: record.id }}
          />
        );
      },
    },
    {
      title: strings("xc_status"),
      width: 200,
      align: "center",
      dataIndex: "order_data_sealogis",
      customFilterKey: "xc_status_id",
      render: (values, record, index) => {
        const { xc_status } = values || {};
        const key = "data_order_sealogis.xc_status_id";
        const fieldOnEdit = index === isEdit.index && key === isEdit.key;
        const enableFields = getFields("update_xc_status", "sk");
        const xcCode = record?.order_data_sealogis?.xc_status?.code;
        return (
          <EditionComponent
            type="select"
            allowClear={canCtx.can("clear_xc_status", "true")}
            value={fieldOnEdit ? xc_status?.code : xc_status?.label}
            strings={strings}
            selector={selectXCStatus}
            disabledBtn={
              canCtx.can("update_xc_disabled_when", xcCode ?? "EMPTY")
                ? strings("update_disabled")
                : undefined
            }
            enableFields={enableFields}
            isBlocked={isEdit.index !== -1}
            required={false}
            canWrite={canCtx.can("update_bl", key)}
            isEdit={fieldOnEdit}
            isBlockedByMultiEdition={(record?.fields_in_edition || []).includes(
              key,
            )}
            setIsEdit={() => handleEditionFieldClick({ index, key, record })}
            onCancel={() =>
              handleEditionFieldClick({
                index: -1,
                key,
                record,
              })
            }
            updateInfos={{ key, id: record.id }}
          />
        );
      },
    },
    {
      title: strings("action"),
      width: 120,
      key: "action",
      align: "center",
      fixed: "right",
      csvExport: false,
      customFilterKey: "action_col",
      render: (_, item, index) => {
        return (
          <Space
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {canCtx.can("display", "tracking_btn") && (
              <TrackinUrlButton
                url={item.carrier_tracking_url}
                icon={<ShippingBox />}
                className={"align-items"}
                tooltipTitle={strings("tracking")}
              />
            )}
            {canCtx.can("display", "tracking_btn_or_pod") && (
              <>
                {canCtx.can("update_pod", "upload_file") ? (
                  <Button
                    id={"pod"}
                    icon={<AuditOutlined className={"audit-icon"} />}
                    shape="circle"
                    className={
                      item.pod_file || item.receipt_url
                        ? "audit-icon-btn"
                        : "audit-icon-no-pod"
                    }
                    style={
                      item.pod_file || item.receipt_url
                        ? { borderColor: "#0480FC" }
                        : { borderColor: "grey" }
                    }
                    onClick={() => {
                      setCurrentBL(item.bl_num);
                      setIsModalePODVisible(true);
                      setCurrentPodFile(item.pod_file);
                      setCurrentReceiptUrl(item.receipt_url);
                    }}
                  />
                ) : (
                  <TrackinUrlButton
                    onClick={() => {
                      onClickTracking(item);
                    }}
                    url={item.pod_file ? item.pod_file : item.receipt_url}
                    icon={<AuditOutlined />}
                    tooltipTitle={strings("POD")}
                  />
                )}
              </>
            )}
            {canCtx.can("display", "import_ged_order_btn") &&
              selectedClient &&
              item.id && (
                <Badge
                  count={item?.files_remitters?.length ?? 0}
                  offset={[-5, 1]}
                  size={"small"}
                >
                  <ImportGED
                    selectedClient={selectedClient}
                    orderId={item.id}
                    strings={strings}
                    id={"import-ged-order"}
                  />
                </Badge>
              )}
          </Space>
        );
      },
    },
  ].filter((el) => {
    if (!el.customFilterKey) return true;
    return !fields.includes(el.customFilterKey);
  });

  return (
    <DataTable
      columns={columns}
      items={valueList}
      loading={subLoading}
      isPagination
      pagination={{ ...pagination, total: totalBls }}
    />
  );
};
