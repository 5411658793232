import { InfoOutlined } from "@ant-design/icons";
import React from "react";

import ConfirmButton from "./ConfirmButton";

const InformationButton = ({ loading, modify, onCancel }) => {
  return (
    <ConfirmButton
      loading={loading}
      ghost
      shape={"circle"}
      type={"primary"}
      icon={<InfoOutlined />}
      onClick={modify}
      onCancel={onCancel}
    />
  );
};

export default InformationButton;
