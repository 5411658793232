import moment from "moment"

export const create_majStatut = (date) => {
    if (!date) return "missing_date";
    return moment(`${date}`).format("DD-MM-YYYY HH:mm");
};

export const bl_types = {
    ship: {
        status: "ship",
        maj_status_datetime: "ship_datetime",
    },
    print: {
        status: "print",
        maj_status_datetime: "print_datetime",
    },
    prepare: {
        status: "prepare",
        maj_status_datetime: "prepare_datetime",
    },
    request: {
        status: "request",
        maj_status_datetime: "request_datetime",
    },
    status: {
        status: "status",
        maj_status_datetime: "datetime_etat",
    },
};
