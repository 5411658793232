import { gql } from "@apollo/client";

const queries = {
  bcuData: gql`
    query getBcuDataForUser {
      bcu_removal_location_cities {
        id
        loading_zone
      }
    }
  `,
  typeFlux: gql`
    query getTypeFluxSk {
      type_flux_sk {
        id
        label
        code
      }
    }
  `,
  xcStatus: gql`
    query getXCStatus {
      xc_status {
        id
        label
        code
      }
    }
  `,
  partnerCarrierRequest: gql`
    query partnerCarrierRequest {
      partners(where: { type_code: { _eq: "TPT" } }) {
        id
        sealogis_code
      }
    }
  `,
};

export default queries;
