import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import AuthLayout from "../../layouts/authLayout";
import "./style.css";
import FluxFilter from "./fluxFilter";
import { BlActions, selectTotalBls } from "../../../redux/bl";
import { useDispatch, useSelector } from "react-redux";
import { StatusActions } from "../../../redux/status";
import EditModal from "./EditModal";
import { useSubscription } from "../../../services/hooks";
import blQueries from "../../../services/GraphQLRequest/blQueries";
import { orderParams } from "../../../services/hookHelpers/bl";
import { verifyIfDateIsPassed } from "../../../utils/wrappers/dateWrapper";
import { union } from "lodash";
import {
  selectHasNewOrder,
  sendFileSuccess,
  signedUrl,
} from "../../../redux/bl/selector";
import { showInfoBis } from "../../../utils/notifications-helper";
import { usePrevious } from "../../../utils/hooks/usePrevious";
import PODModale from "../../components/PodModale";
import { PalletsModal } from "./PalletsModal";
import { useRoleUser } from "../../../utils/hooks/useRoleUser";
import { CanWithChildren } from "../../components/can";
import { AbilityContext } from "../../../utils/casl/v2/can";
import { ClassicalBoard, SKBoard } from "./Tables";
import {
  selectArticleForModalTableClassic,
  selectTotalsForModalTableClassic,
} from "../../../redux/pallets";

const Home = ({
  strings,
  navigateTo,
  list,
  sk_list,
  filters,
  setFilters,
  setBlSearch,
  blSearch,
  pagination,
  sorter,
}) => {
  const [valueList, setValueList] = useState([]);
  const [reset, setReset] = useState(false);
  const [isModalePODVisible, setIsModalePODVisible] = useState(false);
  const [currentBL, setCurrentBL] = useState("");
  const [currentPodFile, setCurrentPodFile] = useState("");
  const [currentReceiptUrl, setCurrentReceiptUrl] = useState("");
  const dispatch = useDispatch();
  const totalBls = useSelector(selectTotalBls);
  const [isEdit, setIsEdit] = useState({ index: -1, key: "" });
  const [toEditIndex, setToEditIndex] = useState(-1);
  const [palletsIndex, setPalletsIndex] = useState(-1);
  const [openedFilter, setOpenedFilter] = useState(null);
  const hasNewOrder = useSelector(selectHasNewOrder);
  const trackingUrl = useSelector(signedUrl);
  const ref = useRef({});
  const successfullySendFile = useSelector(sendFileSuccess);

  const previous = usePrevious(isEdit.index);
  const userRole = useRoleUser();

  const testCtx = useContext(AbilityContext);
  const isClassical = testCtx.can("board", "classical");

  const {
    where = "",
    params = "",
    variables = {},
    orderBy = { id: "desc" },
  } = useMemo(() => orderParams(filters, sorter), [filters, sorter]);
  const onData = (data) => {
    if (dispatch)
      dispatch(
        isClassical
          ? BlActions.setBls(data?.data?.data?.orders)
          : BlActions.setSkBls(data?.data?.data?.orders),
      );
  };

  const { loading: subLoading } = useSubscription(
    isClassical
      ? blQueries.orderSubscription(where, params)
      : blQueries.skOrderSubscription(where, params),
    {
      onData,
      variables: {
        ...variables,
        offset:
          pagination.current === 1
            ? 0
            : (pagination.current - 1) * pagination.pageSize,
        limit: pagination.pageSize,
        order_by: orderBy,
      },
    },
  );

  const onClickTracking = (item) => {
    if (item.pod_file && dispatch) {
      dispatch(BlActions.getSignedUrl(item.pod_file));
    } else {
      window.open(item.receipt_url);
    }
  };

  useEffect(() => {
    if (trackingUrl !== "") {
      window.open(trackingUrl);
      dispatch(BlActions.resetSignedUrl());
    }
  }, [trackingUrl, dispatch]);

  useEffect(() => {
    if (successfullySendFile) {
      closePODModale();
      dispatch(BlActions.resetPodFileSuccess());
    }
    //eslint-disable-next-line
  }, [successfullySendFile]);

  // HANDLE ALL THE LOGICAL PROCESS OF UPDATING ORDER WITH KEY IN EDITION
  /*
    index: the index of the row in edition
    key: key of field in edition, only used by home table
    keys: keys of fields in edition, only used by modal
    record: the row entry
   */
  const handleEditionFieldClick = ({ index, key, keys, record }) => {
    if (!record) return;
    // COPY THE ACTIVE EDITION FIELDS
    let fieldsInEdition = [];
    const useList = list?.length ? list : sk_list;
    const updatedRecord = useList.find((el) => el.id === record.id);
    if (updatedRecord) {
      fieldsInEdition = [...updatedRecord.fields_in_edition];
    } else {
      fieldsInEdition = [...record.fields_in_edition];
    }
    if (dispatch) {
      switch (true) {
        case keys?.length > 0:
          if (index !== -1) fieldsInEdition = union(fieldsInEdition, keys);
          else if (index === -1)
            fieldsInEdition = fieldsInEdition.filter(
              (el) => !keys.includes(el),
            );
          break;
        case key?.length > 0:
          const fieldIndex = fieldsInEdition.indexOf(key);
          if (fieldIndex > -1) fieldsInEdition.splice(fieldIndex, 1);
          else fieldsInEdition.push(key);
          break;
        default:
          break;
      }
      dispatch(
        BlActions.blUpdate(
          { id: record.id, fields_in_edition: fieldsInEdition },
          {
            onFailure: () => {},
            onSuccess: () => {},
          },
          true,
        ),
      );
    }
    const isEditTmp = {
      index,
      key: index === -1 ? "" : key,
      dateEdit: index === -1 ? null : new Date(),
    };
    ref.current = {
      ...isEditTmp,
      keys: keys,
      record:
        index === -1 ? null : { ...record, fields_in_edition: fieldsInEdition },
    };
    setIsEdit(isEditTmp);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const isAfter = !ref.current?.dateEdit
        ? false
        : verifyIfDateIsPassed(ref.current.dateEdit, {
            duration: 3,
            unit: "minutes",
          });
      if (isAfter)
        handleEditionFieldClick({
          index: -1,
          key: ref.current?.key,
          keys: ref.current?.keys,
          record: ref.current?.record,
        });
    }, 10000);

    return () => {
      clearInterval(interval);
    };
    //eslint-disable-next-line
  }, [ref.current?.dateEdit]);

  const cleanUp = () => {
    if (!ref.current) {
      return;
    }
    const { index, key, keys, record } = ref.current;
    if (index !== -1 && record) {
      handleEditionFieldClick({ index, key, keys, record });
    }
  };

  const closePODModale = () => {
    setCurrentBL("");
    setIsModalePODVisible(false);
    setCurrentPodFile(null);
    setCurrentReceiptUrl(null);
  };

  window.onbeforeunload = () => {
    cleanUp();
  };

  useEffect(() => {
    dispatch(StatusActions.deliveryStatusRequest());
    dispatch(StatusActions.orderStatesRequest());
    dispatch(StatusActions.responsibilityRequest());
    dispatch(StatusActions.delayReasonRequest());
    dispatch(StatusActions.disputeReasonRequest());
    dispatch(StatusActions.warehouseStatusRequest());

    return () => {
      cleanUp();
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (hasNewOrder && isEdit.index === -1 && previous > -1) {
      showInfoBis(
        strings("new_order_notification"),
        strings("new_order_notification_description"),
        `new_order_${new Date().getTime()}`,
      );
    }
    //eslint-disable-next-line
  }, [hasNewOrder, isEdit.index]);

  useEffect(() => {
    if (list && isEdit.index === -1) {
      setValueList(list);
    }
  }, [list, isEdit.index, previous]);

  useEffect(() => {
    if (dispatch) {
      if (isEdit?.index > -1)
        handleEditionFieldClick({
          index: -1,
          key: isEdit.key,
          record: isEdit.record || ref.current?.record,
        });
      dispatch(
        BlActions.blsRequest(
          pagination.current === 1
            ? 0
            : (pagination.current - 1) * pagination.pageSize,
          pagination.pageSize,
          filters,
          sorter,
        ),
      );
    }
    // eslint-disable-next-line
  }, [filters, pagination, sorter]);

  const handleOnFilterDropdownOpenChange = (isOpen, key) => {
    if (isOpen) setOpenedFilter(key);
    else if (!isOpen && openedFilter === key) setOpenedFilter(null);
  };

  const onFilterChange = (newList) => {
    if (!newList) setReset(true);
    setValueList(newList || list);
  };

  const onModify = (center) => {
    dispatch(BlActions.setSelectedIndex(center.id));
    navigateTo(`/fluxTracking/${center.id}`);
  };

  const handleFilterChange = (toAdd, removeAll) => {
    const old = removeAll ? {} : filters;
    setFilters({ ...old, ...toAdd });
    dispatch(BlActions.setPagination({ ...pagination, current: 1 }));
  };

  return (
    <AuthLayout
      isHome={true}
      filters={filters}
      onFilterChange={handleFilterChange}
    >
      <FluxFilter
        strings={strings}
        setFilters={setFilters}
        filters={filters}
        sorter={sorter}
        blSearch={blSearch}
        setBlSearch={setBlSearch}
        onFilterChange={onFilterChange}
        pagination={pagination}
        list={list}
      />
      {/*<DataTable
        items={valueList}
        reset={reset}
        setReset={setReset}
        columns={columns}
        isPagination
        pagination={{ ...pagination, total: totalBls }}
        sorter={sorter}
        filters={filters}
        loading={subLoading}
        allowScrollOnElement={true}
      />*/}
      <CanWithChildren action={"board"} subject={"classical"}>
        <PalletsModal
          values={
            palletsIndex > -1
              ? {
                  id: valueList[palletsIndex].id,
                  blNum: valueList[palletsIndex].bl_num,
                }
              : { id: -1, blNum: "" }
          }
          selectTotal={selectTotalsForModalTableClassic}
          selectArticle={selectArticleForModalTableClassic}
          onClose={() => setPalletsIndex(-1)}
          strings={strings}
        />
        <ClassicalBoard
          strings={strings}
          sorter={sorter}
          openedFilter={openedFilter}
          handleOnFilterDropdownOpenChange={handleOnFilterDropdownOpenChange}
          filters={filters}
          isEdit={isEdit}
          handleEditionFieldClick={handleEditionFieldClick}
          setCurrentBL={setCurrentBL}
          setIsModalePODVisible={setIsModalePODVisible}
          setCurrentPodFile={setCurrentPodFile}
          setCurrentReceiptUrl={setCurrentReceiptUrl}
          onClickTracking={onClickTracking}
          setPalletsIndex={setPalletsIndex}
          setToEditIndex={setToEditIndex}
          onModify={onModify}
          reset={reset}
          setReset={setReset}
          pagination={pagination}
          totalBls={totalBls}
          subLoading={subLoading}
        />
      </CanWithChildren>

      <CanWithChildren action={"board"} subject={"sk"}>
        <SKBoard
          strings={strings}
          valueList={subLoading ? [] : valueList}
          subLoading={subLoading}
          isEdit={isEdit}
          filters={filters}
          handleEditionFieldClick={handleEditionFieldClick}
          pagination={pagination}
          totalBls={totalBls}
          userRole={userRole}
          setCurrentBL={setCurrentBL}
          setIsModalePODVisible={setIsModalePODVisible}
          setCurrentPodFile={setCurrentPodFile}
          setCurrentReceiptUrl={setCurrentReceiptUrl}
          onClickTracking={onClickTracking}
        />
      </CanWithChildren>
      <EditModal
        values={toEditIndex > -1 ? valueList[toEditIndex] : null}
        onClose={() => setToEditIndex(-1)}
        isEdit={isEdit}
        onEditField={(keys, index) =>
          handleEditionFieldClick({
            index: index !== -1 ? toEditIndex : index,
            keys,
            record: valueList[toEditIndex],
          })
        }
        strings={strings}
      />
      <PODModale
        showModal={isModalePODVisible}
        titleModale={"pod_modale_title"}
        strings={strings}
        blNum={currentBL}
        podFile={currentPodFile}
        receiptUrl={currentReceiptUrl}
        onClose={() => {
          closePODModale();
        }}
      />
    </AuthLayout>
  );
};

export default Home;
